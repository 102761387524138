import React from 'react';

import { __ } from 'helpers/i18n';

const SuggestionsAccordionEmptyState = () => (
  <div
    className={
      'text-text-light italic py-2 pr-4 pl-[var(--accordion-pad-left)]'
    }
  >
    {__('No training courses suggested')}
  </div>
);

export default SuggestionsAccordionEmptyState;
