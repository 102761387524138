import React, { Fragment, useState } from 'react';

import can from 'helpers/can';
import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { Notification } from 'components';

import BudgetCard from './BudgetCard';
import PlanStats from './PlanStats';

type Props = {
  periodSlug: string;
  isValidatedPlan: boolean;
};

const Stats = ({ periodSlug, isValidatedPlan }: Props) => {
  const organization = useCurrentOrganization();
  const trainingCurrenciesEnabled =
    organization.featureFlags.includes('trainingCurrencies');
  const canManageBudget = can({
    perform: 'manage_budget',
    on: organization,
  });
  const [missingCurrencies, setMissingCurrencies] = useState<Array<string>>([]);

  const [budgetManagementModalActive, setBudgetManagementModalActive] =
    useState(false);

  return (
    <Fragment>
      <div
        className="flex mb-8 gap-6"
        key={`training-${
          isValidatedPlan ? 'validated' : 'forecast'
        }-plan-stats`}
      >
        <BudgetCard
          periodSlug={periodSlug}
          isValidatedPlan={isValidatedPlan}
          setMissingCurrencies={setMissingCurrencies}
          budgetManagementModalActive={budgetManagementModalActive}
          setBudgetManagementModalActive={setBudgetManagementModalActive}
        />
        <PlanStats periodSlug={periodSlug} isValidatedPlan={isValidatedPlan} />
      </div>
      {missingCurrencies.length > 0 && trainingCurrenciesEnabled && (
        <Notification
          className="mb-8"
          kind="warning"
          icon="warning"
          action={
            canManageBudget
              ? {
                  label: __('Change exchange rates'),
                  handleClick: () => setBudgetManagementModalActive(true),
                }
              : undefined
          }
        >
          {__(
            'Please note that some sessions are priced in currencies with undefined exchange rates (%1).',
            missingCurrencies.join(', ')
          )}
        </Notification>
      )}
    </Fragment>
  );
};

export default Stats;
