import { debounce } from 'lodash';
import React from 'react';
import { components } from 'react-select';
import { DropdownIndicatorProps } from 'react-select';

import { BaseTrainingOrganism } from 'models/TrainingOrganism';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { hydrateFromResponse } from 'lib/dataLoader';
import { AppDispatch } from 'redux/actions';
import { get } from 'redux/actions/api';
import { ReduxStore } from 'redux/reducers';

import { IOption, Icon, Select } from 'components';

type Props = {
  onChange: (trainingOrganism?: BaseTrainingOrganism) => void;
  trainingOrganism?: BaseTrainingOrganism | null;
  isDisabled?: boolean;
};

const SearchDropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <Icon name="search" />
  </components.DropdownIndicator>
);

const TrainingOrganismPicker = ({
  trainingOrganism,
  isDisabled,
  onChange,
}: Props) => {
  const dispatch = useAppDispatch();

  const trainingOrganismToOption = (
    trainingOrganism: BaseTrainingOrganism
  ) => ({
    label: trainingOrganism.name,
    value: trainingOrganism.id,
    trainingOrganism,
  });

  const fetchTrainingOrganisms = async (
    search: string
  ): Promise<BaseTrainingOrganism[]> => {
    const fetchUrl = '/training/organisms';

    return dispatch(
      async (dispatch: AppDispatch, getState: () => ReduxStore) => {
        const { response } = await dispatch(
          get(fetchUrl, {
            countPerPage: 10,
            search,
          })
        );

        const { items } = hydrateFromResponse(
          getState().data,
          response.body,
          {
            trainingOrganismCollection: {
              items: {},
            },
          },
          response.body.data.id
        ) as unknown as { items: BaseTrainingOrganism[] };

        return items;
      }
    );
  };

  const loadTrainingOrganismOptions = (
    search: string,
    callback: (options: IOption[]) => void
  ) => {
    fetchTrainingOrganisms(search).then(trainingOrganisms => {
      callback(trainingOrganisms.map(trainingOrganismToOption));
    });
  };

  const debouncedSearch = debounce(loadTrainingOrganismOptions, 300);

  return (
    <Select
      loadOptions={debouncedSearch}
      value={trainingOrganism && trainingOrganismToOption(trainingOrganism)}
      onChange={trainingOrganism => {
        onChange(trainingOrganism?.trainingOrganism);
      }}
      placeholder={__('Search a training organization')}
      noOptionsMessage={__('No training organizations found')}
      isAsync
      inModal={true}
      isClearable
      cacheOptions
      defaultOptions
      loadingMessage={() => __('Loading training organizations...')}
      components={{ DropdownIndicator: SearchDropdownIndicator }}
      isDisabled={isDisabled}
    />
  );
};

export default TrainingOrganismPicker;
