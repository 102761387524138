import React, { Fragment, useState } from 'react';

import type { TrainingParticipant } from 'models';

import { __ } from 'helpers/i18n';

import { Text } from 'components';

import RequestManagementModal from 'scenes/components/TrainingRequestManagementModal';

type Props = {
  participant: TrainingParticipant;
  sessionName: string;
  refetchParticipants: () => void;
};

const RequestCell = ({
  participant,
  sessionName,
  refetchParticipants,
}: Props) => {
  const [isRequestUpdateModalVisible, setIsRequestUpdateModalVisible] =
    useState(false);
  const request = participant.request;

  if (!!request)
    return (
      <Fragment>
        <button
          onClick={() => setIsRequestUpdateModalVisible(true)}
          className="p-0 border-none bg-transparent cursor-pointer hover:underline hover:text-blue-elevo"
        >
          {request.title}
        </button>
        {isRequestUpdateModalVisible && (
          <RequestManagementModal
            currentPeriodSlug={request.trainingPeriod.slug}
            trainingRequest={request}
            onClose={() => setIsRequestUpdateModalVisible(false)}
            onRequestRefusal={() => refetchParticipants()}
            linkedSessionName={sessionName}
          />
        )}
      </Fragment>
    );

  return (
    <Text transformation="italic" color="light">
      {__('No associated request')}
    </Text>
  );
};

export default RequestCell;
