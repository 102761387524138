import classNames from 'classnames';
import React, { Fragment, ReactNode } from 'react';
import { Route, Link as RouterLink } from 'react-router-dom';

import type { MaterialIconName } from 'components/Icon';
import type { TextPreset } from 'components/text/Text';

import { Icon, Link, Text, Tooltip } from 'components';

type NavigationItemPropsBase = {
  to?: string | undefined | null;
  onClick?: () => void;
  label: string;
  icon?: MaterialIconName;
  svgIcon?: ReactNode;
  testName?: string;
  rightContent?: React.ReactNode;
  labelPreset?: TextPreset;
  isDisabled?: boolean;
  disabledReason?: ReactNode;
};

type ClickableNavigationItemProps = NavigationItemPropsBase & {
  onClick: NonNullable<NavigationItemPropsBase['onClick']>;
  isActive?: boolean;
};

type LinkNavigationItemProps = NavigationItemPropsBase & {
  to: NonNullable<NavigationItemPropsBase['to']>;
  exact?: boolean;
};

export type NavigationItem =
  | ClickableNavigationItemProps
  | LinkNavigationItemProps;

const hasOnClick = (
  item: NavigationItem
): item is ClickableNavigationItemProps => {
  return !!item.onClick;
};

const hasLink = (item: NavigationItem): item is LinkNavigationItemProps => {
  return !!item.to;
};

type ItemIconProps = {
  icon?: MaterialIconName;
  svgIcon?: ReactNode;
};

const ItemIcon = ({ icon, svgIcon }: ItemIconProps) => {
  if (icon) {
    return (
      <Fragment>
        <Icon name={icon} />
        &nbsp;
      </Fragment>
    );
  }

  if (svgIcon) {
    return <Fragment>{svgIcon}&nbsp;</Fragment>;
  }

  return null;
};

type Props = {
  items: Array<NavigationItem>;
};

const ClickableNavigationItem = ({
  label,
  icon,
  svgIcon,
  rightContent,
  testName,
  onClick,
  isActive,
  labelPreset,
  isDisabled,
  disabledReason,
}: ClickableNavigationItemProps) => (
  <li
    className={classNames({
      'is-active': isActive,
      'is-disabled': isDisabled,
    })}
  >
    <Tooltip enabled={isDisabled} content={disabledReason} isLight>
      <Link
        className={null}
        onClick={onClick}
        testClassName={classNames('test-link', testName)}
      >
        <ItemIcon icon={icon} svgIcon={svgIcon} />
        <Text preset={labelPreset}>{label}</Text>
        {rightContent}
      </Link>
    </Tooltip>
  </li>
);

const LinkNavigationItem = ({
  to,
  label,
  icon,
  svgIcon,
  exact,
  rightContent,
  testName,
  labelPreset,
}: LinkNavigationItemProps) => (
  <Route
    path={to.split('?')[0]}
    exact={!!exact}
    children={({ match: isActive }) => {
      return (
        <li className={isActive ? 'is-active' : ''}>
          <RouterLink
            to={to}
            className={classNames('test-link', testName, {
              active: isActive,
            })}
          >
            <ItemIcon icon={icon} svgIcon={svgIcon} />
            <Text preset={labelPreset}>{label}</Text>
            {rightContent}
          </RouterLink>
        </li>
      );
    }}
  />
);

export default function NavigationList({ items }: Props) {
  return (
    <ul>
      {items.map(item => {
        if (hasLink(item) && item.isDisabled) {
          item = {
            ...item,
            onClick: () => {},
            to: undefined,
          };
        }

        if (hasOnClick(item)) {
          return <ClickableNavigationItem {...item} key={item.label} />;
        } else if (hasLink(item)) {
          return <LinkNavigationItem {...item} key={item.label} />;
        } else {
          throw new Error(
            'Navigation item should have either `onClick` or `to`'
          );
        }
      })}
    </ul>
  );
}
