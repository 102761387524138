import React from 'react';

import type { HomeMessage, NavActionsCount, User } from 'models';

import can from 'helpers/can';
import classNames from 'helpers/classNames';
import { useCurrentOrganization, useOrganizationPlan } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import {
  pathToENPSForm,
  pathToFeedbackRequests,
  pathToMyReviews,
  pathToMyTeamReviews,
  pathToOtherActions,
  pathToPeopleReviewCycles,
  pathToPeopleReviewsToComplete,
  pathToSurveysToComplete,
  pathToUserTrainings,
} from 'helpers/paths';

import { FetchContainer } from 'components';

import HomeMessageBox from '../HomeMessageBox';
import HomeNavigationBox from './HomeNavigationBox';

type Props = {
  user: User;
  actionsCount: NavActionsCount;
  homeMessage: HomeMessage | null;
  isFetchingHomeMessage: boolean;
  hasError: boolean;
  refetchHomeMessage: () => Promise<any>;
  customGreetings?: string;
};

const HomeMainGrid = ({
  user,
  actionsCount,
  homeMessage,
  isFetchingHomeMessage,
  hasError,
  refetchHomeMessage,
  customGreetings,
}: Props) => {
  const organization = useCurrentOrganization();
  const organizationPlan = useOrganizationPlan();
  const { is360Enabled, trainingModuleEnabled, baseSurveysEnabled } =
    organizationPlan;

  const withMessageBox =
    homeMessage || can({ perform: 'update_home_message', on: organization });

  const shouldDisplayPeopleReviewBox = can({
    perform: 'show_people_reviews_menu',
    on: organization,
  });

  const shouldDisplayENPSBox = !Object.is(actionsCount?.enpsToComplete, null);

  const hasManyFeaturesEnabled =
    is360Enabled ||
    trainingModuleEnabled ||
    baseSurveysEnabled ||
    shouldDisplayPeopleReviewBox ||
    shouldDisplayENPSBox;

  const reviewsItems = [
    {
      title: __('My reviews'),
      count: actionsCount?.myOneOnOneReviews || 0,
      path: pathToMyReviews('one_on_one'),
      show: true,
    },
    {
      title: __('Team reviews'),
      count: actionsCount?.myOneOnOneTeamReviews || 0,
      path: pathToMyTeamReviews('one_on_one'),
      show: !!user.isManager,
    },
    {
      title: __('Other reviews'),
      count: actionsCount?.myOneOnOneOtherReviews || 0,
      path: pathToOtherActions('one_on_one'),
      show: !Object.is(actionsCount?.myOneOnOneOtherReviews, null),
    },
  ];

  const threeSixtyItems = [
    {
      title: __('My 360 feedback'),
      count: actionsCount?.my360Reviews || 0,
      path: pathToMyReviews('360'),
      show: true,
      testClassName: 'test-my-three-sixty-nav-item',
    },
    {
      title: __('Team 360 feedback'),
      count: actionsCount?.my360TeamReviews || 0,
      path: pathToMyTeamReviews('360'),
      show: !!user.isManager,
      testClassName: 'test-my-team-three-sixty-nav-item',
    },
    {
      title: __('360 feedback requests'),
      count: actionsCount?.feedbackRequests || 0,
      path: pathToFeedbackRequests(),
      show: true,
      testClassName: 'test-three-sixty-requests-nav-item',
    },
    {
      title: __('Delegated 360 feedback'),
      count: actionsCount?.my360OtherReviews || 0,
      path: pathToOtherActions('360'),
      show: !Object.is(actionsCount?.my360OtherReviews, null),
    },
  ];

  const trainingItems = [
    {
      title: __('My training requests'),
      count: 0,
      path: pathToUserTrainings(user.id),
      show: true,
    },
  ];

  const peopleReviewItems = [
    {
      title: __('To complete'),
      count: actionsCount?.myPeopleReviewsAsReviewer || 0,
      path: pathToPeopleReviewsToComplete(),
      show: !Object.is(actionsCount?.myPeopleReviewsAsReviewer, null),
    },
    {
      title: __('Campaigns'),
      count: 0,
      path: pathToPeopleReviewCycles(),
      show: true,
    },
  ];

  const enpsItems = [
    {
      title: __('Ongoing'),
      count: actionsCount?.enpsToComplete || 0,
      path: pathToENPSForm(),
      show: true,
    },
  ];

  const surveyItems = [
    {
      title: __('To complete'),
      count: actionsCount?.surveysToComplete || 0,
      path: pathToSurveysToComplete(),
      show: true,
    },
  ];

  return (
    <React.Fragment>
      <div className="text-2xl font-bold">
        {customGreetings
          ? customGreetings
          : __('Welcome %1 👋', user.firstName)}
      </div>
      <div className="home-navigation-boxes mt-4">
        <div
          className={
            hasManyFeaturesEnabled ? 'home-main-grid' : 'home-main-wrapper'
          }
        >
          <div
            className={
              withMessageBox ? 'lg:col-span-2 flex-1' : 'lg:col-span-3 flex-1'
            }
          >
            <div
              className={
                hasManyFeaturesEnabled
                  ? classNames(
                      'grid grid-cols-1 md:grid-cols-2 gap-4 lg:gap-8',
                      { 'lg:grid-cols-3': !withMessageBox }
                    )
                  : 'flex'
              }
            >
              <div className="w-full">
                <HomeNavigationBox
                  title={__('Reviews')}
                  iconName="speed"
                  items={reviewsItems}
                  className="gtm-reviews-box"
                  testClassName="test-one-on-one-navigation-box"
                />
              </div>
              {is360Enabled && (
                <div>
                  <HomeNavigationBox
                    title={__('360 Feedback')}
                    iconName="360"
                    items={threeSixtyItems}
                    testClassName="test-three-sixty-navigation-box"
                  />
                </div>
              )}
              {trainingModuleEnabled && (
                <div>
                  <HomeNavigationBox
                    title={__('Training')}
                    iconName="school"
                    className="gtm-training-home-box"
                    items={trainingItems}
                  />
                </div>
              )}
              {shouldDisplayPeopleReviewBox && (
                <div>
                  <HomeNavigationBox
                    title={__('People Review')}
                    iconName="person_pin"
                    items={peopleReviewItems}
                  />
                </div>
              )}
              {shouldDisplayENPSBox && (
                <div>
                  <HomeNavigationBox
                    title={__('eNPS')}
                    iconName="volunteer_activism"
                    items={enpsItems}
                  />
                </div>
              )}
              {baseSurveysEnabled && (
                <HomeNavigationBox
                  title={__('Surveys')}
                  iconName="volunteer_activism"
                  items={surveyItems}
                />
              )}
            </div>
          </div>
          {withMessageBox && (
            <div className="lg:col-span-1 mb-6 flex-1">
              <FetchContainer
                isFetching={isFetchingHomeMessage}
                hasError={hasError}
                render={() => (
                  <HomeMessageBox
                    organization={organization}
                    homeMessage={homeMessage}
                    refetchHomeMessage={refetchHomeMessage}
                  />
                )}
              />
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default HomeMainGrid;
