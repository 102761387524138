import React from 'react';
import { connect } from 'react-redux';

import type { Answer } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import classNames from 'helpers/classNames';
import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import { assert } from 'helpers/invariant';

import { AutoSave, RadioSelect, Textarea } from 'components';

import RequiredQuestionWarning from '../../components/FeedbackZone/RequiredQuestionWarning';
import DiscreteSliderAnswer from './DiscreteSliderAnswer';
import RatingSelect from './RatingSelect';

type Feedback = {
  text: string;
  rating: string | undefined | null;
};

type Props = {
  itemId: string;
  itemType: string;
  itemLabel?: string;
  answer: Answer;
  blockId: string;
  withMandatoryTag?: boolean;
  persistAction: (
    evaluationId: string | null,
    feedbackableId: string,
    feedbackableType: string,
    text: string,
    rating: string | undefined | null
  ) => (dispatch: AppDispatch) => Promise<void>;
  closedAnswers?: Answer[];
};

type AfterConnectProps = Props & {
  persistFeedback: (feedback: Feedback) => Promise<void>;
};

type State = {
  text: string;
  rating: string | null;
};

class OpenAnswer extends React.Component<AfterConnectProps, State> {
  constructor(props: AfterConnectProps) {
    super(props);

    const { answer } = props;
    const { text, rating } = answer;

    this.state = {
      text: text || '',
      rating,
    };
  }

  setStateWithPromise = (setStateParams: (prevState: State) => State) =>
    new Promise<void>(resolve => this.setState(setStateParams, resolve));

  onRatingChange = (newRating: string | null) =>
    this.setStateWithPromise((prevState: State) => ({
      ...prevState,
      rating: newRating,
    }));

  onTextChange = (value: string) =>
    this.setStateWithPromise(prevState => ({
      ...prevState,
      text: value,
    }));

  persistFeedback = async () => {
    const { persistFeedback, itemType } = this.props;
    const { text, rating } = this.state;

    await persistFeedback({
      text,
      rating: itemType === 'feedbackableSkillsDomain' ? undefined : rating,
    });
  };

  render() {
    const {
      itemId,
      itemType,
      itemLabel,
      answer,
      blockId,
      withMandatoryTag,
      closedAnswers,
    } = this.props;
    const { rating, text } = this.state;
    const {
      ratingEnabled,
      textEnabled,
      ratings,
      authorRole,
      ratingMandatory,
      textMandatory,
    } = answer;

    const className = classNames('answer', 'is-opened', 'test-open-answer', {
      'test-reviewee-answer': authorRole === 'reviewee',
      'test-reviewer-answer': authorRole !== 'reviewee',
    });
    const skillExpectationAnswer =
      itemType === 'skillsCareerLevelExpectationBlock' ||
      itemType === 'skillsCareerLevel';
    const mandatory =
      (ratingMandatory &&
        itemType !== 'feedbackableSkillsDomain' &&
        !skillExpectationAnswer) ||
      textMandatory;

    return (
      <div className={className}>
        {ratingEnabled && skillExpectationAnswer && (
          <div className="mb-4 mt-4">
            <AutoSave
              fieldUid={compositeKey({
                blockId,
                itemId,
                itemType,
                field: 'rating',
              })}
              onChange={this.onRatingChange}
              doPersist={this.persistFeedback}
              render={autoSavingOnChange => (
                <DiscreteSliderAnswer
                  rating={rating}
                  ratingOptions={ratings}
                  ratingMandatory={ratingMandatory}
                  onRatingChange={autoSavingOnChange}
                  closedAnswers={closedAnswers}
                />
              )}
            />
          </div>
        )}

        {withMandatoryTag && mandatory && <RequiredQuestionWarning />}

        {ratingEnabled &&
          itemType !== 'multipleScaleQuestion' &&
          itemType !== 'skillsCareerLevelExpectationBlock' &&
          itemType !== 'skillsCareerLevel' &&
          itemType !== 'feedbackableSkillsDomain' &&
          itemType !== 'ratingCriterion' && (
            <div
              className={classNames('answer-rating-input', 'test-rating-part')}
            >
              <AutoSave
                fieldUid={compositeKey({
                  blockId,
                  itemId,
                  itemType,
                  field: 'rating',
                })}
                onChange={this.onRatingChange}
                doPersist={this.persistFeedback}
                render={autoSavingOnChange => (
                  <RatingSelect
                    ratings={assert(
                      ratings,
                      'ratings must be defined if rating is enabled'
                    )}
                    selectedRating={rating}
                    onChange={autoSavingOnChange}
                    isDanger={ratingMandatory && !this.state.rating}
                  />
                )}
              />
            </div>
          )}

        {ratingEnabled && itemLabel && itemType === 'ratingCriterion' && (
          <div
            className={classNames(
              'answer-criterion-input',
              'test-answer-criterion-input',
              {
                'is-mandatory': ratingMandatory && !rating,
              }
            )}
          >
            <AutoSave
              fieldUid={compositeKey({
                blockId,
                itemId,
                itemType,
                field: 'text',
              })}
              onChange={this.onRatingChange}
              doPersist={this.persistFeedback}
              render={autoSavingOnChange => (
                <RadioSelect
                  label={itemLabel}
                  name={`row-item-${itemId}`}
                  selectedValue={rating}
                  values={assert(
                    ratings,
                    'ratings must be defined if rating is enabled'
                  )}
                  onChange={autoSavingOnChange}
                />
              )}
            />
          </div>
        )}

        {textEnabled && (
          <div className={classNames('answer-text-input', 'test-text-part')}>
            <AutoSave
              fieldUid={compositeKey({
                blockId,
                itemId,
                itemType,
                field: 'text',
              })}
              onChange={this.onTextChange}
              doPersist={this.persistFeedback}
              render={autoSavingOnChange => (
                <Textarea
                  value={text}
                  onChange={autoSavingOnChange}
                  placeholder={__('Add your comment here')}
                  state={
                    textMandatory && !this.state.text.trim().length
                      ? 'danger'
                      : undefined
                  }
                />
              )}
            />
          </div>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(
  dispatch: AppDispatch,
  { itemId, itemType, answer, persistAction }: Props
) {
  const evaluationId = answer.evaluationId;
  const feedbackableId = itemId;
  const feedbackableType = itemType;

  return {
    persistFeedback: async ({ text, rating }: Feedback) => {
      return await dispatch(
        persistAction(
          evaluationId,
          feedbackableId,
          feedbackableType,
          text,
          rating
        )
      );
    },
  };
}

// @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
export default connect(null, mapDispatchToProps)(OpenAnswer);
