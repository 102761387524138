import React, { Fragment } from 'react';
import { Redirect, RouteComponentProps } from 'react-router-dom';

import { __ } from 'helpers/i18n';
import {
  Match,
  pathToManagerTrainingDashboardRequests,
  pathToManagerTrainingDashboardSessions,
} from 'helpers/paths';

import {
  ContentContainer,
  PageHeader,
  PageTitle,
  Route,
  Switch,
} from 'components';

import Requests from './Requests';
import Sessions from './Sessions';

type RouterProps = RouteComponentProps<{ periodSlug: string }> & {
  match: Match<{
    id: string;
  }>;
};

type Props = RouterProps;

const ManagerDashboard = ({ match }: Props) => {
  const periodSlug = match.params.periodSlug;

  return (
    <Fragment>
      <PageTitle title={[__('Trainings'), __('Team follow-up')]} />
      <PageHeader
        title={__('Team follow-up')}
        tabItems={[
          {
            to: pathToManagerTrainingDashboardRequests(periodSlug),
            label: __('Requests'),
          },
          {
            to: pathToManagerTrainingDashboardSessions(periodSlug),
            label: __('Sessions'),
          },
        ]}
      />
      <ContentContainer>
        <Switch>
          <Route
            exact
            path={`${match.path}/requests`}
            render={() => <Requests periodSlug={periodSlug} />}
          />
          <Route
            exact
            path={`${match.path}/sessions`}
            render={() => <Sessions periodSlug={periodSlug} />}
          />
          <Redirect to={pathToManagerTrainingDashboardRequests(periodSlug)} />
        </Switch>
      </ContentContainer>
    </Fragment>
  );
};

export default ManagerDashboard;
