import { debounce } from 'lodash';
import React from 'react';
import { components } from 'react-select';

import type { SkillsArea } from 'models';
import type { DropdownIndicatorProps } from 'react-select';
import type { AppDispatch } from 'redux/actions';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { hydrateFromResponse } from 'lib/dataLoader';
import { get } from 'redux/actions/api';
import { ReduxStore } from 'redux/reducers';

import { Icon, Select } from 'components';

type Option = {
  label: string;
  value: string;
  area: SkillsArea;
};

type Props = {
  selectedArea: SkillsArea | null;
  matrixId: string;
  onChange: (area: SkillsArea | null) => void;
};

const SkillsAreaPicker = ({ selectedArea, matrixId, onChange }: Props) => {
  const dispatch = useAppDispatch();

  const areaToOption = (area: SkillsArea): Option => ({
    label: area.title,
    value: area.id,
    area,
  });

  const fetchAreas = async (search: string): Promise<SkillsArea[]> =>
    dispatch(async (dispatch: AppDispatch, getState: () => ReduxStore) => {
      const { response } = await dispatch(
        get(`/skills/matrices/${matrixId}/available_areas`, {
          countPerPage: 10,
          search,
        })
      );

      const { items } = hydrateFromResponse(
        getState().data,
        response.body,
        {
          areaCollection: { items: {} },
        },
        response.body.data.id
      ) as unknown as { items: SkillsArea[] };

      return items;
    });

  const loadAreaOptions = (
    search: string,
    callback: (options: Option[]) => void
  ) => {
    fetchAreas(search).then(areas => {
      callback(areas.map(area => areaToOption(area)));
    });
  };

  const debouncedSearch = debounce(loadAreaOptions, 300);

  return (
    <Select
      loadOptions={debouncedSearch}
      value={!!selectedArea ? areaToOption(selectedArea) : null}
      onChange={option => {
        invariant(!Array.isArray(option), 'Invalid option type');

        onChange(!option ? null : (option as Option).area);
      }}
      placeholder={__('Select a skill')}
      noOptionsMessage={__('No skill is available')}
      isClearable
      isAsync
      loadingMessage={() => __('Loading skills...')}
      cacheOptions
      defaultOptions
      components={{
        DropdownIndicator: (props: DropdownIndicatorProps) => (
          <components.DropdownIndicator {...props}>
            <Icon name="search" />
          </components.DropdownIndicator>
        ),
      }}
    />
  );
};

export default SkillsAreaPicker;
