import React, { Fragment } from 'react';

import useActions from '../helpers/useActions';
import SessionList from './SessionList';

type Props = { periodSlug: string };

const Requests = ({ periodSlug }: Props) => {
  const { renderActions, userFilters } = useActions(periodSlug);

  return (
    <Fragment>
      {renderActions()}

      <SessionList
        currentPeriodSlug={periodSlug}
        defaultFilter={{ all: true }}
        additionalQueryParams={{ userFilters, sort: { created_at: 'desc' } }}
      />
    </Fragment>
  );
};

export default Requests;
