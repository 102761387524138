// uses company short name as flag identifier
import React, { Fragment, ReactNode } from 'react';

import type { FeatureFlag } from 'models';

import { useCurrentOrganization } from 'helpers/hooks';

type Props = {
  flag: FeatureFlag;
  children?: ReactNode;
  alternativeChildren?: ReactNode;
  inverseDefaultBehavior?: boolean;
  render?: (featureFlagged: boolean) => ReactNode;
};

const FeatureFlagged = ({
  flag,
  children,
  alternativeChildren,
  inverseDefaultBehavior,
  render,
}: Props) => {
  const { featureFlags } = useCurrentOrganization();
  const featureFlagged =
    featureFlags.includes(flag) === !inverseDefaultBehavior;

  let content: ReactNode;
  if (render) {
    content = render(featureFlagged);
  } else if (featureFlagged && children) {
    content = children;
  } else {
    content = alternativeChildren || null;
  }

  return <Fragment>{content}</Fragment>;
};

export default FeatureFlagged;
