import {
  useOrganizationSettings,
  useUserFilterableFields,
} from 'helpers/hooks';

const useGetAssignationCriterionLabel = () => {
  const { skillsMatrixAssignationCriterion } = useOrganizationSettings();
  const userFilterableFields = useUserFilterableFields();

  if (!skillsMatrixAssignationCriterion) {
    return null;
  }

  return userFilterableFields.find(
    field => field.slug === skillsMatrixAssignationCriterion
  )?.label;
};

export default useGetAssignationCriterionLabel;
