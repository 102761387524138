import React, { useEffect, useState } from 'react';

import type { AvailableCriterionKey, CustomField } from 'models';

import store from 'config/store';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { hydrateFromResponse } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Select } from 'components';

type AttributeNamePickerProps = {
  attributeName: AvailableCriterionKey | null | undefined;
  updateAttributeName: (key: AvailableCriterionKey) => Promise<void>;
};

type Option = {
  label: string;
  value: string; // Cannot type with AvailableCriterionKey
};

const defaultOptions = (): Array<Option> => [
  {
    label: __('Job title'),
    value: 'job_title',
  },
  {
    label: __('Department'),
    value: 'department',
  },
  {
    label: __('Language'),
    value: 'locale',
  },
  {
    label: __('Level'),
    value: 'level',
  },
  {
    label: __('Service'),
    value: 'service',
  },
  {
    label: __('Region'),
    value: 'region',
  },
  {
    label: __('Team'),
    value: 'team',
  },
  {
    label: __('Manager status'),
    value: 'manager_status',
  },
];

export default function useAttributeNamePicker() {
  const dispatch = useAppDispatch();

  const [options, setOptions] = useState<Array<Option>>([]);

  const getOptions = async () => {
    const data = await dispatch(get('custom_fields'));

    const customFields = hydrateFromResponse(
      store.getState().data,
      data.response.body,
      { customFields: {} }
    ) as unknown as Array<CustomField>;

    setOptions(
      [
        ...defaultOptions(),
        ...customFields.map(field => ({
          label: field.label,
          value: field.slug,
        })),
      ].sort((attribute1, attribute2) =>
        attribute1.label.localeCompare(attribute2.label)
      )
    );
  };

  useEffect(() => {
    getOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getOption = (value: string | null | undefined): Option | null => {
    if (!value) return null;
    return (
      options.find(({ value: optionValue }) => value === optionValue) || null
    );
  };

  return {
    attributesCount: options.length,
    attributeNamePicker: ({
      attributeName,
      updateAttributeName,
    }: AttributeNamePickerProps) => {
      return (
        <Select
          testClassName="test-attribute-name-picker"
          value={getOption(attributeName)}
          onChange={option => {
            if (!option || Array.isArray(option)) return;
            const newAttributeName = option.value as AvailableCriterionKey;
            return updateAttributeName(newAttributeName);
          }}
          options={options}
          isClearable={false}
          isSearchable={false}
          placeholder={__('Choose a property')}
        />
      );
    },
  };
}
