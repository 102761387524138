import React from 'react';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { del } from 'redux/actions/api';

import StrictlySanitizedHtml from 'components/StrictlySanitizedHtml';
import { ConfirmationModal } from 'components/modals';

type Props = {
  courseId: string;
  courseTitle: string;
  areaId: string;
  onConfirm?: () => void;
  onClose: () => void;
};

const DeletionConfirmationModal = ({
  courseId,
  courseTitle,
  areaId,
  onConfirm,
  onClose,
}: Props) => {
  const dispatch = useAppDispatch();

  const deleteTrainingCourseSuggestion = async (courseId, areaId) => {
    const params = { courseId: courseId, areaId: areaId };
    await dispatch(
      del(`training/training_course_suggestions`, params, {
        successMessage: __(
          'The training course was successfully removed from suggestions.'
        ),
      })
    );
    if (onConfirm) onConfirm();
    onClose();
  };

  return (
    <ConfirmationModal
      onConfirm={() => deleteTrainingCourseSuggestion(courseId, areaId)}
      confirmLabel={__('Remove training course')}
      onCancel={() => onClose()}
      cancelLabel={__('Cancel')}
      title={__('Remove training course from suggestions')}
      refreshContentOnOpening={false}
      isActive
      isDanger
      onClose={onClose}
    >
      <p>
        {__(
          'This action will remove "%1" from the catalog suggestions.',
          courseTitle
        )}
      </p>
      <br />
      <StrictlySanitizedHtml
        componentType="p"
        html={__(
          '<b>Employees will no longer see</b> this training course suggested on the catalog based on their skills matrix.'
        )}
      />
      <br />
      <p>{__('Are you sure you want to remove this training course?')}</p>
    </ConfirmationModal>
  );
};

export default DeletionConfirmationModal;
