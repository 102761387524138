import React from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { OneOnOneReviewCycle, ThreeSixtyReviewCycle } from 'models';

import { __ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  BreadcrumbAnchor,
  Column,
  Columns,
  ContentContainer,
  FetchContainer,
  PageHeader,
  PageTitle,
} from 'components';

import CardPlaceholder from 'scenes/reviews/components/CardPlaceholder';

import ReviewCycleList from './ReviewCycleList';

type Props = {
  type: string;
};

type AfterConnectProps = Props &
  DataLoaderProvidedProps & {
    reviewCycles: Array<ThreeSixtyReviewCycle | OneOnOneReviewCycle>;
  };

function ReviewCyclesList({
  isFetching,
  hasError,
  refetchData,
  reviewCycles,
}: AfterConnectProps) {
  return (
    <React.Fragment>
      <PageTitle title={__('Other actions')} />
      <BreadcrumbAnchor name="user_review_list" />
      <PageHeader title={__('Other reviews')} />

      <ContentContainer>
        <Columns>
          <Column size={12}>
            <FetchContainer
              isFetching={isFetching}
              hasError={hasError}
              renderFetching={() => (
                <React.Fragment>
                  <CardPlaceholder variant="team_2" />
                  <CardPlaceholder variant="team_2" />
                </React.Fragment>
              )}
              render={() => (
                <ReviewCycleList
                  reviewCycles={reviewCycles}
                  testClassName="test-other-reviews"
                  reloadList={refetchData}
                />
              )}
            />
          </Column>
        </Columns>
      </ContentContainer>
    </React.Fragment>
  );
}

export default newDataLoader({
  fetch: ({ type }: Props) => get('review_cycles/others', { type }),
  hydrate: {
    reviewCycles: {},
  },
  cacheKey: ({ type }: Props) => `delegated-reviews-${type}`,
})(ReviewCyclesList) as React.ComponentType<Props>;
