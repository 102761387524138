import React from 'react';
import { connect } from 'react-redux';

import { type AppDispatch, errorNotice } from 'redux/actions';

type Upload =
  | {
      state: 'waiting';
      id: string;
      file: File;
    }
  | {
      state: 'uploading';
      id: string;
      file: File;
      progress: number;
    }
  | {
      state: 'error';
      id: string;
      file: File;
      error: string;
    }
  | {
      state: 'finished';
      id: string;
      file: File;
      signedId: string;
    };

type Props = {
  uploads: Array<Upload>;
  modelUpdateError: string | undefined | null;
  ready: boolean;
  render: (args: { isUploading: boolean }) => React.ReactNode;
};

type AfterConnectProps = {
  showErrorNotice: (errorMessage: string) => void;
} & Props;

/*
 * ProgressRenderer gathers all info from DirectUploader, about all upload statuses,
 * and does 2 things:
 *  - boils down all statuses to a simple overall `isUploading`
 *  - watches changes of statuses and display a notice in case of error
 */
class ProgressRenderer extends React.Component<AfterConnectProps> {
  static computeOverallUploadStatusFromProps(
    props: AfterConnectProps
  ): 'in_progress' | 'error' | 'finished' {
    const { modelUpdateError, uploads } = props;
    const anyUploadInProgress = uploads.find(upload =>
      ['waiting', 'uploading'].includes(upload.state)
    );
    const anyUploadInError = uploads.find(upload => upload.state === 'error');

    if (modelUpdateError || anyUploadInError) return 'error';
    if (anyUploadInProgress) return 'in_progress';

    return 'finished';
  }

  componentDidUpdate(prevProps: AfterConnectProps) {
    const previousOverallStatus =
      ProgressRenderer.computeOverallUploadStatusFromProps(prevProps);
    const currentOverallStatus =
      ProgressRenderer.computeOverallUploadStatusFromProps(this.props);

    if (currentOverallStatus === 'error' && previousOverallStatus !== 'error') {
      this.props.showErrorNotice(this.errorToDisplay());
    }
  }

  overallUploadStatus(): 'in_progress' | 'error' | 'finished' {
    return ProgressRenderer.computeOverallUploadStatusFromProps(this.props);
  }

  errorToDisplay(): string {
    const { modelUpdateError, uploads } = this.props;

    if (modelUpdateError) return modelUpdateError;

    return (
      uploads
        .filter(upload => upload.state === 'error')
        // @ts-ignore
        .map(upload => upload.error)
        .join(', ')
    );
  }

  render() {
    const { render } = this.props;
    const isUploading = this.overallUploadStatus() === 'in_progress';

    return render({ isUploading });
  }
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    showErrorNotice: (errorMessage: string) =>
      dispatch(errorNotice(errorMessage)),
  };
}

export default connect(
  null,
  // @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
  mapDispatchToProps
)(ProgressRenderer) as React.ComponentType<Props>;
