import React from 'react';

import { __ } from 'helpers/i18n';
import { useQuery } from 'helpers/navigation';

import { ContentContainer, DesignSystem, PageHeader } from 'components';

import TemplateCreator from './TemplateCreator';
import TemplateListWithPagination from './TemplateListWithPagination';

export default function Index() {
  const query = useQuery();
  const type = query.get('type');

  const title =
    type === '360' ? __('360 Feedback templates') : __('Review templates');

  return (
    <DesignSystem version={2}>
      <PageHeader
        title={title}
        actions={[
          <TemplateCreator key="add_template" interactionType={type || ''} />,
        ]}
      />
      <ContentContainer>
        <TemplateListWithPagination paginationType="url" templateType={type} />
      </ContentContainer>
    </DesignSystem>
  );
}
