import React, { ReactNode, useState } from 'react';

import { TrainingPeriod } from 'models';

import { useActiveUser } from 'helpers/hooks';
import {
  navigate,
  pathToManagerTrainingDashboardRequests,
} from 'helpers/navigation';
import queryString from 'helpers/queryString';

import { getUserFiltersFromQueryParams } from 'lib/dataLoader/pagination/paginationParams';

import { ActiveFilters } from 'components/Filters/types';

import TrainingPeriodPicker from 'scenes/components/TrainingPeriodPicker';
import UserMultiFilters from 'scenes/components/UserMultiFilters';

type RenderActionsProps = { additionalActions: ReactNode };

const useActions = (periodSlug: string) => {
  const activeUser = useActiveUser();
  const defaultUserFilters = getUserFiltersFromQueryParams(
    // @ts-expect-error: TSFIXME
    queryString.parse(window.location.search)
  ) || {
    direct_reports: {
      label: activeUser.fullName,
      value: activeUser.id,
    },
  };

  const [userFilters, setUserFilters] = useState<ActiveFilters | ''>(
    defaultUserFilters
  );

  const renderActions = (options?: RenderActionsProps) => (
    <div className="mb-4">
      <div className="flex justify-between">
        <div className="flex">
          <TrainingPeriodPicker
            renderingStyle="popover"
            currentPeriodSlug={periodSlug}
            onChange={(period: TrainingPeriod) =>
              navigate(pathToManagerTrainingDashboardRequests(period.slug))
            }
          />
          <UserMultiFilters
            className="ml-3"
            userFilters={userFilters}
            onChange={({ userFilters }) => setUserFilters(userFilters)}
          />
        </div>
        {options?.additionalActions}
      </div>
    </div>
  );

  return { renderActions, userFilters };
};

export default useActions;
