const enMessages = require('./en.json');
const frMessages = require('./fr.json');
const itMessages = require('./it.json');
const deMessages = require('./de.json');
const nlMessages = require('./nl.json');
const esMessages = require('./es.json');

export type SupportedLocale = 'en' | 'fr' | 'it' | 'de' | 'nl' | 'es';

const messages = {
  en: enMessages,
  fr: frMessages,
  it: itMessages,
  de: deMessages,
  nl: nlMessages,
  es: esMessages,
} as const;
export const supportedLocales: Array<SupportedLocale> = Object.keys(
  messages
) as SupportedLocale[];
export function getGettextMessagesForLocale(locale: SupportedLocale) {
  if (!messages[locale]) {
    return getGettextMessagesForLocale('en');
  }

  // Cloning seems to be needed, because `messages[locale]` has a required empty key
  // I tried to remove it, be warned! :)
  return { ...messages[locale] };
}
