import React, { useEffect, useRef } from 'react';

import { history } from 'config/history';

import can from 'helpers/can';
import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { useQuery } from 'helpers/navigation';
import { navigate, pathToNewReviewCycle } from 'helpers/navigation';
import queryString from 'helpers/queryString';

import { parsePaginationParams } from 'lib/dataLoader/pagination/paginationParams';

import {
  Button,
  ContentContainer,
  DesignSystem,
  Icon,
  PageHeader,
  PageTitle,
  Testable,
  Text,
} from 'components';

import TagPicker from 'scenes/components/TagPicker';

import ReviewCycleListWithPagination from './ReviewCycleListWithPagination';

const ReviewCycleIndex = () => {
  const query = useQuery();
  const type = query.get('type');
  const organization = useCurrentOrganization();
  const canCreateCampaigns = can({
    perform: 'create_review_campaigns',
    on: organization,
  });
  const pageTitle =
    type === 'one_on_one' ? __('Review campaigns') : __('360 review campaigns');

  const urlQueryParams = () =>
    parsePaginationParams(
      // @ts-expect-error: TSFIXME
      queryString.parse(window.location.search)
    );
  const [selectedTagLabels, setSelectedTagLabels] = React.useState<
    Array<string>
    // @ts-ignore
  >(urlQueryParams().tagLabels || []);
  const isFirstRender = useRef(true);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }

    const params = {
      ...urlQueryParams(),
      tagLabels: selectedTagLabels,
      page: 1,
    };
    history.replace(
      history.location.pathname + '?' + queryString.stringify(params)
    );
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTagLabels.join(',')]);

  return (
    <DesignSystem version={2}>
      <PageTitle title={[pageTitle, __('Admin')]} />
      <PageHeader
        title={pageTitle}
        actions={
          canCreateCampaigns && (
            <Button
              color="primary"
              onClick={() => navigate(pathToNewReviewCycle(type))}
            >
              <Testable name="test-creation-button">
                <Icon name="add" className="mr-2" />
                <span>{__('New campaign')}</span>
              </Testable>
            </Button>
          )
        }
      />
      <ContentContainer>
        <div className="flex items-center mb-4">
          <Text className="mr-2">{__('Filter by tags:')}</Text>
          <TagPicker
            context={
              type === 'one_on_one'
                ? 'review_cycle'
                : 'three_sixty_review_cycle'
            }
            selectedTagLabels={selectedTagLabels}
            className="min-w-64"
            placeholder={__('Select one or several tags')}
            onTagsChange={tags =>
              setSelectedTagLabels(tags.map(tag => tag.label))
            }
          />
        </div>
        <ReviewCycleListWithPagination
          paginationType="url"
          cycleType={type}
          defaultPaginationParams={{
            filter: { ongoing: true },
            sort: { date: 'desc' },
          }}
          tagLabels={selectedTagLabels}
        />
      </ContentContainer>
    </DesignSystem>
  );
};

export default ReviewCycleIndex;
