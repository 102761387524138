import classNames from 'classnames';
import React from 'react';

import { __ } from 'helpers/i18n';

import { Checkbox, Icon, Link, Tooltip } from 'components';

import AIIcon from 'scenes/components/AIIcon';

type SuggestionsAccordionCourseRowProps = {
  logoUrl: string;
  logoAlt: string;
  title: string;
  link: string;
  isAuto?: boolean;
  openInNewTab?: boolean;
  isChecked?: boolean;
  onCheckboxClick?: (checked: boolean) => void;
  onCourseDeleteClick?: () => void;
  onDeleteClick?: () => void;
};

const SuggestionsAccordionCourseRow = ({
  logoUrl,
  logoAlt,
  title,
  link,
  isAuto,
  openInNewTab,
  isChecked,
  onCheckboxClick,
  onDeleteClick,
}: SuggestionsAccordionCourseRowProps) => {
  const selectable = isChecked !== undefined;
  return (
    <div
      className={classNames(
        'flex items-center py-2 pr-4',
        !selectable && 'hover:bg-ui-bg'
      )}
    >
      <div className="w-[var(--accordion-pad-left)] text-center">
        {selectable && (
          <Checkbox isChecked={isChecked} onChange={onCheckboxClick} />
        )}
      </div>
      <div className="flex items-center gap-2">
        <img src={logoUrl} alt={logoAlt} className="w-7 h-7 rounded-full" />
        <Link
          to={link}
          openInNewTab={openInNewTab}
          withOpenInNewTabIcon={false}
        >
          {title}
        </Link>
        {isAuto && (
          <Tooltip
            content={__('This training course was suggested by Elevo AI.')}
          >
            <AIIcon className="text-text-light" />
          </Tooltip>
        )}
      </div>
      <div className="flex-grow min-w-4" />
      {onDeleteClick && (
        <Link onClick={onDeleteClick}>
          <Icon name="close" className="text-text-soften" />
        </Link>
      )}
    </div>
  );
};

export default SuggestionsAccordionCourseRow;
