import React from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { PeopleReviewMappingData, UserFilterSegment } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import newDataLoader from 'lib/dataLoader/newDataLoader';
import { get } from 'redux/actions/api';

import { NoNullableValues } from 'types/predicates/NoNullValues';

import { Box, FetchContainer, InfoTooltip, Title } from 'components';
import { ActiveFilters } from 'components/Filters/types';
import Heatmap from 'components/Heatmap';
import EmptyStateWithIcon from 'components/emptyStates/EmptyStateWithIcon';

import { SelectedDimensionIds } from '.';
import PeopleReviewCycleHeatmapCell from './PeopleReviewCycleHeatmapCell';

type Props = {
  cycleId: string;
  selectedDimensionIds: NoNullableValues<SelectedDimensionIds>;
  userFilter?: UserFilterSegment | null;
  userFilters?: ActiveFilters | '' | null;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    peopleReviewMappingData: PeopleReviewMappingData | undefined;
  };

const PeopleReviewCycleHeatmap = ({
  peopleReviewMappingData,
  cycleId,
  selectedDimensionIds,
  userFilter,
  userFilters,
  isFetching,
  hasError,
}: AfterDataLoaderProps) => (
  <FetchContainer
    isFetching={isFetching}
    hasError={hasError}
    render={() => {
      invariant(
        !!peopleReviewMappingData,
        'peopleReviewMappingData should be defined'
      );
      return peopleReviewMappingData.matchingPeopleUserReviewCount === 0 ? (
        <EmptyStateWithIcon
          iconName="person_off"
          title={__('No people review to display')}
          description={
            userFilter
              ? __(
                  'There are no people review completed with the two selected dimensions matching the filter'
                )
              : __(
                  'There are no people review completed with the two selected dimensions'
                )
          }
        />
      ) : (
        <Box>
          <Title size={4} className="mb-4">
            {peopleReviewMappingData.eligiblePeopleUserReviewCount ===
            peopleReviewMappingData.matchingPeopleUserReviewCount
              ? n__(
                  'Mapping of %1 employee',
                  'Mapping of %1 employees',
                  peopleReviewMappingData.matchingPeopleUserReviewCount
                )
              : n__(
                  'Mapping of %1 employee of %2',
                  'Mapping of %1 employees of %2',
                  peopleReviewMappingData.matchingPeopleUserReviewCount,
                  peopleReviewMappingData.eligiblePeopleUserReviewCount
                )}
            &nbsp;
            <InfoTooltip>
              {__(
                'Only employees whose people review has been completed and for whom the selected dimensions has been defined are included in the matrix.'
              )}
            </InfoTooltip>
          </Title>
          <Heatmap
            matrixData={peopleReviewMappingData.matrixData}
            axesData={peopleReviewMappingData.axesData}
            renderCell={(position, labels, colorStyle, cellData) => (
              <PeopleReviewCycleHeatmapCell
                key={`x${position.x}y${position.y}`}
                cycleId={cycleId}
                dimensionIds={selectedDimensionIds}
                style={colorStyle}
                position={position}
                dimensionLabels={labels}
                cellData={cellData}
                userFilter={userFilter}
                userFilters={userFilters}
              />
            )}
          />
        </Box>
      );
    }}
  />
);

export default newDataLoader({
  // TODO: multifilters: remove userFilter when FF is enabled for everyone
  fetch: ({ cycleId, selectedDimensionIds, userFilter, userFilters }: Props) =>
    get(`people_review_cycles/${cycleId}/mapping/data`, {
      userFilter,
      userFilters,
      ...selectedDimensionIds,
    }),
  hydrate: {
    peopleReviewMappingData: {},
  },
  cacheKey: ({ selectedDimensionIds, userFilter, userFilters }: Props) =>
    compositeKey({ ...selectedDimensionIds, userFilter, userFilters }),
})(PeopleReviewCycleHeatmap) as React.ComponentType<Props>;
