import React from 'react';
import { connect } from 'react-redux';
import uuidv4 from 'uuid/v4';

import type { AppDispatch } from '../../redux/actions/types';
import type { IncludedResourcesRelationships } from './JsonApiResponse';
import type { DataConnector, DataRenderer } from './RequestConnector';
import type { RequestStatus } from './reducer';
import type {
  GenericJSONAPIResponse,
  JSONAPIResource,
  JSONObject,
} from 'lib/api/types';
import type { JsonApiResourceMetadataWithRelationships } from 'lib/dataLoader/JsonApiResponse';

import { RequestConnector } from './RequestConnector';
import { requestFailed, requestStarted, requestSucceeded } from './actions';

export type DataFetcher = (dispatch: AppDispatch) => Promise<any>;

export type DataLoaderProvidedProps = {
  isFetching: boolean;
  hasError: boolean;
  noContent: boolean;
  refetchData: () => Promise<any>;
};

type DataLoaderProps<TPropsAfterConnect> = {
  fetch: DataFetcher;
  connect: DataConnector<TPropsAfterConnect>;
  render: DataRenderer<TPropsAfterConnect>;
  cacheKey?: string;
};

type DataLoaderPropsAfterConnect<TPropsAfterConnect extends {}> = {
  dispatch: AppDispatch;
  fetch: DataFetcher;
  connect: DataConnector<TPropsAfterConnect>;
  cacheKey?: string;
  render: DataRenderer<TPropsAfterConnect>;
  connectedProps: TPropsAfterConnect;
  getRequestStatus: (requestId: string) => RequestStatus | undefined | null;
};

type DataLoaderState = {
  requestId: string | undefined | null;
};

class _DataLoader<TPropsAfterConnect extends {}> extends React.Component<
  DataLoaderPropsAfterConnect<TPropsAfterConnect>,
  DataLoaderState
> {
  state = {
    requestId: null,
  };

  fetch = async () => {
    const { dispatch, fetch, cacheKey } = this.props;

    const requestId = !!cacheKey ? cacheKey : uuidv4();

    this.setState({
      requestId,
    });

    await dispatch(requestStarted(requestId));

    let result;
    try {
      result = await dispatch(fetch);
    } catch (e) {
      await dispatch(requestFailed(requestId));
      return;
    }

    const jsonApiResponse = result && result.response && result.response.body;
    await dispatch(
      requestSucceeded(
        requestId,
        jsonApiResponse
          ? {
              data: this.getResourcesMetadataAndRelationships(jsonApiResponse),
              included: this.getAllIncludedResourcesSet(jsonApiResponse),
            }
          : null
      )
    );
  };

  componentDidMount() {
    this.fetch();
  }

  componentDidUpdate(prevProps) {
    if (this.props.cacheKey !== prevProps.cacheKey) {
      this.fetch();
    }
  }

  getResourcesMetadataAndRelationships(
    response?: GenericJSONAPIResponse
  ):
    | Array<JsonApiResourceMetadataWithRelationships>
    | JsonApiResourceMetadataWithRelationships {
    if (!response || !response.data) return [];

    if (Array.isArray(response.data)) {
      return response.data.map(resource => {
        const { relationships, id, type } = resource;
        return { relationships, id, type };
      });
    } else {
      const { relationships, id, type } = response.data;
      return { relationships, id, type };
    }
  }

  getAllIncludedResourcesSet(
    response?: GenericJSONAPIResponse
  ): IncludedResourcesRelationships {
    if (!response || !response.included) return {};
    const includedResourcesSet = {};
    const resourcesFromIncludedSection = response.included;
    const resourcesFromDataSection: Array<JSONAPIResource<JSONObject>> =
      Array.isArray(response.data) ? response.data : [response.data];

    const allIncludedResources = (resourcesFromIncludedSection || []).concat(
      resourcesFromDataSection
    );

    if (allIncludedResources.length < 1) return includedResourcesSet;

    for (let resource of allIncludedResources) {
      if (!includedResourcesSet[resource.type]) {
        includedResourcesSet[resource.type] = {};
      }

      includedResourcesSet[resource.type][parseInt(resource.id, 10)] =
        resource.relationships || {};
    }

    return includedResourcesSet;
  }

  render() {
    const { render, connect } = this.props;
    const { requestId } = this.state;

    if (!requestId) return null;

    return (
      <RequestConnector
        requestId={requestId}
        connect={connect}
        render={render}
        fetch={this.fetch}
      />
    );
  }
}

// @ts-expect-error: TSFIXME if you have the courage
const DataLoader: React.ComponentType<DataLoaderProps<any>> =
  // @ts-expect-error: TSFIXME if you have the courage
  connect()(_DataLoader);

type PropsToFetchAndConnect<T, U> = (props: T) => {
  fetch: DataFetcher;
  connect: DataConnector<U>;
  cacheKey?: string;
};

// FIXME: Actually type this thing
export const dataLoader = (
  propsToFetchAndConnect: PropsToFetchAndConnect<any, any>
) => {
  return (OriginalComponent: React.ComponentType<any>) => {
    const NewComponent: React.ComponentType<any> = (originalProps: any) => {
      const { fetch, connect, cacheKey } =
        propsToFetchAndConnect(originalProps);

      return (
        <DataLoader
          fetch={fetch}
          connect={connect}
          cacheKey={cacheKey}
          render={providedProps => (
            <OriginalComponent {...originalProps} {...providedProps} />
          )}
        />
      );
    };

    return NewComponent;
  };
};
