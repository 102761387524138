import React, { useState } from 'react';
import { compose } from 'redux';

import type { WithDeprecatedPaginationProps } from 'lib/dataLoader/pagination/withDeprecatedPagination';

import { PeopleUserReview } from 'models';
import { PeopleUserReviewPlannedAction } from 'models';
import { PaginatedCollection } from 'models/Collection';

import can from 'helpers/can';
import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';

import {
  DataLoaderProvidedProps,
  PaginationProps,
  newDataLoader,
  withDeprecatedPagination,
} from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  Button,
  DatatableWrapper,
  EmptyStateWithIcon,
  Helper,
  LevelRight,
  SubTitle,
  Text,
  Title,
} from 'components';

import PeopleReviewActionsWithPagination from '../../../components/PeopleReviewActionsWithPagination';
import ActionPlanCreationModal from './ActionPlan/CreationModal';

type Props = WithDeprecatedPaginationProps & {
  review: PeopleUserReview;
};

type AfterPaginateProps = Props & PaginationProps;

type AfterDataLoaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    peopleReviewActionsCollection: PaginatedCollection<PeopleUserReviewPlannedAction>;
  };

function ActionPlanBlock({
  peopleReviewActionsCollection,
  review,
  refetchData,
  ...otherProps
}: AfterDataLoaderProps) {
  const { isFetching, hasError } = otherProps;
  const title = __(
    'What actions need to be planned regarding %1?',
    review.user.firstName
  );
  const [creationModalIsActive, setCreationModalIsActive] = useState(false);
  const afterCreate = () => {
    refetchData();
    setCreationModalIsActive(false);
  };
  const canUpdateActions = can({ perform: 'update', on: review });

  return (
    <Box
      className="question-block block-content-wrapper"
      testClassName="test-question-block"
    >
      <div className="presentational-information">
        <Title
          size={5}
          weight="bold"
          preserveLinebreaks
          className="is-spaced block-title"
          testClassName="test-block-title"
        >
          {title}
        </Title>

        <SubTitle size={6} className="mb-4">
          {__(
            "Plan your actions and find them in %1's People Review or via the Actions Plans menu for optimal tracking.",
            review.user.firstName
          )}
        </SubTitle>

        <Helper className="mb-2">
          <Text>
            {__(
              'Actions are only visible to administrators and People Review responsibles. They are not displayed to employees.'
            )}
          </Text>
        </Helper>

        <div className="actions-plan">
          <DatatableWrapper
            collectionInfo={peopleReviewActionsCollection}
            {...otherProps}
            onUserFilterChange={undefined}
            // @ts-ignore TSFIXME: Fix strictNullChecks error
            onSearchChange={undefined}
            totalCountRenderer={totalCount =>
              // @ts-ignore TSFIXME: Fix strictNullChecks error
              n__('%1 action', '%1 actions', totalCount)
            }
            renderNoRecord={() => (
              <EmptyStateWithIcon
                iconName="rule"
                title={__('No action has been created at the moment.')}
                inBox
              />
            )}
          >
            <PeopleReviewActionsWithPagination
              peopleReviewActionsCollection={peopleReviewActionsCollection}
              compactDisplay
              isFetching={isFetching}
              hasError={hasError}
              refetchData={refetchData}
            />
          </DatatableWrapper>
        </div>
        <LevelRight className="mt-4">
          {canUpdateActions && (
            <React.Fragment>
              <Button
                color="secondary"
                onClick={() => setCreationModalIsActive(true)}
                className="float-right"
              >
                {__('Plan action')}
              </Button>
              <ActionPlanCreationModal
                review={review}
                isActive={creationModalIsActive}
                onClose={() => setCreationModalIsActive(false)}
                afterCreate={afterCreate}
              />
            </React.Fragment>
          )}
        </LevelRight>
      </div>
    </Box>
  );
}

export default compose<React.ComponentType<Props>>(
  withDeprecatedPagination,
  newDataLoader({
    fetch: ({ page, countPerPage, review }: AfterPaginateProps) =>
      get('planned/people_review_actions', {
        page,
        countPerPage,
        reviewId: review.id,
      }),
    hydrate: {
      peopleReviewActionsCollection: {
        items: {
          abilities: {},
          user: {},
        },
      },
    },
    cacheKey: ({ page, countPerPage, review }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, reviewId: review.id }),
  })
)(ActionPlanBlock);
