import React, { createContext, useState } from 'react';

import { useActiveUser, useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { anyTemplateWithSkillsInCycle } from 'helpers/models/reviewCycle';
import {
  pathToManagerReviewCycle,
  pathToManagerReviewCycleAnalytics,
  pathToManagerReviewCycleResults,
  pathToManagerReviewCycleSkills,
  pathToMyTeamReviews,
} from 'helpers/paths';
import { Match } from 'helpers/paths';
import queryString from 'helpers/queryString';

import { newDataLoader } from 'lib/dataLoader';
import { getUserFiltersFromQueryParams } from 'lib/dataLoader/pagination/paginationParams';
import { get } from 'redux/actions/api';

import {
  DesignSystem,
  FetchContainer,
  PageHeader,
  PageTitle,
  Redirect,
  Route,
  Switch,
} from 'components';
import { ActiveFilters } from 'components/Filters/types';

import ExportMenuButton from 'scenes/admin/reviewCycles/Manage/Header/ExportMenuButton';
import LaunchingCycle from 'scenes/admin/reviewCycles/Manage/LaunchingCycle';

import ManagerCycleAnalytics from './ManagerCycleAnalytics';
import ManagerCycleResults from './ManagerCycleResults';
import ManagerCycleSkills from './ManagerCycleSkills';

type DataContextType = {
  withUserMultiFilters: boolean;
  userFilters: ActiveFilters | '';
  setUserFilters: (filters: ActiveFilters | '') => void;
};

export const DataContext = createContext<DataContextType>(
  {} as DataContextType
);

type RouterProps = {
  match: Match<{ id: string }>;
};
type Props = RouterProps;

function ReviewCycleIndex({ match, reviewCycle, isFetching, hasError }) {
  const activeUser = useActiveUser();
  const { featureFlags } = useCurrentOrganization();
  const shouldShowSkillsTab =
    featureFlags.includes('mockDataForSkillsAndCareersAnalytics') ||
    featureFlags.includes('skillsAndCareersAnalytics');

  const withUserMultiFilters = featureFlags.includes('userMultifilters');
  const defaultUserFilters = getUserFiltersFromQueryParams(
    // @ts-expect-error: TSFIXME
    queryString.parse(window.location.search)
  ) || {
    direct_reports: {
      label: activeUser.fullName,
      value: activeUser.id,
    },
  };

  const [userFilters, setUserFilters] = useState<ActiveFilters | ''>(
    defaultUserFilters
  );

  return (
    <DataContext.Provider
      value={{ withUserMultiFilters, userFilters, setUserFilters }}
    >
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => {
          if (reviewCycle.status === 'launching') {
            return (
              <React.Fragment>
                <PageTitle title={[reviewCycle.name, __('Manager')]} />
                <LaunchingCycle reviewCycle={reviewCycle} />
              </React.Fragment>
            );
          }

          const tabItems = [
            {
              label: __('Progress'),
              to: pathToManagerReviewCycleAnalytics(match.params.id),
              testName: 'test-progress-link',
            },
            {
              label: __('Results'),
              to: pathToManagerReviewCycleResults(match.params.id),
              testName: 'test-results-link',
            },
          ];

          if (
            shouldShowSkillsTab &&
            anyTemplateWithSkillsInCycle(reviewCycle)
          ) {
            tabItems.push({
              label: __('Skills'),
              to: pathToManagerReviewCycleSkills(match.params.id),
              testName: 'test-skills-link',
            });
          }

          return (
            <DesignSystem version={2}>
              <PageHeader
                title={reviewCycle.name}
                withBackButton
                backButtonProps={{
                  target: pathToMyTeamReviews(reviewCycle.interactionType),
                  children: __('Back to home'),
                }}
                tabItems={tabItems}
                actions={[
                  <ExportMenuButton
                    reviewCycle={reviewCycle}
                    formats={['xlsx']}
                  />,
                ]}
              />

              <Switch>
                <Route
                  exact
                  path={`${match.path}/analytics`}
                  render={() => (
                    <ManagerCycleAnalytics reviewCycle={reviewCycle} />
                  )}
                />
                <Route
                  exact
                  path={`${match.path}/results`}
                  render={() => (
                    <ManagerCycleResults reviewCycle={reviewCycle} />
                  )}
                />
                <Route
                  exact
                  path={`${match.path}/skills`}
                  render={() => (
                    <ManagerCycleSkills reviewCycle={reviewCycle} />
                  )}
                />

                <Redirect to={pathToManagerReviewCycle(match.params.id)} />
              </Switch>
            </DesignSystem>
          );
        }}
      />
    </DataContext.Provider>
  );
}

export default newDataLoader({
  fetch: (ownProps: Props) => get(`review_cycles/${ownProps.match.params.id}`),
  hydrate: {
    reviewCycle: {
      abilities: {},
      defaultTemplate: {},
      templates: {},
      lastPendingExportAction: {},
    },
  },
})(ReviewCycleIndex);
