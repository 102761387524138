import type { UserFilterableFieldType } from 'models';

import { useAppSelector } from 'helpers/hooks';
import invariant from 'helpers/invariant';

import { getUserFilterableFields } from 'redux/reducers';

const useUserFilterableFields = (): Array<UserFilterableFieldType> => {
  return useAppSelector(state => {
    const userFilterableFields = getUserFilterableFields(state);

    invariant(
      userFilterableFields,
      'Organization user filterable fields should be defined.'
    );

    return userFilterableFields;
  });
};

export default useUserFilterableFields;
