import React, { useState } from 'react';

import { MessagingChatRoom, SurveyAnswer } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { post } from 'redux/actions/api';

import {
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
} from 'components';

import ChatRoomConversation from './ChatRoomConversation';
import ChatRoomInput from './ChatRoomInput';
import MessageBox from './MessageBox';

type Props = {
  answer: SurveyAnswer;
  messagingChatRoom?: MessagingChatRoom | null;
  campaignName?: string;
  questionName?: string;
  initialParticipantDisplayName?: null | string;
  postAsAnonymous?: boolean;
  onClose: (
    chatRoom: MessagingChatRoom | null | undefined,
    shouldRefresh: boolean
  ) => void;
};

const ChatRoomModal = ({
  answer,
  messagingChatRoom,
  campaignName,
  questionName,
  initialParticipantDisplayName,
  postAsAnonymous = false,
  onClose,
}: Props) => {
  const dispatch = useAppDispatch();
  const [chatRoom, setChatRoom] = useState(
    messagingChatRoom || answer?.messagingChatRoom
  );
  const [shouldRefresh, setShouldRefresh] = useState(false);
  const [sentMessagesCount, setSentMessagesCount] = useState(0);
  const createChatRoom = async (messageContent: string) => {
    const { response } = await dispatch(
      post('survey/messaging/chat_rooms', {
        messagingSourceId: answer?.id || messagingChatRoom?.messagingSourceId,
        messagingSourceType: 'Survey::Answer',
        name: 'Anonymous discussion',
        messageContent: messageContent,
      })
    );
    setChatRoom(response.body.data);
  };
  const createMessage = async (messageContent: string) => {
    if (!chatRoom) {
      return;
    }

    await dispatch(
      post(`survey/messaging/chat_rooms/${chatRoom?.id}/messages`, {
        chatRoomId: chatRoom?.id,
        messageContent: messageContent,
        participantId: postAsAnonymous ? chatRoom.anonymousParticipantId : null,
      })
    );
  };
  const sendMessage = async (messageContent: string) => {
    if (chatRoom?.id) {
      await createMessage(messageContent);
    } else {
      await createChatRoom(messageContent);
    }
    setSentMessagesCount(sentMessagesCount => sentMessagesCount + 1);
    setShouldRefresh(true);
  };
  return (
    <ModalCard
      isActive
      isLarge
      onClose={() => onClose(chatRoom, shouldRefresh)}
      refreshContentOnOpening={false}
    >
      <ModalCardHead onClose={() => onClose(chatRoom, shouldRefresh)}>
        <ModalCardTitle>
          {__('Answer follow-up conversation')}
          <Text preset="14s6" style={{ fontWeight: 'normal' }}>
            <div>{campaignName || messagingChatRoom?.campaignName}</div>
            <div>{questionName || messagingChatRoom?.questionName}</div>
          </Text>
        </ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody
        style={{ display: 'flex', flexDirection: 'column-reverse' }}
      >
        {chatRoom?.id ? (
          <ChatRoomConversation
            messagingChatRoomId={chatRoom?.id}
            sentMessagesCount={sentMessagesCount}
          />
        ) : (
          <MessageBox
            message={answer.text || __('Did not leave a comment')}
            userAvatarUrl={''}
            userName={
              initialParticipantDisplayName
                ? initialParticipantDisplayName
                : __('Anonymous')
            }
            alignAvatar="left"
          />
        )}
      </ModalCardBody>
      <ModalCardFooter>
        <ChatRoomInput onSubmit={sendMessage} />
      </ModalCardFooter>
    </ModalCard>
  );
};

export default ChatRoomModal;
