import type { JsonApiStrippedResponse } from '../JsonApiResponse';
import type { DataState } from 'redux/reducers/DataStateType';

import hydrate from './hydrate';

export default function hydrateFromResponse(
  dataState: DataState,
  response: JsonApiStrippedResponse | undefined | null,
  relationshipsTree: {},
  memoizeKey?: string
) {
  const data = response ? response.data : (null as any);
  const relationshipsSubtree = (
    Object.values(relationshipsTree) as Array<{}>
  )[0];
  return hydrate(dataState, data, relationshipsSubtree, memoizeKey);
}
