import React from 'react';
import { connect } from 'react-redux';

import type {
  AvailableCriterionKey,
  ReviewTemplate,
  ReviewTemplateAssignmentCriterion,
} from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { post } from 'redux/actions/api';

import AssignmentCriterion from './AssignmentCriterion';

type Props = {
  reviewCycleId: string;
  defaultTemplateAssignmentCriterion: ReviewTemplateAssignmentCriterion;
  dynamicTemplateAssignmentCriteria: Array<ReviewTemplateAssignmentCriterion>;
  interactionTypeCompatibleTemplates: Array<ReviewTemplate>;
  defaultTemplateCompatibleTemplates: Array<ReviewTemplate>;
};

type ReviewTemplateAssignmentCriterionUpdateParams = {
  criterion: {
    // eslint-disable-next-line no-unused-vars
    [key in AvailableCriterionKey]: string | undefined | null;
  };
  reviewTemplateId: string | undefined | null;
};

type AfterConnectProps = Props & {
  updateAssignmentCriterion: (
    attributes: ReviewTemplateAssignmentCriterionUpdateParams
  ) => Promise<void>;
};

function AssignmentCriteria({
  defaultTemplateAssignmentCriterion,
  dynamicTemplateAssignmentCriteria,
  interactionTypeCompatibleTemplates,
  defaultTemplateCompatibleTemplates,
  updateAssignmentCriterion,
}: AfterConnectProps) {
  const defaultTemplateIsDefined =
    !!defaultTemplateAssignmentCriterion.template;

  const sortedDynamicTemplateCriteria = dynamicTemplateAssignmentCriteria.sort(
    (criterion1, criterion2) => {
      if (criterion1.isDefault) return -1;
      const criterionLabel1 = criterion1.segment.label;
      const criterionLabel2 = criterion2.segment.label;

      if (!criterionLabel1) return -1;
      if (!criterionLabel2) return 1;
      return criterionLabel1.toLowerCase() < criterionLabel2.toLowerCase()
        ? -1
        : 1;
    }
  );

  return (
    <React.Fragment>
      <AssignmentCriterion
        assignmentCriterion={defaultTemplateAssignmentCriterion}
        disabled={false}
        compatibleTemplates={interactionTypeCompatibleTemplates}
        onTemplateUpdate={reviewTemplateId =>
          updateAssignmentCriterion({
            // @ts-ignore TSFIXME
            criterion: {},
            reviewTemplateId,
          })
        }
      />

      {sortedDynamicTemplateCriteria.map(assignmentCriterion => (
        <AssignmentCriterion
          key={assignmentCriterion.id}
          assignmentCriterion={assignmentCriterion}
          disabled={!defaultTemplateIsDefined}
          isClearable
          compatibleTemplates={defaultTemplateCompatibleTemplates}
          onTemplateUpdate={reviewTemplateId =>
            updateAssignmentCriterion({
              // @ts-ignore TSFIXME
              criterion: assignmentCriterion.criterion,
              reviewTemplateId,
            })
          }
        />
      ))}
    </React.Fragment>
  );
}

const mapDispatchToProps = (
  dispatch: AppDispatch,
  { reviewCycleId }: Props
) => ({
  updateAssignmentCriterion: (
    attributes: ReviewTemplateAssignmentCriterionUpdateParams
  ) =>
    dispatch(
      post(
        `review_cycles/${reviewCycleId}/review_template_assignment_criteria`,
        { reviewTemplateAssignmentCriterion: attributes }
      )
    ),
});

export default connect(
  null,
  // @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
  mapDispatchToProps
)(AssignmentCriteria) as React.ComponentType<Props>;
