import React from 'react';

import classNames from 'helpers/classNames';

import { Flex } from 'components';

import BackNavigationItem from './BackNavigationItem';
import NavigationList, { type NavigationItem } from './NavigationList';

type TabsPosition = 'left' | 'center' | 'right';
type TabsSize = 'default' | 'small' | 'medium' | 'large';
type TabsStyle = 'default' | 'boxed' | 'toggle' | 'toggle-rounded';
type TabsWidth = 'default' | 'fit-content' | 'full-width';

export type Props = {
  items?: Array<NavigationItem>;
  tabsPosition: TabsPosition;
  tabsSize: TabsSize;
  tabsStyle: TabsStyle;
  tabsWidth?: TabsWidth;
  backItem?: NavigationItem;
  leftContent?: React.ReactNode;
  rightContent?: React.ReactNode;
  className?: string;
};

const Tabs = ({
  items,
  tabsPosition,
  tabsSize,
  tabsStyle,
  tabsWidth,
  backItem,
  leftContent,
  rightContent,
  className,
}: Props) => {
  return (
    <div
      className={classNames(
        'tabs',
        'item',
        {
          'is-centered': tabsPosition === 'center',
          'is-right': tabsPosition === 'right',
          'is-small': tabsSize === 'small',
          'is-medium': tabsSize === 'medium',
          'is-large': tabsSize === 'large',
          'is-boxed': tabsStyle === 'boxed',
          'is-toggle': tabsStyle === 'toggle' || tabsStyle === 'toggle-rounded',
          'fit-content': tabsWidth === 'fit-content',
          'is-fullwidth': tabsWidth === 'full-width',
          'is-toggle-rounded': tabsStyle === 'toggle-rounded',
        },
        className
      )}
    >
      {(backItem || leftContent) && (
        <div className="left-content">
          {backItem ? <BackNavigationItem item={backItem} /> : leftContent}
        </div>
      )}

      {items && <NavigationList items={items} />}

      {rightContent && (
        <Flex className="right-content" verticalAlign>
          {rightContent}
        </Flex>
      )}
    </div>
  );
};

export const defaultProps = {
  tabsPosition: 'left',
  tabsSize: 'default',
  tabsStyle: 'default',
  tabsWidth: 'default',
};

Tabs.defaultProps = defaultProps;

export default Tabs;
