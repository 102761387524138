import React from 'react';

import type {
  TrainingPlanBudgetStats,
  TrainingPlanValidatedBudgetStats,
} from 'models';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { formatMoney } from 'helpers/money';

import { hasShape } from 'types/predicates/WithShape';

import { Link, Text } from 'components';

import BudgetVisualizer from '../components/BudgetVisualizer';
import DeprecatedStats from './DeprecatedStats';

type Props = {
  openDetailsModal: () => void;
  isValidatedPlan: boolean;
  stats: TrainingPlanBudgetStats;
};

const BudgetStatsCard = ({
  isValidatedPlan,
  stats,
  openDetailsModal,
}: Props) => {
  const link = (
    <Link
      additionalClassName="budget-card-link"
      onClick={openDetailsModal}
      testClassName="test-budget-details-link"
    >
      {__('Details')}
    </Link>
  );
  const { featureFlags } = useCurrentOrganization();
  const trainingPlansEnabled = featureFlags.includes('trainingPlans');

  if (!trainingPlansEnabled) {
    return (
      <DeprecatedStats
        link={link}
        stats={stats as TrainingPlanValidatedBudgetStats}
      />
    );
  }

  const validatedAmountCents = stats.validatedAmountCents;
  const forecastAmountCents = hasShape(stats, { forecastAmountCents: 0 })
    ? stats.forecastAmountCents
    : undefined;

  return (
    <div className="flex flex-col test-budget-stats-card">
      <div className="flex justify-between">
        <Text preset="16bs5.5">
          {isValidatedPlan ? __('Budget spent') : __('Budget')}
        </Text>
        {link}
      </div>

      {stats.provisionedAmountCents > 0 ? (
        <BudgetVisualizer
          className="mt-3"
          testClassName="test-total-budget-visualizer"
          provisionedAmountCents={stats.provisionedAmountCents}
          forecastAmountCents={forecastAmountCents}
          validatedAmountCents={validatedAmountCents}
          currency={stats.currency}
        />
      ) : (
        <div className="flex flex-col mt-1">
          <div>
            <Text>{__('Validated funding:')}</Text>
            <Text
              preset="14bs6"
              className="ml-1"
              testClassName="test-validated-amount"
            >
              {validatedAmountCents
                ? formatMoney(validatedAmountCents, stats.currency)
                : '-'}
            </Text>
          </div>
          {forecastAmountCents !== undefined && (
            <div>
              <Text>{__('Forecast funding:')}</Text>
              <Text
                preset="14bs6"
                className="ml-2"
                testClassName="test-forecast-amount"
              >
                {forecastAmountCents > 0
                  ? formatMoney(forecastAmountCents, stats.currency)
                  : '-'}
              </Text>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default BudgetStatsCard;
