import React from 'react';
import { connect } from 'react-redux';

import type {
  BlockFeedbackOptions,
  FeedbackableType,
  InteractionType,
} from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { handleFormErrors } from 'helpers/api';
import * as commonPhrases from 'helpers/commonPhrases';
import { __ } from 'helpers/i18n';

import { htmlErrorNotice, htmlWarningNotice } from 'redux/actions';
import { put } from 'redux/actions/api';

import { BoxSeparator, Title } from 'components';

import FeedbackOption from './FeedbackOption';

type Props = {
  revieweeFeedbackOptions: BlockFeedbackOptions | undefined;
  reviewerFeedbackOptions: BlockFeedbackOptions;
  hasSelfEvaluationEnabled: boolean;
  interactionType: InteractionType;
  blockType: FeedbackableType;
};

type AfterMapAppDispatchTopProps = Props & {
  onChange: (
    feedbackOptionId: string,
    attributes: Partial<BlockFeedbackOptions>
  ) => Promise<any>;
};

function FeedbackOptionsSection({
  revieweeFeedbackOptions,
  reviewerFeedbackOptions,
  hasSelfEvaluationEnabled,
  interactionType,
  blockType,
  onChange,
}: AfterMapAppDispatchTopProps) {
  const hasMandatory = blockType !== 'reviewedObjectivesModule';
  const revieweeLabel = () =>
    __('The reviewee') +
    (!hasSelfEvaluationEnabled ? __(' (Self evaluation is disabled)') : '');

  const reviewerLabel = () => {
    if (interactionType === '360' && hasSelfEvaluationEnabled)
      return __('The reviewee and the peers');

    if (interactionType === '360' && !hasSelfEvaluationEnabled)
      return __('The peers');

    return __('The reviewer');
  };

  const feedbackOptionSectionTitle = () => {
    if (blockType === 'dropdownQuestion')
      return __('Who can answer the dropdown list?');

    if (blockType === 'multipleScaleQuestion')
      return __('Who can answer the multiple scale?');

    if (blockType === 'reviewedObjectivesModule')
      return __('Who can rate the achievement of objectives?');

    if (blockType === 'skillsCareerLevel')
      return __('Who can rate the skills evaluation?');

    return __('Who can answer the question?');
  };

  const feedbackOptionCommentSectionTitle = () => {
    if (blockType === 'reviewedObjectivesModule')
      return __('Who can comment the achievement of objectives?');

    if (blockType === 'skillsCareerLevel')
      return __('Who can comment on the skills?');

    return __('Who can leave a comment?');
  };

  return (
    <div className="feedback-options">
      <Title size={7} transformation="uppercase" style={{ marginBottom: 8 }}>
        {feedbackOptionSectionTitle()}
      </Title>
      {interactionType === 'one_on_one' && (
        <FeedbackOption
          testClassName="test-reviewee-rating-enabled-toggle"
          feedbackOptions={revieweeFeedbackOptions}
          isDisabled={!hasSelfEvaluationEnabled}
          mandatoryDisabled={!hasSelfEvaluationEnabled}
          withMandatory={hasMandatory}
          label={revieweeLabel()}
          feedbackType="rating"
          onChange={onChange}
        />
      )}

      <FeedbackOption
        feedbackOptions={reviewerFeedbackOptions}
        label={reviewerLabel()}
        testClassName="test-reviewer-rating-enabled-toggle"
        onChange={onChange}
        isDisabled={
          interactionType === '360' &&
          ['multipleScaleQuestion', 'dropdownQuestion'].includes(blockType)
        }
        feedbackType="rating"
        withMandatory={hasMandatory}
      />

      <BoxSeparator style={{ margin: '16px 0' }} />

      <Title size={7} transformation="uppercase" style={{ marginBottom: 8 }}>
        {feedbackOptionCommentSectionTitle()}
      </Title>

      {interactionType === 'one_on_one' && (
        <FeedbackOption
          isDisabled={!hasSelfEvaluationEnabled}
          feedbackOptions={revieweeFeedbackOptions}
          mandatoryDisabled={!hasSelfEvaluationEnabled}
          withMandatory={hasMandatory}
          label={revieweeLabel()}
          onChange={onChange}
          feedbackType="text"
        />
      )}

      <FeedbackOption
        feedbackOptions={reviewerFeedbackOptions}
        label={reviewerLabel()}
        onChange={onChange}
        feedbackType="text"
        withMandatory={hasMandatory}
      />
    </div>
  );
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    onChange: async (
      feedbackOptionId: string,
      attributes: Partial<BlockFeedbackOptions>
    ) =>
      handleFormErrors(
        () =>
          dispatch(
            put(`feedback_options/${feedbackOptionId}`, {
              feedbackOption: attributes,
            })
          ),
        errors => {
          if (errors.invalidFeedbackOptions) {
            dispatch(
              htmlWarningNotice(
                __(
                  '<b>Oops, this configuration is not possible</b><br />For the question to be visible either the reviewee or the reviewer must be able to answer it 🙂'
                )
              )
            );
          } else {
            dispatch(htmlErrorNotice(commonPhrases.somethingWentWrong()));
          }
        }
      ),
  };
}

// @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
export default connect(null, mapDispatchToProps)(FeedbackOptionsSection);
