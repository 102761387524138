import React, { ReactNode } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { ReleaseActionInfo } from '../Review/ReleaseAction';
import type { Evaluation, UserReview } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import can from 'helpers/can';
import invariant from 'helpers/invariant';
import transformProps from 'helpers/transformProps';

import { put } from 'redux/actions/api';

import {
  OnCloseBlockingConfirm,
  SavingStatusConnector,
  Toggleable,
} from 'components';

import EvaluationShareability, {
  refreshEvaluationShareability,
} from '../components/EvaluationShareability';
import ShareEvaluationModal from './ShareEvaluationModal';

type Props = {
  evaluation?: Evaluation;
  refreshReview: () => Promise<void>;
  render: (shareAction: ReleaseActionInfo) => ReactNode;
};

type AfterTransformerProps = Props & {
  review?: UserReview;
  canShare: boolean;
};

type AfterConnectProps = AfterTransformerProps & {
  onShare: () => Promise<void>;
  refreshShareability: () => Promise<void>;
};

function ShareAction({
  onShare,
  canShare,
  evaluation,
  render,
  refreshShareability,
}: AfterConnectProps) {
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const memoizedRefreshShareability = React.useCallback(refreshShareability, [
    evaluation ? evaluation.id : '',
  ]);

  if (!evaluation)
    return render({
      isShown: false,
      isModalActive: false,
      refreshShareability: () => Promise.resolve(),
    });

  return (
    <EvaluationShareability
      evaluationId={evaluation.id}
      render={({ isShareable, shareDisabledReasons }) => {
        return (
          <SavingStatusConnector
            render={({ autoSaveStatus }) => {
              return (
                <React.Fragment>
                  <OnCloseBlockingConfirm
                    shouldBlock={autoSaveStatus !== 'saved'}
                  />
                  <Toggleable
                    render={(isToggled, toggle) => {
                      const isShown = canShare;
                      const isEnabled =
                        isShown && autoSaveStatus === 'saved' && isShareable;
                      const trigger = isEnabled ? toggle : undefined;
                      const disabledReasons = shareDisabledReasons;

                      return (
                        <React.Fragment>
                          <ShareEvaluationModal
                            isActive={isToggled}
                            onShare={onShare}
                            onClose={toggle}
                            evaluation={evaluation}
                          />
                          {render({
                            isShown,
                            isEnabled,
                            isModalActive: isToggled,
                            disabledReasons,
                            trigger,
                            refreshShareability: memoizedRefreshShareability,
                          })}
                        </React.Fragment>
                      );
                    }}
                  />
                </React.Fragment>
              );
            }}
          />
        );
      }}
    />
  );
}

function propsTransformer({
  evaluation,
}: Props): Omit<AfterTransformerProps, 'render' | 'refreshReview'> {
  const review = evaluation && evaluation.userReview;

  return {
    evaluation,
    review,
    canShare: !!evaluation && can({ perform: 'display_share', on: evaluation }),
  };
}

function mapDispatchToProps(
  dispatch: AppDispatch,
  { evaluation, review, refreshReview }: AfterTransformerProps
) {
  return {
    onShare: async () => {
      invariant(review, 'review should be defined');
      await dispatch(put(`user_reviews/${review.id}/share_for_active_user`));
      await refreshReview();
    },
    refreshShareability: () => {
      invariant(evaluation, 'review should be defined');
      return dispatch(refreshEvaluationShareability(evaluation.id));
    },
  };
}

export default compose(
  transformProps(propsTransformer),
  // @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
  connect(null, mapDispatchToProps)
)(ShareAction) as React.ComponentType<Props>;
