import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { ReviewTemplateWithDuplicateAction } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { __ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';

import { del, post } from 'redux/actions/api';

import { FetchContainer, PageTitle } from 'components';

import TemplateItem from './TemplateItem';

type Props = {
  reviewTemplates: Array<ReviewTemplateWithDuplicateAction>;
  refetchData: () => Promise<void>;
  isFetching: boolean;
  hasError: boolean;
};

type AfterDataloaderProps = Props & DataLoaderProvidedProps;

type AfterConnectProps = AfterDataloaderProps & {
  duplicateTemplate: (templateId: string) => Promise<void>;
  deleteTemplate: (templateId: string) => Promise<void>;
};

function TemplatesList({
  reviewTemplates,
  duplicateTemplate,
  deleteTemplate,
  isFetching,
  hasError,
  refetchData,
}: AfterConnectProps) {
  return (
    <React.Fragment>
      <PageTitle title={[__('Templates'), __('Admin')]} />
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        loadingStyle="overlay"
        render={() => {
          return (
            <div className="review-template-list">
              {reviewTemplates.map(reviewTemplate => (
                <TemplateItem
                  refetchData={refetchData}
                  key={reviewTemplate.id}
                  reviewTemplate={reviewTemplate}
                  duplicate={duplicateTemplate}
                  delete={deleteTemplate}
                />
              ))}
            </div>
          );
        }}
      />
    </React.Fragment>
  );
}

const mapDispatchToProps = (
  dispatch: AppDispatch,
  { refetchData }: AfterDataloaderProps
) => ({
  duplicateTemplate: (templateId: string) =>
    dispatch(post(`review_templates/${templateId}/duplicate`)),
  deleteTemplate: async (templateId: string) =>
    confirmAsync(
      __('Delete template'),
      __('Delete this template and its content?'),
      {
        confirmLabel: __('Delete template'),
        onConfirm: async () => {
          await dispatch(del(`review_templates/${templateId}`));
          refetchData();
        },
      }
    ),
});

export default compose<React.ComponentType<Props>>(
  // @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
  connect(null, mapDispatchToProps)
)(TemplatesList);
