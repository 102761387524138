import React from 'react';

import { useOrganizationPlan } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import {
  CheckboxSwitch,
  Column,
  Columns,
  Flex,
  PageTitle,
  Text,
  Title,
} from 'components';

const ENPSSettings = () => {
  const { enpsEnabled } = useOrganizationPlan();

  return (
    <React.Fragment>
      <PageTitle title={[__('eNPS'), __('Settings')]} />

      <div className="bg-white rounded p-5 max-w-[1000px] mx-auto mt-8 md:mt-0">
        <Columns contentVerticallyAligned>
          <Column>
            <Flex verticalAlign className="mb-2">
              <Title
                size={5}
                weight="semibold"
                className="!text-basic-800 mr-5"
              >
                {__('eNPS')}
              </Title>
              <Text color={enpsEnabled ? 'success' : 'grey'}>
                {enpsEnabled
                  ? __('Enabled_masculine')
                  : __('Disabled_masculine')}
              </Text>
            </Flex>
            <Text>
              {enpsEnabled
                ? __('The product "eNPS" is enabled on your platform.')
                : __('The product "eNPS" is disabled on your platform.')}
            </Text>
          </Column>
          <Column isNarrow>
            <CheckboxSwitch
              disabled
              value={enpsEnabled}
              onChange={() => {}}
              size="small"
            />
          </Column>
        </Columns>
      </div>
    </React.Fragment>
  );
};

export default ENPSSettings;
