import type { ReduxStore } from '../../redux/reducers/index';
import type { JsonApiStrippedResponse } from './JsonApiResponse';
import type {
  RequestFailedAction,
  RequestStartedAction,
  RequestSucceededAction,
} from './actions';

import { REQUEST_FAILED, REQUEST_STARTED, REQUEST_SUCCEEDED } from './actions';

export type RequestStatus = {
  requestId: string;
  isFetching: boolean;
  hasError: boolean;
  noContent?: boolean;
  value?: JsonApiStrippedResponse;
};

type RequestStatuses = {
  [requestId: string]: RequestStatus;
};

export const reducer = (
  state: RequestStatuses = {},
  action: RequestStartedAction | RequestSucceededAction | RequestFailedAction
): RequestStatuses => {
  const { requestId } = action;

  if (!requestId) return state;

  const requestStatuses = { ...state };

  if (!requestStatuses[requestId]) {
    requestStatuses[requestId] = {
      requestId,
      isFetching: false,
      hasError: false,
    };
  }

  switch (action.type) {
    case REQUEST_STARTED:
      requestStatuses[requestId] = {
        ...(requestStatuses[requestId] || {}),
        isFetching: true,
        hasError: false,
      };
      break;
    case REQUEST_SUCCEEDED:
      requestStatuses[requestId] = {
        ...(requestStatuses[requestId] || {}),
        isFetching: false,
        hasError: false,
        noContent: !action.value,
        ...action,
      };
      break;
    case REQUEST_FAILED:
      requestStatuses[requestId] = {
        ...(requestStatuses[requestId] || {}),
        isFetching: false,
        hasError: true,
      };
      break;
    default:
      return state;
  }

  return requestStatuses;
};

export const getRequestStatus = (
  state: ReduxStore,
  requestId: string
): RequestStatus => {
  const requestStatuses = state.requests;
  const defaultRequestStatus = { requestId, isFetching: true, hasError: false };

  if (!requestStatuses) return defaultRequestStatus;
  return requestStatuses[requestId] || defaultRequestStatus;
};
