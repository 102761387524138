import { omit } from 'lodash';
import React, { useEffect } from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { PaginationProps } from 'lib/dataLoader/pagination/types';
import type { WithDeprecatedPaginationProps } from 'lib/dataLoader/pagination/withDeprecatedPagination';
import type {
  PaginatedCollection,
  PeopleReviewCycle,
  PeopleUserReview,
} from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';
import { navigate } from 'helpers/navigation';

import { withDeprecatedPagination } from 'lib/dataLoader';
import newDataLoader from 'lib/dataLoader/newDataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  BoxList,
  BoxListItem,
  Column,
  Columns,
  DatatableWrapper,
  FetchContainer,
} from 'components';

import ReviewEmptyState from 'scenes/components/userReview/ListEmptyState/index';

import PeopleUserReviewListItem from './PeopleUserReviewListItem';
import PeopleUserReviewEmptyState from './components/PeopleUserReviewEmptyState';

type Props = {
  peopleReviewCycle: PeopleReviewCycle;
  shouldRefetchReviews: boolean;
  setShouldRefetchReviews: (boolean) => void;
} & WithDeprecatedPaginationProps;

type AfterPaginateProps = Props & PaginationProps;

type AfterConnectProps = AfterPaginateProps & {
  peopleUserReviewCollection: PaginatedCollection<PeopleUserReview>;
};

type AfterDataloaderProps = DataLoaderProvidedProps & AfterConnectProps;

const PeopleUserReviewListWithPagination = ({
  search,
  page,
  filter,
  countPerPage,
  peopleUserReviewCollection,
  isFetching,
  hasError,
  refetchData,
  onSearchChange,
  onFilterChange,
  previousPageLink,
  nextPageLink,
  getPreviousPage,
  getNextPage,
  shouldRefetchReviews,
  setShouldRefetchReviews,
  peopleReviewCycle,
}: AfterDataloaderProps) => {
  const peopleUserReviews: PeopleUserReview[] = peopleUserReviewCollection
    ? peopleUserReviewCollection.items
    : [];

  const isPageLoaded = !isFetching && !hasError;
  const isCurrentPageEmpty =
    isPageLoaded &&
    peopleUserReviewCollection.page === page &&
    peopleUserReviews.length === 0;
  const shouldFetchPreviousPage = isCurrentPageEmpty && !!page && page > 1;

  // When completing a review, we go back to the review list and the page may now be empty if the groups changed
  React.useEffect(() => {
    // @ts-ignore TSFIXME: Fix strictNullChecks error
    if (shouldFetchPreviousPage) navigate(previousPageLink);
  }, [shouldFetchPreviousPage, previousPageLink]);

  useEffect(() => {
    if (shouldRefetchReviews) {
      refetchData();
      setShouldRefetchReviews(false);
    }
  }, [shouldRefetchReviews, refetchData, setShouldRefetchReviews]);

  return (
    <Box className="people-user-review-list mt-6">
      <DatatableWrapper
        collectionInfo={
          peopleUserReviewCollection
            ? omit(peopleUserReviewCollection, 'items')
            : null
        }
        // @ts-ignore TSFIXME: Fix strictNullChecks error
        totalCountRenderer={(totalCount?: number) => {
          return n__('%1 people review', '%1 people reviews', totalCount || 0);
        }}
        search={search}
        page={page}
        countPerPage={countPerPage}
        previousPageLink={previousPageLink}
        nextPageLink={nextPageLink}
        getPreviousPage={getPreviousPage}
        getNextPage={getNextPage}
        onSearchChange={onSearchChange}
        onFilterChange={onFilterChange}
        filters={[
          { param: 'all', label: __('All') },
          { param: 'to_do', label: __('To complete') },
          { param: 'completed', label: __('Completed') },
          { param: 'validated', label: __('Validated') },
        ]}
        filter={filter}
        searchPlaceholder={__('Search')}
        isFetching={isFetching}
        hasError={hasError}
        renderNoRecord={() => <PeopleUserReviewEmptyState />}
        renderNoResult={() => (
          <ReviewEmptyState
            title={__('No people review matches your search')}
            inBoxList
          />
        )}
      >
        <BoxList>
          <BoxListItem className={'text-legends'}>
            <Columns contentVerticallyAligned>
              <Column size={3}>{__('Employee')}</Column>
              <Column size={3}>{__('Reviewer')}</Column>
              <Column size={3}>{__('Identified as...')}</Column>
              <Column size={2}>{__('Status')}</Column>
              <Column size={1} />
            </Columns>
          </BoxListItem>
          <FetchContainer
            isFetching={isFetching}
            hasError={hasError}
            loadingStyle="overlay"
            render={() => {
              return (
                <div className="people-review-cycle-list">
                  {peopleUserReviews.map(peopleUserReview => (
                    <PeopleUserReviewListItem
                      key={peopleUserReview.id}
                      peopleUserReview={peopleUserReview}
                      refetchData={refetchData}
                      peopleReviewCycleName={peopleReviewCycle.name}
                    />
                  ))}
                </div>
              );
            }}
          />
        </BoxList>
      </DatatableWrapper>
    </Box>
  );
};

export default compose<React.ComponentType<Props>>(
  withDeprecatedPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      search,
      filter,
      peopleReviewCycle,
      query,
      userFilter,
    }: AfterPaginateProps) =>
      get(`/people_review_cycles/${peopleReviewCycle.id}/reviews`, {
        page,
        countPerPage,
        search,
        filter,
        userFilter,
        'people_group_ids[]': query?.peopleGroupIds,
      }),
    hydrate: {
      peopleUserReviewCollection: {
        items: {
          user: {},
          reviewer: {},
          peopleGroups: {},
          abilities: {},
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      search,
      filter,
      peopleReviewCycle,
      userFilter,
      query,
    }: AfterPaginateProps) =>
      compositeKey({
        page,
        countPerPage,
        search,
        filter,
        cycleStatus: peopleReviewCycle.status,
        userFilter,
        peopleGroupIds: query?.peopleGroupIds,
      }),
  })
)(PeopleUserReviewListWithPagination);
