import React, { useState } from 'react';

import type { InteractionType } from 'models';

import { useOrganizationPlan } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { Control, Input, Text, Title5 } from 'components';

import ThreeSixtyFeaturePromptModal from 'scenes/admin/components/ThreeSixtyFeaturePromptModal';
import StepContainer from 'scenes/components/Stepper/StepContainer';

type Props = {
  name: string;
  interactionType: InteractionType | undefined | null;
  goToNextStep: () => void;
  onContinueClick: (params: {
    name: string;
    interactionType: InteractionType | undefined | null;
  }) => void;
};

const Step1Name = ({
  name: initialName,
  interactionType: initialInteractionType,
  onContinueClick,
}: Props) => {
  const { is360Enabled } = useOrganizationPlan();
  const [name, setName] = useState(initialName || '');
  const [interactionType, setInteractionType] = useState<
    InteractionType | undefined | null
  >(initialInteractionType);
  const [newlySetInteractionType, setNewlySetInteractionType] = useState(false);

  const onNameChange = (newName: string) => setName(newName);
  const onInteractionTypeChange = (
    newInteractionType: InteractionType | null | undefined
  ) => {
    setInteractionType(newInteractionType);
    setNewlySetInteractionType(true);
  };

  const handleContinueClick = () => {
    setNewlySetInteractionType(false);
    onContinueClick({ name, interactionType });
  };

  return (
    <StepContainer
      onContinueClick={handleContinueClick}
      canPassStep={!!name}
      stepName="name"
    >
      {!!interactionType && (
        <div>
          <Title5>{__('Campaign name')}</Title5>
          <Text>
            {__(
              'The review campaign name will be visible to all participants. It will be displayed in emails and in review lists.'
            )}
          </Text>

          <Control style={{ marginTop: 8 }}>
            <Input
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={newlySetInteractionType}
              name="review_cycle_name"
              placeholder={__('Performance Review Q2')}
              value={name}
              onChange={value => onNameChange(value || '')}
            />
          </Control>
        </div>
      )}

      {!is360Enabled && (
        <ThreeSixtyFeaturePromptModal
          isActive={interactionType === '360'}
          onClose={() => onInteractionTypeChange(null)}
        />
      )}
    </StepContainer>
  );
};

export default Step1Name;
