import React from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { PaginationProps } from 'lib/dataLoader/pagination/types';
import type { WithDeprecatedPaginationProps } from 'lib/dataLoader/pagination/withDeprecatedPagination';
import type {
  PaginatedCollection,
  TrainingParticipant,
  TrainingSession,
} from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';

import { newDataLoader, withDeprecatedPagination } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  DatatableWrapper,
  EmptyState,
  FetchContainer,
  Icon,
  Level,
  LevelItem,
  LevelLeft,
  LevelRight,
  SearchBar,
  SimpleTable,
  Text,
} from 'components';

import UserAvatar from 'scenes/components/UserAvatar';
import UserFilter from 'scenes/components/UserFilter';

import RequestCell from '../../components/RequestCell';
import NoRecordState from './NoRecordState';
import RowActions from './RowActions';
import TableActions from './TableActions';

type Props = {
  session: TrainingSession;
} & WithDeprecatedPaginationProps;

type AfterPaginateProps = Props & PaginationProps;

type AfterDataLoaderProps = DataLoaderProvidedProps &
  AfterPaginateProps & {
    participantCollection: PaginatedCollection<TrainingParticipant>;
  };

const ParticipantsTable = ({
  session,
  participantCollection,
  isFetching,
  hasError,
  refetchData,
  sort,
  onSortChange,
  search,
  onSearchChange,
  page,
  countPerPage,
  previousPageLink,
  nextPageLink,
  userFilter,
  onUserFilterChange,
}: AfterDataLoaderProps) => {
  return (
    <DatatableWrapper
      isFetching={isFetching}
      hasError={hasError}
      search={search}
      page={page}
      countPerPage={countPerPage}
      onSearchChange={onSearchChange}
      nextPageLink={nextPageLink}
      previousPageLink={previousPageLink}
      collectionInfo={participantCollection}
      userFilter={userFilter}
      onUserFilterChange={onUserFilterChange}
      totalCountRenderer={(count?: number | null) =>
        n__('%1 participant', '%1 participants', count || 0)
      }
      renderNoRecord={() => (
        <NoRecordState session={session} refetchParticipants={refetchData} />
      )}
      renderNoResult={() => (
        <EmptyState title={__('No participants match your search')} />
      )}
      renderHeader={() => (
        <Level isMobile style={{ marginBottom: 16 }}>
          <LevelLeft>
            <LevelItem>
              <SearchBar
                value={search}
                placeholder={__('Search participants')}
                onChange={onSearchChange}
                style={{ width: '220px' }}
              />
            </LevelItem>
            {!!onUserFilterChange && (
              <LevelItem>
                <UserFilter
                  segment={userFilter}
                  onChange={onUserFilterChange}
                />
              </LevelItem>
            )}
          </LevelLeft>
          <LevelRight style={{ textAlign: 'end' }}>
            <TableActions session={session} refetchParticipants={refetchData} />
          </LevelRight>
        </Level>
      )}
    >
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        loadingStyle="overlay"
        render={() => {
          const sessionName = session.name || __('Untitled session');

          return (
            <SimpleTable
              rows={!!participantCollection ? participantCollection.items : []}
              keyFn={participant => participant.id}
              rowClassName="test-training-participant-row"
              columns={[
                {
                  header: __('Participant name'),
                  cell: participant => (
                    <UserAvatar user={participant.user} withJobTitle />
                  ),
                  activeSort: sort && sort['participant_name'],
                  onSort: () =>
                    onSortChange && onSortChange('participant_name'),
                },
                {
                  header: __('Associated training request'),
                  cell: participant => (
                    <RequestCell
                      participant={participant}
                      refetchParticipants={refetchData}
                      sessionName={sessionName}
                    />
                  ),
                },
                {
                  header: __('Calendar invitation'),
                  cell: participant =>
                    participant.meeting && !participant.meeting.canceledAt ? (
                      <span className="flex items-center">
                        <Icon
                          style={{ marginRight: 8, marginBottom: 2 }}
                          name="event"
                        />
                        {__('Sent')}
                      </span>
                    ) : (
                      <Text
                        weight="light"
                        transformation="italic"
                        color="light"
                      >
                        {__('Not invited')}
                      </Text>
                    ),
                },
                {
                  header: '',
                  cell: participant => (
                    <RowActions
                      participant={participant}
                      sessionName={sessionName}
                      onDelete={refetchData}
                    />
                  ),
                  isNarrow: true,
                },
              ]}
              className="mb-2"
            />
          );
        }}
      />
    </DatatableWrapper>
  );
};

export default compose<React.ComponentType<Props>>(
  withDeprecatedPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      search,
      userFilter,
      filter,
      session,
      sort,
    }: AfterPaginateProps) =>
      get(`training/sessions/${session.id}/participants`, {
        page,
        countPerPage,
        search,
        userFilter,
        filter,
        sort,
      }),
    hydrate: {
      participantCollection: {
        items: {
          user: {},
          fundingItems: {
            fundingSource: {},
          },
          meeting: {},
          request: {
            creator: {},
            trainingPeriod: {},
            trainee: {},
            trainingCourse: {
              organism: {},
            },
          },
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      search,
      userFilter,
      filter,
      session,
      sort,
    }: AfterPaginateProps) =>
      compositeKey({
        page,
        countPerPage,
        search,
        userFilter,
        filter,
        sessionId: session.id,
        sort,
      }),
  })
)(ParticipantsTable);
