import React, { useState } from 'react';

import { ReviewCycle, UserFilterSegment } from 'models';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { Flex, Text } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import Filters from './Filters';
import List from './List';
import NoFilterEmptyState from './NoFilterEmptyState';

type Props = {
  reviewCycle: ReviewCycle;
  defaultUserFilter?: UserFilterSegment;
  userFilters?: ActiveFilters;
  withUserMultiFilters?: boolean;
  setUserFilters?: (ActiveFilters) => void;
};

const DevelopmentNeeds = ({
  reviewCycle,
  defaultUserFilter,
  withUserMultiFilters,
  userFilters: userFiltersProp,
  setUserFilters: setUserFiltersProp,
}: Props) => {
  const [userFilter, setUserFilter] = useState(defaultUserFilter);
  const [userFilters, setUserFilters] = useState(userFiltersProp);
  const [selectedMatrixId, setSelectedMatrixId] = useState<null | string>(null);
  const [selectedLevelId, setSelectedLevelId] = useState<null | string>(null);
  const { featureFlags } = useCurrentOrganization();

  const updateUserFilters = (newUserFilters: ActiveFilters) => {
    setUserFilters(newUserFilters);
    setUserFiltersProp && setUserFiltersProp(newUserFilters);
  };

  const displayRealData = featureFlags.includes('skillsAndCareersAnalytics');
  const displayMockedData =
    !displayRealData &&
    featureFlags.includes('mockDataForSkillsAndCareersAnalytics');

  const [totalCount, setTotalCount] = useState<number | null>(null);

  return (
    <React.Fragment>
      <Flex direction="column" className="review-cycle-skills">
        <Text preset="18bs5" className="mb-2">
          {__('Developement needs')}
        </Text>
        <Text className="mb-2">
          {__('Visualize the skills that can be improved in a team.')}
        </Text>
        <Filters
          totalCount={totalCount}
          displayMockedData={displayMockedData}
          selectedMatrixId={selectedMatrixId}
          setSelectedMatrixId={setSelectedMatrixId}
          selectedLevelId={selectedLevelId}
          setSelectedLevelId={setSelectedLevelId}
          // @ts-ignore TSFIXME: Fix strictNullChecks error
          userFilter={withUserMultiFilters ? undefined : userFilter}
          userFilters={userFilters}
          setUserFilters={updateUserFilters}
          withUserMultiFilters={withUserMultiFilters}
          // @ts-ignore TSFIXME: Fix strictNullChecks error
          setUserFilter={withUserMultiFilters ? undefined : setUserFilter}
          reviewCycle={reviewCycle}
        />
        {!selectedMatrixId ? (
          <NoFilterEmptyState />
        ) : (
          <List
            reviewCycle={reviewCycle}
            displayMockedData={displayMockedData}
            selectedMatrixId={selectedMatrixId}
            selectedLevelId={selectedLevelId}
            // @ts-ignore TSFIXME: Fix strictNullChecks error
            userFilter={withUserMultiFilters ? undefined : userFilter}
            userFilters={userFilters as ActiveFilters}
            // @ts-ignore TSFIXME: Fix strictNullChecks error
            totalCount={totalCount}
            setTotalCount={setTotalCount}
          />
        )}
      </Flex>
    </React.Fragment>
  );
};

export default DevelopmentNeeds;
