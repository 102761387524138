import { omit } from 'lodash';
import * as React from 'react';
import { compose } from 'redux';

import { UserCollection } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n/index';

import { withDeprecatedPagination } from 'lib/dataLoader';
import newDataLoader from 'lib/dataLoader/newDataLoader';
import { PaginationProps } from 'lib/dataLoader/pagination/types';
import { WithDeprecatedPaginationProps } from 'lib/dataLoader/pagination/withDeprecatedPagination';
import { get } from 'redux/actions/api';

import { Box, BoxList, DatatableWrapper, EmptyState } from 'components';

import UsersList from './UsersList';

type FetchParams = {
  permission?: string;
};

type Props = {
  fetchPath: string;
  fetchParams?: FetchParams;
} & WithDeprecatedPaginationProps;

type AfterPaginateProps = Props & PaginationProps;

type AfterConnectProps = AfterPaginateProps & {
  userCollection: UserCollection;
  isFetching: boolean;
  hasError: boolean;
};

function OldUserListWithPagination({
  search,
  page,
  countPerPage,
  userCollection,
  isFetching,
  hasError,
  onSearchChange,
  filter,
  onFilterChange,
  previousPageLink,
  nextPageLink,
  getPreviousPage,
  getNextPage,
  userFilter,
  onUserFilterChange,
}: AfterConnectProps) {
  const users = userCollection ? userCollection.users : [];

  return (
    <Box>
      <DatatableWrapper
        collectionInfo={
          userCollection
            ? (omit(userCollection, 'users') as UserCollection)
            : null
        }
        search={search}
        page={page}
        countPerPage={countPerPage}
        previousPageLink={previousPageLink}
        nextPageLink={nextPageLink}
        getPreviousPage={getPreviousPage}
        getNextPage={getNextPage}
        onSearchChange={onSearchChange}
        onFilterChange={onFilterChange}
        userFilter={userFilter}
        onUserFilterChange={onUserFilterChange}
        filters={[
          { param: 'active', label: __('Active') },
          { param: 'suspended', label: __('Suspended') },
        ]}
        filter={filter}
        searchPlaceholder={__('Search users')}
        isFetching={isFetching}
        hasError={hasError}
        renderNoResult={() => (
          <EmptyState title={__('No user matches your search')} inBoxList />
        )}
      >
        <BoxList>
          <UsersList
            users={users}
            isFetching={isFetching}
            hasError={hasError}
            displayActions
          />
        </BoxList>
      </DatatableWrapper>
    </Box>
  );
}

export default compose<React.ComponentType<Props>>(
  withDeprecatedPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      search,
      filter,
      userFilter,
      fetchPath,
      fetchParams,
    }: AfterPaginateProps) =>
      get(fetchPath, {
        page: page,
        countPerPage: countPerPage,
        search: search,
        filter: filter,
        userFilter,
        ...fetchParams,
      }),
    hydrate: {
      userCollection: {
        users: {
          manager: {},
          abilities: {},
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      search,
      filter,
      userFilter,
    }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, search, filter, userFilter }),
  })
)(OldUserListWithPagination);
