import { useEffect, useState } from 'react';

import type { AppDispatch } from 'redux/actions';

import { AsyncAction } from 'models';

import { Resource } from 'helpers/data/types/NormalizedJsonApiResponse';
import { useAppDispatch } from 'helpers/hooks';
import { usePollingWithAsyncAction } from 'helpers/hooks/usePollingWithAsyncAction';

import { hydrateFromResponse } from 'lib/dataLoader';
import { post } from 'redux/actions/api';
import { ReduxStore } from 'redux/reducers';

/**
 * isFetching && !isReady: Checking if embeddings are ready, it is unknown yet
 * !isFetching && !isReady: Embeddings are being computed
 * !isFetching && isReady: Embeddings are ready
 */
const useEmbeddingsReady = () => {
  const [asyncAction, setAsyncAction] = useState<AsyncAction | null>(null);
  const [isFetching, setIsFetching] = useState(true);
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(async (dispatch: AppDispatch, getState: () => ReduxStore) => {
      const { response } = await dispatch(post(`embeddings/sync_outdated`));

      if (response.body?.data) {
        const asyncAction = hydrateFromResponse(
          getState().data,
          response.body,
          {
            asyncAction: {},
          },
          response.body.data.id
        ) as Resource & AsyncAction;

        setAsyncAction(asyncAction);
      }
      setIsFetching(false);
    });
  }, [dispatch]);

  const { pollerComponent } = usePollingWithAsyncAction(asyncAction, async () =>
    setAsyncAction(null)
  );

  return {
    isFetching,
    isReady: !isFetching && !asyncAction,
    pollerComponent,
  };
};

export default useEmbeddingsReady;
