import { sortBy } from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import type {
  CreatableReviewBlockType,
  ReviewBlock,
  ReviewBlockType,
  ReviewStructure,
} from 'models';
import type { AppDispatch } from 'redux/actions/types';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import { del, post, put } from 'redux/actions/api';

import {
  Centered,
  DragAndDropContainer,
  DragAndDropItem,
  FadeinFadeoutList,
  FadeinFadeoutListItem,
  Testable,
  WithSavingStatusRecorder,
} from 'components';

import HeaderMessage from './HeaderMessage';
import ReviewBlockComponent from './ReviewBlock';
import ReviewBlockCreator from './ReviewBlockCreator';

type Props = {
  templateId: string;
  structure: ReviewStructure;
  hasSelfEvaluationEnabled: boolean;
  canDeleteBlocks?: boolean;
};

type AfterConnectProps = Props & {
  updateHeaderMessage: (headerMessage: string) => Promise<any>;
  createBlock: (
    blockType: CreatableReviewBlockType,
    position: number
  ) => Promise<any>;
  deleteBlock: (blockType: ReviewBlockType, blockId: string) => Promise<void>;
  updateBlockPosition: (blockId: string, newPosition: number) => Promise<any>;
  duplicateBlock: (blockId: string) => Promise<any>;
};

const moveElementTo = (list, element, newIndex) => {
  const newList = [...list];
  const currentIndex = newList.indexOf(element);
  if (currentIndex !== -1) {
    const [removedItem] = newList.splice(currentIndex, 1);
    newList.splice(newIndex, 0, removedItem);
  }
  return newList;
};

function Structure({
  structure,
  updateHeaderMessage,
  hasSelfEvaluationEnabled,
  canDeleteBlocks = true,
  createBlock,
  deleteBlock,
  updateBlockPosition,
  duplicateBlock,
}: AfterConnectProps) {
  const [lastCreatedPosition, setLastCreatedPosition] = useState<
    number | string
  >('');
  const [orderedBlockIds, setOrderedBlockIds] = useState(
    sortBy(structure.reviewBlocks, 'position').map(block => block.id)
  );

  useEffect(() => {
    setOrderedBlockIds(
      sortBy(structure.reviewBlocks, 'position').map(block => block.id)
    );
  }, [structure.reviewBlocks]);

  const onCreateBlock = (
    blockType: CreatableReviewBlockType,
    position: number
  ) => {
    setLastCreatedPosition(position);
    return createBlock(blockType, position);
  };

  const onDeleteBlock = (blockType: ReviewBlockType, blockId: string) => {
    // @ts-ignore TSFIXME: Fix strictNullChecks error
    setLastCreatedPosition(null);
    return deleteBlock(blockType, blockId);
  };

  const onDuplicateBlock = (block: ReviewBlock) => {
    invariant(
      [
        'question',
        'textQuestion',
        'dropdownQuestion',
        'multipleScaleQuestion',
        'instructions',
      ].includes(block.blockType),
      'Block should be of type question or multiple scale'
    );
    setLastCreatedPosition(block.position + 1);
    return duplicateBlock(block.id);
  };

  const onBlockPositionChange = (blockId: string, newPosition: number) => {
    setOrderedBlockIds(moveElementTo(orderedBlockIds, blockId, newPosition));
    return updateBlockPosition(blockId, newPosition);
  };

  const numberOfBlocks = structure.reviewBlocks.length;
  const signatureBlock = structure.reviewBlocks.find(
    block => block.blockType === 'signature'
  );

  return (
    <Testable name="test-structure-display">
      <WithSavingStatusRecorder
        fieldUid={compositeKey({
          structureId: structure.id,
          field: 'header_message',
        })}
        onChange={(headerMessage: string) => updateHeaderMessage(headerMessage)}
        render={autoSavingOnChange => (
          <HeaderMessage
            headerMessage={structure.headerMessage}
            onChange={newValue => autoSavingOnChange(newValue)}
            withRichText={structure.richTextEnabled}
          />
        )}
      />

      <DragAndDropContainer onChangePosition={onBlockPositionChange}>
        <FadeinFadeoutList key={structure.id}>
          {structure.reviewBlocks
            .sort(
              (b1, b2) =>
                orderedBlockIds.indexOf(b1.id) - orderedBlockIds.indexOf(b2.id)
            )
            .map(block => {
              if (block.blockType === 'signature') return undefined;

              return (
                <FadeinFadeoutListItem key={block.id}>
                  <ReviewBlockCreator
                    withHoverableFadeInFadeOut
                    onCreate={blockType =>
                      onCreateBlock(blockType, block.position)
                    }
                  />
                  <DragAndDropItem
                    key={block.id}
                    itemId={block.id}
                    position={block.position}
                    handlePosition="top"
                    alwaysDisplayHandle
                    className="review-template-block-drag-item"
                  >
                    <ReviewBlockComponent
                      block={block}
                      onDelete={
                        canDeleteBlocks
                          ? () => onDeleteBlock(block.blockType, block.id)
                          : undefined
                      }
                      justEnteredInList={lastCreatedPosition === block.position}
                      hasSelfEvaluationEnabled={hasSelfEvaluationEnabled}
                      interactionType={structure.interactionType}
                      updatePosition={onBlockPositionChange}
                      onDuplicate={() => onDuplicateBlock(block)}
                    />
                  </DragAndDropItem>
                </FadeinFadeoutListItem>
              );
            })}
        </FadeinFadeoutList>
      </DragAndDropContainer>

      {!!signatureBlock && (
        <React.Fragment>
          <ReviewBlockCreator
            withHoverableFadeInFadeOut
            onCreate={blockType =>
              onCreateBlock(blockType, signatureBlock.position)
            }
          />
          <ReviewBlockComponent
            block={signatureBlock}
            onDelete={undefined}
            justEnteredInList={lastCreatedPosition === signatureBlock.position}
            hasSelfEvaluationEnabled={hasSelfEvaluationEnabled}
            interactionType={structure.interactionType}
            updatePosition={onBlockPositionChange}
            onDuplicate={() => onDuplicateBlock(signatureBlock)}
          />
        </React.Fragment>
      )}

      {structure.canAddBlockAtTheEnd && (
        <Centered style={{ marginTop: 20 }}>
          <Testable name="test-bottom-review-block-creator">
            <ReviewBlockCreator
              onCreate={blockType => onCreateBlock(blockType, numberOfBlocks)}
            />
          </Testable>
        </Centered>
      )}
    </Testable>
  );
}

const mapDispatchToProps = (
  dispatch: AppDispatch,
  { templateId, structure }: Props
) => ({
  updateHeaderMessage: async (headerMessage: string) =>
    dispatch(
      put(
        `review_structures/${structure.id}`,
        {
          reviewStructure: {
            headerMessage,
          },
        },
        {
          errorMessage: __('The header message could not be updated.'),
        }
      )
    ),
  createBlock: (blockType: CreatableReviewBlockType, position: number) =>
    dispatch(
      post(
        'review_blocks',
        {
          reviewBlock: {
            reviewStructureId: structure.id,
            blockType,
            position,
          },
        },
        {
          errorMessage: __('The block %1 could not be created.', blockType),
        }
      )
    ),
  deleteBlock: async (blockType: ReviewBlockType, blockId: string) => {
    switch (blockType) {
      case 'reviewedObjectivesModule':
        dispatch(
          put(
            `review_templates/${templateId}`,
            { reviewTemplate: { objectivesReviewEnabled: false } },
            {
              errorMessage: __('The template could not be updated.'),
            }
          )
        );
        break;
      case 'nextObjectivesModule':
        dispatch(
          put(
            `review_templates/${templateId}`,
            { reviewTemplate: { objectivesDefinitionEnabled: false } },
            {
              errorMessage: __('The template could not be updated.'),
            }
          )
        );
        break;
      case 'skillsCareerLevel':
        dispatch(
          put(
            `review_templates/${templateId}`,
            { reviewTemplate: { skillsCareerLevelEnabled: false } },
            {
              errorMessage: __('The template could not be updated.'),
            }
          )
        );
        break;
      case 'title':
      case 'instructions':
      case 'question':
      case 'textQuestion':
      case 'dropdownQuestion':
      case 'multipleScaleQuestion':
        dispatch(
          del(
            `review_blocks/${blockId}`,
            {},
            {
              errorMessage: __('The element could not be deleted.'),
            }
          )
        );
        break;
      case 'trainingRequestHistory':
        dispatch(
          put(
            `review_templates/${templateId}`,
            { reviewTemplate: { trainingRequestHistoryEnabled: false } },
            {
              errorMessage: __('The template could not be updated.'),
            }
          )
        );
        break;
      default:
        throw new Error(`Unrecognized blockType: ${blockType}`);
    }
  },
  updateBlockPosition: (blockId: string, new_position: number) =>
    dispatch(
      put(`review_blocks/${blockId}/position`, {
        position: new_position,
      })
    ),
  duplicateBlock: (blockId: string) =>
    dispatch(post(`review_blocks/${blockId}/duplicate`)),
});

export default connect(
  null,
  // @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
  mapDispatchToProps
)(Structure) as React.ComponentType<Props>;
