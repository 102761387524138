import React, { Fragment } from 'react';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';

import { UserFilterSegment } from 'models/UserFilterSegment';

import compositeKey from 'helpers/compositeKey';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Box, BoxVerticalSeparator, FetchContainer } from 'components';
import { ActiveFilters } from 'components/Filters/types';

import AuditStatByStatus from './AuditStatByStatus';

type Stats = {
  late: number;
  nearingDeadline: number;
  upToDate: number;
  total: number;
};

type Props = {
  auditReportId: string;
  userFilter: UserFilterSegment;
  userFilters: ActiveFilters;
  lastUpdate: string;
};

type AfterDataloaderProps = DataLoaderProvidedProps &
  Props & {
    auditReportStats: Stats;
  };

const AuditStats = ({
  auditReportStats,
  isFetching,
  hasError,
}: AfterDataloaderProps) => {
  const stats = auditReportStats;
  return (
    <Box className="flex max-w-[75%] px-2 py-5 grow">
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => (
          <Fragment>
            <AuditStatByStatus
              value={stats.late || 0}
              name="late"
              total={stats.total}
            />

            <BoxVerticalSeparator className="mx-3" />

            <AuditStatByStatus
              value={stats.nearingDeadline || 0}
              name="nearingDeadline"
              total={stats.total}
            />

            <BoxVerticalSeparator className="mx-3" />

            <AuditStatByStatus
              value={stats.upToDate || 0}
              name="upToDate"
              total={stats.total}
            />
          </Fragment>
        )}
      />
    </Box>
  );
};

export default newDataLoader({
  // TODO: multifilters: remove userFilter when FF is enabled for everyone
  fetch: ({ auditReportId, userFilter, userFilters }) =>
    get(`audit_reports/${auditReportId}/stats`, {
      userFilter,
      userFilters,
    }),
  hydrate: {
    auditReportStats: {},
  },
  cacheKey: ({ auditReportId, userFilter, lastUpdate, userFilters }) =>
    compositeKey({
      auditReportId,
      userFilter,
      lastUpdate,
      userFilters,
    }),
})(AuditStats);
