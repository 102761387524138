import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';

import type { AppDispatch } from 'redux/actions/types';

import {
  resetFieldStatus,
  setFieldFailed,
  setFieldSaved,
  setFieldSaving,
} from 'redux/actions/autoSave';

import ComponentUIDProvider from 'components/ComponentUIDProvider';

type Props<TChangeEventArgs extends unknown[]> = {
  fieldUid?: string;
  render: (
    autoSavingOnChange: (...args: TChangeEventArgs) => Promise<void>,
    setSaving: () => void
  ) => ReactNode;
  onChange: (...args: TChangeEventArgs) => Promise<void>;
  unprocessableEntityHandled?: boolean;
};

type WithUIDProps<TChangeEventArgs extends unknown[]> =
  Props<TChangeEventArgs> & {
    fieldUid: string;
  };

type AfterConnectProps<TChangeEventArgs extends unknown[]> =
  WithUIDProps<TChangeEventArgs> & {
    onSaving: () => void;
    onPersistSuccess: () => void;
    onPersistFailure: () => void;
    resetFieldStatus: () => void;
  };

type State = {
  isSaving: boolean;
};

class _Recorder<TChangeEventArgs extends unknown[]> extends Component<
  AfterConnectProps<TChangeEventArgs>,
  State
> {
  state = {
    isSaving: false,
  };

  componentWillUnmount() {
    if (this.state.isSaving) {
      this.props.resetFieldStatus();
    }
  }

  onChange = async (...args: TChangeEventArgs) => {
    this.setState({
      isSaving: true,
    });
    this.props.onSaving();

    try {
      await this.props.onChange.apply(this.props, args);
      await this.props.onPersistSuccess();
    } catch (e: any) {
      await this.props.onPersistFailure();
      if (!(this.props.unprocessableEntityHandled && e.status === 422)) throw e;
    } finally {
      this.setState({
        isSaving: false,
      });
    }
  };

  render() {
    return this.props.render(this.onChange, this.props.onSaving);
  }
}

function mapDispatchToProps<TChangeEventArgs extends unknown[]>(
  dispatch: AppDispatch,
  ownProps: WithUIDProps<TChangeEventArgs>
) {
  const { fieldUid } = ownProps;
  return {
    onSaving: () => dispatch(setFieldSaving(fieldUid)),
    onPersistSuccess: () => dispatch(setFieldSaved(fieldUid)),
    onPersistFailure: () => dispatch(setFieldFailed(fieldUid)),
    resetFieldStatus: () => dispatch(resetFieldStatus(fieldUid)),
  };
}

// @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
const ConnectedRecorder = connect(null, mapDispatchToProps)(_Recorder);

export default function WithSavingStatusRecorder<
  TChangeEventArgs extends unknown[]
>(props: Props<TChangeEventArgs>) {
  const Recorder = ConnectedRecorder as unknown as React.ComponentType<
    Props<TChangeEventArgs>
  >;
  const { fieldUid, ...otherProps } = props;

  if (!!fieldUid) {
    return <Recorder fieldUid={fieldUid} {...otherProps} />;
  } else {
    return (
      <ComponentUIDProvider
        render={uid => <Recorder fieldUid={uid} {...otherProps} />}
      />
    );
  }
}
