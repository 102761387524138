import React, { Fragment } from 'react';

import type { TrainingRequest } from 'models';

import { __ } from 'helpers/i18n';
import confirmAsync from 'helpers/react/confirmAsync';

import { Icon, Text } from 'components';

const deleteRequestWithConfirmation = (
  request: TrainingRequest,
  onDelete: (request: TrainingRequest) => void
) =>
  confirmAsync(
    __('Confirm deletion'),
    <Fragment>
      <p>
        {__(
          "This action will remove the training request from %1's profile. The employee will be informed by email that the training request has been removed.",
          request.trainee.fullName
        )}
      </p>
      <p>{__('Are you sure you want to remove this training request ?')}</p>
      <div className="mt-5 flex items-center">
        <Icon color="danger" className="mr-3" name="warning" />
        <div className="flex">
          <Text color="danger">{__('This action cannot be undone')}</Text>
        </div>
      </div>
    </Fragment>,
    {
      confirmLabel: __('Remove request'),
      isDanger: true,
      onConfirm: async () => onDelete(request),
    }
  );

export default deleteRequestWithConfirmation;
