import React, { ComponentProps, Fragment } from 'react';

import { SkillsAreaWithSuggestions, SkillsMatrixWithSuggestions } from 'models';

import { __ } from 'helpers/i18n';

import { Button } from 'components';

import AccordionStructure from '../AccordionStructure';
import SuggestionsAccordionAreaTitle from './AreaTitle';
import SuggestionsAccordionCourses from './Courses';
import SuggestionsAccordionEmptyState from './EmptyState';

export type AreaId = string;
export type CourseId = string;
export type SelectedCourses = Record<AreaId, Record<CourseId, boolean>>;

type Props = {
  matrix: SkillsMatrixWithSuggestions;
  design?: ComponentProps<typeof AccordionStructure>['design'];
  selectedCourses?: SelectedCourses;
  onCourseToggle?: (areaId: string, courseId: string) => void;
  onCourseDeleteClick?: (areaId: string, courseId: string) => void;
  onCourseAddClick?: (areaId: string) => void;
  showAutoSuggestions?: boolean;
};

const SuggestionsAccordion = ({
  matrix,
  design,
  selectedCourses,
  onCourseToggle,
  onCourseDeleteClick,
  onCourseAddClick,
  showAutoSuggestions,
}: Props) => {
  const newAutoTrainingCourses = (area: SkillsAreaWithSuggestions) => {
    return (
      area.autoTrainingCourses?.filter(
        autoCourse =>
          !area.trainingCourses.some(course => autoCourse.id === course.id)
      ) || []
    );
  };

  const hasTrainingCourses = (area: SkillsAreaWithSuggestions) =>
    area.trainingCourses.length ||
    (showAutoSuggestions && area.autoTrainingCourses?.length);

  const getAddCourseButton = (area: SkillsAreaWithSuggestions) => {
    if (!onCourseAddClick) return null;

    return (
      <Button
        color="secondary"
        size="small"
        className="ml-[var(--accordion-pad-left)] my-2"
        onClick={() => onCourseAddClick(area.id)}
      >
        {__('Add training courses')}
      </Button>
    );
  };

  const getCoursesCount = (area: SkillsAreaWithSuggestions) => {
    if (selectedCourses?.[area.id]) {
      return Object.keys(selectedCourses[area.id]).filter(
        courseId => selectedCourses[area.id][courseId]
      ).length;
    } else {
      let count = area.trainingCourses.length;
      if (showAutoSuggestions) count += newAutoTrainingCourses(area).length;
      return count;
    }
  };

  return (
    <AccordionStructure
      defaultState="open"
      design={design}
      items={matrix.areas.map(area => ({
        key: area.id,
        title: (
          <SuggestionsAccordionAreaTitle
            title={area.title}
            count={getCoursesCount(area)}
          />
        ),
        content: hasTrainingCourses(area) ? (
          <Fragment>
            <SuggestionsAccordionCourses
              existingCourses={area.trainingCourses}
              newAutoCourses={
                showAutoSuggestions ? newAutoTrainingCourses(area) : []
              }
              selectedCourses={selectedCourses?.[area.id]}
              onCheckboxClick={
                onCourseToggle
                  ? courseId => onCourseToggle(area.id, courseId)
                  : undefined
              }
              onCourseDeleteClick={
                onCourseDeleteClick
                  ? (courseId: string) => onCourseDeleteClick(area.id, courseId)
                  : undefined
              }
            />
            {getAddCourseButton(area)}
          </Fragment>
        ) : (
          <Fragment>
            <SuggestionsAccordionEmptyState />
            {getAddCourseButton(area)}
          </Fragment>
        ),
      }))}
    />
  );
};

export default SuggestionsAccordion;
