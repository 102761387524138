import React from 'react';

import { __, n__ } from 'helpers/i18n';

import {
  Box,
  BoxVerticalSeparator,
  Column,
  Columns,
  FetchContainer,
  Placeholder,
} from 'components';

import StatsGridItem from 'scenes/components/StatsGridItem';

type Props = {
  plannedActionsStatsData: PlannedActionsStatsData;
  isFetching: boolean;
  hasError: boolean;
};

type PlannedActionsStatsData = {
  soonOverdueCount: number;
  overdueCount: number;
  totalCount: number;
};

const PlannedActionsStats = ({
  plannedActionsStatsData,
  isFetching,
  hasError,
}: Props) => (
  <FetchContainer
    isFetching={isFetching}
    hasError={hasError}
    renderFetching={() => (
      <Box className="mb-4" style={{ textAlign: 'center', padding: '20px' }}>
        <Columns className="stats-grid gap-5">
          <Column>
            <Columns className="mb-0 mt-0">
              <Column size={6}>
                <Placeholder width={160} />
              </Column>
              <Column size={6}>
                <Placeholder width={160} />
              </Column>
            </Columns>
          </Column>
        </Columns>
      </Box>
    )}
    render={() => {
      const { soonOverdueCount, overdueCount, totalCount } =
        plannedActionsStatsData;
      return (
        <Box className="mb-4 py-5 px-2 w-[50%] flex">
          <StatsGridItem
            backgroundColorClass="bg-yellow-400"
            mainNumber={soonOverdueCount}
            title={__('Due soon')}
            tooltipTitle={n__(
              '<b>%1 action due soon</b></br>This kind of action is due within the next 7 days.',
              '<b>%1 actions due soon</b></br>This kind of action is due within the next 7 days.',
              soonOverdueCount
            )}
            description={__(
              'i.e. <b>%1%</b> of the actions',
              totalCount && totalCount > 0
                ? Math.round((soonOverdueCount * 100) / totalCount)
                : 0
            )}
          />
          <BoxVerticalSeparator className="mx-3" />
          <StatsGridItem
            backgroundColorClass="bg-red-400"
            mainNumber={overdueCount}
            title={__('Overdue')}
            tooltipTitle={n__(
              '<b>%1 action overdue</b></br>This kind of action is not yet completed and its due date has passed.',
              '<b>%1 actions overdue</b></br>This kind of action is not yet completed and its due date has passed.',
              overdueCount
            )}
            description={__(
              'i.e. <b>%1%</b> of the actions',
              totalCount && totalCount > 0
                ? Math.round((overdueCount * 100) / totalCount)
                : 0
            )}
          />
        </Box>
      );
    }}
  />
);

export default PlannedActionsStats;
