import React, { ComponentType } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { ActionProps } from './types';
import type { User } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { __ } from 'helpers/i18n';
import { viewerRole } from 'helpers/models/userReview';
import { trackAction } from 'helpers/tracking';
import { withActiveUser } from 'helpers/withSessionProps';

import { updateUserReviewStatus } from 'redux/actions/resources';

import { MenuItem } from 'components';

type Props = ActionProps & {
  target: 'reviewee' | 'reviewer' | 'reviewers';
  onAfterAction?: () => any;
};

type AfterWithActiveUserProps = Props & {
  activeUser: User;
};

type AfterConnectProps = AfterWithActiveUserProps & {
  shareEvaluation: () => Promise<void>;
};

const getActionPerformText = (
  target: Props['target'],
  fullName: string
): string => {
  switch (target) {
    case 'reviewee':
      return __("Share %1's comments on their behalf", fullName);
    case 'reviewer':
      return __('Share reviewer comments');
    case 'reviewers':
      return __('Share reviewers comments');
  }
};

function ShareEvaluation({
  userReview,
  target,
  shareEvaluation,
}: AfterConnectProps) {
  return (
    <MenuItem onClick={shareEvaluation}>
      {getActionPerformText(target, userReview.user.fullName)}
    </MenuItem>
  );
}
function mapDispatchToProps(
  dispatch: AppDispatch,
  { activeUser, userReview, target, onAfterAction }: AfterWithActiveUserProps
) {
  return {
    shareEvaluation: async () => {
      trackAction('Evaluation forced shared', {
        reviewerRole: target,
        activeUserRole: viewerRole(activeUser, userReview),
      });
      await dispatch(
        updateUserReviewStatus(userReview, {
          role: target,
          status: 'shared',
        })
      );
      if (onAfterAction) {
        await Promise.resolve(onAfterAction());
      }
    },
  };
}

export default compose<ComponentType<Props>>(
  withActiveUser,
  // @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
  connect(null, mapDispatchToProps)
)(ShareEvaluation);
