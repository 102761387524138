import React, { Fragment, useEffect } from 'react';
import { compose } from 'redux';

import { BaseTrainingCourse, PaginatedCollection } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import { pathToTrainingsCatalogCourse } from 'helpers/paths';

import {
  DataLoaderProvidedProps,
  PaginationProps,
  newDataLoader,
} from 'lib/dataLoader';
import withStatePagination from 'lib/dataLoader/pagination/StatePaginationFactory';
import { get } from 'redux/actions/api';

import { EmptyStateWithIcon, InfiniteFetchContainer, Tabs } from 'components';
import GridCardLayout from 'components/layout/GridCardLayout';

import CourseCard from './CourseCard';

type Props = {
  search?: string;
  selectedTab: string;
  onTabChange: (tab: 'suggestions' | 'trainings') => void;
};

type AfterPaginateProps = Props & PaginationProps;
type AfterDataLoaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    trainingCoursesCollection: PaginatedCollection<BaseTrainingCourse>;
  };

const CoursesList = ({
  trainingCoursesCollection,
  getNextPage,
  isFetching,
  hasError,
  search,
  selectedTab,
  onTabChange,
}: AfterDataLoaderProps) => {
  const emptyStateDescription = __(
    'The training catalog is currently empty. In the meantime, you can submit an off-catalog request for a specific training.'
  );

  const tabs = [
    { key: 'suggestions' as const, label: __('Recommended for you') },
    { key: 'trainings' as const, label: __('All training courses') },
  ];

  const [showTabs, setShowTabs] = React.useState<boolean | null>(null);

  // Show suggestions tab if there are suggested training courses
  useEffect(() => {
    if (isFetching || showTabs !== null || selectedTab === 'trainings') return;

    if (trainingCoursesCollection?.items?.length === 0) {
      setShowTabs(false);
      onTabChange('trainings');
    } else setShowTabs(true);
  }, [
    trainingCoursesCollection?.items?.length,
    isFetching,
    showTabs,
    onTabChange,
    selectedTab,
  ]);

  return (
    <Fragment>
      {showTabs && (
        <Tabs
          items={tabs.map(tab => ({
            label: tab.label,
            onClick: () => onTabChange(tab.key),
            isActive: selectedTab === tab.key,
          }))}
          className="mt-2 mb-0"
        />
      )}
      <div className="mt-6">
        <InfiniteFetchContainer
          collection={trainingCoursesCollection}
          getNextPage={getNextPage}
          isFetching={isFetching}
          hasError={hasError}
          emptyState={
            search ? (
              <EmptyStateWithIcon
                iconName="search"
                title={__('No trainings match your search.')}
              />
            ) : (
              <EmptyStateWithIcon
                iconName="school"
                title={__('Training catalog')}
                description={emptyStateDescription}
              />
            )
          }
          render={items => (
            <GridCardLayout minCardWidth={260}>
              {items.map(item => (
                <CourseCard
                  name={item.name}
                  costPerParticipantCents={item.costPerParticipantCents}
                  costPerParticipantCurrency={item.costPerParticipantCurrency}
                  durationInHours={item.durationInHours}
                  organism={item.organism}
                  url={pathToTrainingsCatalogCourse(item.id)}
                />
              ))}
            </GridCardLayout>
          )}
        />
      </div>
    </Fragment>
  );
};

export default compose<React.ComponentType<Props>>(
  withStatePagination({
    resetPageFor: ({ search, selectedTab }: Props) => [search, selectedTab],
  }),
  newDataLoader({
    fetch: ({ page, countPerPage, search, selectedTab }: AfterPaginateProps) =>
      get(`training/courses/`, {
        page,
        countPerPage,
        search,
        filter: { published: true, suggested: selectedTab === 'suggestions' },
      }),
    hydrate: {
      trainingCoursesCollection: {
        items: {
          organism: {},
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      search,
      selectedTab,
    }: AfterPaginateProps) =>
      compositeKey({
        type: 'trainingCourses',
        page,
        countPerPage,
        search,
        suggested: selectedTab,
      }),
  })
)(CoursesList);
