import classNames from 'classnames';
import React from 'react';

import type { ReactNode } from 'react';

import { hasShape, stringType } from 'types/predicates/WithShape';

import { Icon, Notification, StrictlySanitizedHtml, Text } from 'components';

type Props =
  | {
      text: string;
      className?: string;
    }
  | {
      children: ReactNode;
      className?: string;
    };

const BlueInfoBox = (props: Props) => {
  const { className } = props;

  return (
    <Notification className={classNames('bg-blue-200', className)}>
      <Text className="mr-2 text-blue-500">
        <Icon name="info" />
      </Text>
      <Text preset="13s7">
        {hasShape(props, { text: stringType }) ? (
          <StrictlySanitizedHtml html={props.text} />
        ) : (
          props.children
        )}
      </Text>
    </Notification>
  );
};

export default BlueInfoBox;
