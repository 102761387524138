import React, { Fragment, useState } from 'react';
import { useHistory } from 'react-router';

import type { UserFilterChangeMethod } from 'lib/dataLoader/pagination/types';

import { SurveyCampaign, UserFilterSegment } from 'models';

import { useCurrentOrganization } from 'helpers/hooks';
import invariant from 'helpers/invariant';
import queryString from 'helpers/queryString';

import Header from '../components/Header';
import FormStats from './FormStats';
import StatsBox from './StatsBox';
import NoPeriodEmptyStateStats from './StatsBox/NoPeriodEmptyState';

type Props = {
  surveyCampaign: SurveyCampaign;
  periodId: string | null;
  userFilter: UserFilterSegment | null;
  onUserFilterChange: UserFilterChangeMethod;
};

const Overview = ({
  surveyCampaign,
  periodId,
  userFilter,
  onUserFilterChange,
}: Props) => {
  const { featureFlags } = useCurrentOrganization();
  const surveyFilteringDisabled = featureFlags.includes(
    'surveyFilteringDisabled'
  );
  const segment = surveyFilteringDisabled ? null : userFilter;
  const history = useHistory();
  const { lastPeriodId, anonymous } = surveyCampaign;
  const [selectedPeriodId, setSelectedPeriodId] = useState(
    periodId?.toString() || lastPeriodId?.toString()
  );

  if (!lastPeriodId)
    return <NoPeriodEmptyStateStats campaign={surveyCampaign} />;

  const selectedPeriod = surveyCampaign.pollingPeriods.find(
    period => period.id === selectedPeriodId
  );
  const handlePeriodIdChange = (periodId: string) => {
    setSelectedPeriodId(periodId);
    const params = {
      ...queryString.parse(window.location.search),
      periodId: periodId,
    };
    history.replace(
      history.location.pathname + '?' + queryString.stringify(params)
    );
  };
  invariant(
    selectedPeriod,
    'Selected period must exist in campaign polling periods.'
  );

  return (
    <Fragment>
      <Header
        campaign={surveyCampaign}
        selectedPeriod={selectedPeriod}
        handleSelectedPeriodChange={handlePeriodIdChange}
        userFilter={segment}
        onUserFilterChange={onUserFilterChange}
      />
      <StatsBox
        campaignId={surveyCampaign.id}
        isAnonymous={anonymous}
        periodId={selectedPeriod.id}
        segment={segment}
      />
      <FormStats
        formId={selectedPeriod.formId}
        periodId={selectedPeriod.id}
        campaignId={surveyCampaign.id}
        segment={segment}
      />
    </Fragment>
  );
};

export default Overview;
