import React, { Fragment, createContext, useContext, useState } from 'react';
import Sticky from 'react-stickynode';

import type { ReviewCycle, ReviewTemplate, UserFilterSegment } from 'models';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';

import {
  Box,
  DesignSystem,
  Divider,
  Flex,
  Level,
  LevelLeft,
  LevelRight,
  PageTitle,
  RawHtml,
  Text,
} from 'components';
import { ActiveFilters } from 'components/Filters/types';

import ReviewTemplatePicker from 'scenes/components/ReviewTemplatePicker';
import UserFilter from 'scenes/components/UserFilter';
import UserMultiFilters from 'scenes/components/UserMultiFilters';

import { DataContext } from '../../Routes';
import FilterText from './FilterText';
import TemplateResults from './TemplateResults';

type Props = {
  reviewCycle: ReviewCycle;
  userFilters?: ActiveFilters;
  defaultUserFilter?: UserFilterSegment;
  withUserMultiFilters?: boolean;
};

type UserFiltersContextType = {
  userFilters: ActiveFilters | '';
};

export const UserFiltersContext = createContext<UserFiltersContextType>(
  {} as UserFiltersContextType
);

const ReviewCycleResults = ({
  reviewCycle,
  defaultUserFilter,
  userFilters: userFiltersProp,
  withUserMultiFilters: withUserMultiFiltersProp,
}: Props) => {
  const { featureFlags } = useCurrentOrganization();
  const withUserMultiFilters =
    useContext(DataContext).withUserMultiFilters || withUserMultiFiltersProp;

  const [template, setTemplate] = useState<ReviewTemplate>(
    reviewCycle.defaultTemplate
  );
  const [userFilter, setUserFilter] = useState<
    UserFilterSegment | undefined | null
  >(defaultUserFilter);

  const [userFilters, setUserFilters] = useState<ActiveFilters | ''>(
    userFiltersProp || ''
  );

  if (featureFlags.includes('fakeResultView')) {
    return (
      <div style={{ textAlign: 'center' }}>
        <PageTitle title={[reviewCycle.name, __('Results')]} />

        <RawHtml // FIXME: RawHtml should not be used, this is a legacy case so we let it
          componentType="div"
          html={process.env.REACT_APP_RESULT_VIEW_HTML}
        />
      </div>
    );
  }

  const { templates } = reviewCycle;

  invariant(templates, 'Templates must be passed here');

  return (
    <DesignSystem version={2}>
      <UserFiltersContext.Provider value={{ userFilters }}>
        <div className="review-cycle-results">
          <Fragment>
            <Sticky enabled={true} top={0} innerZ={100}>
              <div className="results-filters">
                <Box>
                  {templates.length > 1 && (
                    <Fragment>
                      <Flex
                        style={{ justifyContent: 'flex-end' }}
                        verticalAlign
                      >
                        <Text preset="14s6">
                          {__('Template of questions:')}
                          &nbsp;
                        </Text>

                        <div style={{ width: 405 }}>
                          <ReviewTemplatePicker
                            reviewTemplate={template}
                            reviewTemplates={templates}
                            onChange={template =>
                              !!template && setTemplate(template)
                            }
                            placeholder={__('Default template')}
                          />
                        </div>
                      </Flex>
                      <Divider style={{ margin: '16px 0' }} />
                    </Fragment>
                  )}

                  <Level>
                    <LevelLeft className="filter-text">
                      <FilterText
                        reviewCycleId={reviewCycle.id}
                        userFilter={userFilter}
                        userFilters={userFilters}
                        templateId={template.id}
                      />
                    </LevelLeft>
                    <LevelRight>
                      {withUserMultiFilters ? (
                        <UserMultiFilters
                          onChange={({ userFilters }) =>
                            setUserFilters(userFilters)
                          }
                          userFilters={userFilters}
                          maxFiltersCount={5}
                        />
                      ) : (
                        <UserFilter
                          segment={userFilter}
                          onChange={setUserFilter}
                        />
                      )}
                    </LevelRight>
                  </Level>
                </Box>
              </div>
            </Sticky>

            <div className="review-content" style={{ height: 'auto' }}>
              <TemplateResults
                templateId={template.id}
                reviewCycleId={reviewCycle.id}
                userFilter={userFilter}
              />
            </div>
          </Fragment>
        </div>
      </UserFiltersContext.Provider>
    </DesignSystem>
  );
};

export default ReviewCycleResults;
