import React, { ComponentProps } from 'react';

import can from 'helpers/can';
import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import {
  pathToTrainingCourseSuggestions,
  pathToTrainingCourses,
  pathToTrainingOrganisms,
} from 'helpers/paths';

import { PageHeader as DefaultPageHeader, Text } from 'components';

import AIIcon from 'scenes/components/AIIcon';
import AILogo from 'scenes/components/AILogo';

const DisabledSuggestionsReason = () => (
  <div className="max-w-80">
    <p>
      <Text preset="13bs7">
        <i>{__('Save time with %1', <AILogo className="mx-1" />)}</i>
      </Text>
    </p>
    <p>
      <Text preset="13s7">
        {__(
          'Automatically match training courses to skills and recommend them to employees according to their matrices.'
        )}
      </Text>
    </p>
  </div>
);

const PageHeader = () => {
  const organization = useCurrentOrganization();
  const showEnabledSugestionsTab = can({
    perform: 'show_enabled_training_suggestions_management_tab',
    on: organization,
  });
  const showDisabledSugestionsTab = can({
    perform: 'show_disabled_training_suggestions_management_tab',
    on: organization,
  });
  const showSugestionsTab =
    showEnabledSugestionsTab || showDisabledSugestionsTab;

  const tabItems: ComponentProps<typeof DefaultPageHeader>['tabItems'] = [
    {
      label: __('Training courses'),
      to: pathToTrainingCourses(),
    },
    {
      label: __('Training organizations'),
      to: pathToTrainingOrganisms(),
    },
  ];

  if (showSugestionsTab) {
    tabItems.push({
      label: __('Suggestions'),
      svgIcon: <AIIcon />,
      to: pathToTrainingCourseSuggestions(),
      isDisabled: showDisabledSugestionsTab,
      disabledReason: showDisabledSugestionsTab && (
        <DisabledSuggestionsReason />
      ),
    });
  }

  return (
    <DefaultPageHeader title={__('Catalog management')} tabItems={tabItems} />
  );
};

export default PageHeader;
