import React, { Fragment } from 'react';
import { compose } from 'redux';

import type { PaginationProps } from 'lib/dataLoader/pagination/types';
import type { WithDeprecatedPaginationProps } from 'lib/dataLoader/pagination/withDeprecatedPagination';

import {
  PaginatedCollection,
  SurveyAnswer,
  SurveyCampaign,
  SurveyQuestion,
  SurveyQuestionStats,
  UserFilterSegment,
} from 'models';

import compositeKey from 'helpers/compositeKey';
import { useCurrentOrganization } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';

import {
  DataLoaderProvidedProps,
  newDataLoader,
  withDeprecatedPagination,
} from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  BoxList,
  Column,
  Columns,
  DatatableWrapper,
  EmptyState,
  EmptyStateWithIcon,
  FetchContainer,
  FilterBar,
  Flex,
  Icon,
  PullRight,
  Text,
  Tooltip,
} from 'components';

import { getQuestionAnswerFilters } from '../helpers/answerFilters';
import AnswerItem from './AnswerItem';

type Props = WithDeprecatedPaginationProps & {
  campaign: SurveyCampaign;
  selectedPeriodId: string;
  segment: UserFilterSegment | null;
  questionStats: SurveyQuestionStats;
  question: SurveyQuestion;
  commentEnabled?: boolean;
  correlationSlug?: string | null;
};

type AfterPaginateProps = Props & PaginationProps;

type AfterDataLoaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    answersCollection: PaginatedCollection<SurveyAnswer>;
  };

const AnswersList = ({
  campaign,
  question,
  questionStats,
  answersCollection,
  filter,
  onFilterChange,
  isFetching,
  hasError,
  page,
  countPerPage,
  previousPageLink,
  nextPageLink,
  getPreviousPage,
  getNextPage,
}: AfterDataLoaderProps) => {
  const { aiFeaturesEnabled } = useCurrentOrganization();

  const isTextQuestion = question.questionType === 'text';
  const sentimentEnabled =
    isTextQuestion && aiFeaturesEnabled && question.textSentimentEnabled;

  const shouldShowScore = () => {
    if (question.questionType === 'text') return sentimentEnabled;
    return true;
  };

  const showAnswerScore = shouldShowScore();
  const showEmptyState =
    (!filter || filter.all) && answersCollection?.totalRecordCount === 0;

  return (
    <Box className={'survey-question-stats survey-box-question'}>
      <Text preset="18bs5" className="block mb-4">
        {__('Answers')}
      </Text>

      {!!answersCollection && (
        <Fragment>
          {!isFetching && showEmptyState && (
            <EmptyStateWithIcon
              iconName="comment"
              title={__('No shared answer yet')}
            />
          )}

          {!showEmptyState && (
            <div className="survey-answers-box">
              {/* @ts-ignore TSFIXME */}
              <DatatableWrapper
                collectionInfo={answersCollection}
                totalCountRenderer={totalCount =>
                  n__('%1 answer', '%1 answers', totalCount || 0)
                }
                page={page}
                countPerPage={countPerPage}
                previousPageLink={previousPageLink}
                nextPageLink={nextPageLink}
                getPreviousPage={getPreviousPage}
                getNextPage={getNextPage}
                onFilterChange={onFilterChange}
                filter={filter}
                filters={getQuestionAnswerFilters(aiFeaturesEnabled, question)}
                isFetching={isFetching}
                hasError={hasError}
                renderNoResult={() => (
                  <div className="survey-answers-header">
                    <EmptyState
                      description={__('No answer matches your search')}
                    />
                  </div>
                )}
                renderHeader={({ filters, activeFilter, onFilterChange }) => {
                  return (
                    <div className="survey-answers-header">
                      {!!filters && !!onFilterChange && (
                        <Columns>
                          <Column size={12} className="p-1">
                            <PullRight style={{ textAlign: 'end' }}>
                              <FilterBar
                                display="link"
                                leftText={__('Show')}
                                filters={filters}
                                activeFilter={activeFilter}
                                // @ts-ignore TSFIXME: Fix strictNullChecks error
                                onFilterChange={onFilterChange}
                              />
                            </PullRight>
                          </Column>
                        </Columns>
                      )}
                      <Columns>
                        <Column>
                          <Text preset="14s6" color="light">
                            {isTextQuestion
                              ? __('noun|Answer')
                              : __('noun|Comment')}
                          </Text>
                        </Column>
                        {showAnswerScore && (
                          <Column size={2}>
                            <Text
                              preset="14s6"
                              color="light"
                              className="inline-flex"
                            >
                              {sentimentEnabled ? (
                                <TextScoreHeader />
                              ) : (
                                __('noun|Answer')
                              )}
                            </Text>
                          </Column>
                        )}
                      </Columns>
                    </div>
                  );
                }}
              >
                <BoxList>
                  <FetchContainer
                    isFetching={isFetching}
                    hasError={hasError}
                    render={() => (
                      <Flex direction="column">
                        {answersCollection.items.map(answer => (
                          <AnswerItem
                            answer={answer}
                            campaign={campaign}
                            questionStats={questionStats}
                            questionType={question.questionType}
                            showAnswerScore={showAnswerScore}
                            key={answer.id}
                            testClassName="test-survey-answer-item"
                          />
                        ))}
                      </Flex>
                    )}
                  />
                </BoxList>
              </DatatableWrapper>
            </div>
          )}
        </Fragment>
      )}
    </Box>
  );
};

const TextScoreHeader = () => {
  return (
    <Fragment>
      {__('Sentiment analysis')}
      <Tooltip
        content={__(
          'The sentiments displayed here are obtained thanks to the Elevo AI, which assists you by categorizing each answer to a question as positive, neutral or negative.'
        )}
        style={{ maxWidth: '400px' }}
        triggerClassName="flex items-center"
      >
        <Icon name="info" className="ml-1" />
      </Tooltip>
    </Fragment>
  );
};

export default compose<React.ComponentType<Props>>(
  withDeprecatedPagination,
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      filter,
      campaign,
      question,
      selectedPeriodId,
      segment,
      correlationSlug,
    }: AfterPaginateProps) =>
      get(`survey/campaigns/${campaign.id}/questions/answers`, {
        page,
        countPerPage,
        filter,
        correlationSlug,
        userFilter: segment,
        questionId: question.id,
        periodId: selectedPeriodId,
      }),
    hydrate: {
      answersCollection: { items: { choices: {}, messagingChatRoom: {} } },
    },
    cacheKey: ({
      page,
      countPerPage,
      filter,
      campaign,
      question,
      selectedPeriodId,
      segment,
      correlationSlug,
    }: AfterPaginateProps) =>
      compositeKey({
        view: 'answers',
        page,
        countPerPage,
        filter,
        campaign,
        question,
        selectedPeriodId,
        segment,
        correlationSlug,
      }),
  })
)(AnswersList);
