import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { AuthenticationStrategy } from 'models';
import type {
  InvitationParams,
  LoginPasswordPair,
} from 'redux/actions/resources';
import type { AppDispatch } from 'redux/actions/types';

import { __ } from 'helpers/i18n';
import { navigate, pathToSignin } from 'helpers/navigation';

import newDataLoader from 'lib/dataLoader/newDataLoader';
import { errorNotice } from 'redux/actions';
import { get } from 'redux/actions/api/index';
import { acceptInvitation, signIn } from 'redux/actions/resources';

import {
  Button,
  Control,
  FetchContainer,
  Field,
  FieldError,
  Form,
  Input,
  Label,
  PageTitle,
  StrictlySanitizedHtml,
} from 'components';

type Props = {
  login: string;
  invitationToken: string;
  firstName: string;
  lastName: string;
};

type OnboardingFields = {
  firstName: string;
  lastName: string;
  password: string;
};

type AfterConnectProps = Props &
  DataLoaderProvidedProps & {
    authenticationStrategy: AuthenticationStrategy;
    acceptInvitation: (params: InvitationParams) => Promise<void>;
    signIn: (params: LoginPasswordPair) => Promise<void>;
    notifyError: (message: string) => void;
  };

type State = {
  errors: {
    firstName?: string | null;
    lastName?: string | null;
    password?: Array<string> | null;
  };
  isSubmitting: boolean;
  values: OnboardingFields;
};

class AcceptInvitationForm extends React.Component<AfterConnectProps, State> {
  state: State = {
    errors: {},
    isSubmitting: false,
    values: {
      firstName: this.props.firstName || '',
      lastName: this.props.lastName || '',
      password: '',
    },
  };

  handleInputChange = (
    name: keyof OnboardingFields,
    value: string | null | undefined
  ) => {
    this.setState(prevState => {
      const newValues = { ...prevState.values, [name]: value || '' };
      return {
        values: newValues,
        errors: { ...prevState.errors, [name]: null },
      };
    });
  };

  isStrategyPassword = () =>
    this.props.authenticationStrategy.strategy === 'password';

  handleSubmit = async () => {
    const {
      acceptInvitation,
      authenticationStrategy,
      signIn,
      invitationToken,
      login,
    } = this.props;
    const { values } = this.state;

    this.setState({ isSubmitting: true });

    try {
      await acceptInvitation({ ...values, invitationToken });
      this.isStrategyPassword()
        ? signIn({ login, password: values.password })
        : window.location.replace(authenticationStrategy.redirect);
    } catch (e) {
      this.setState({ isSubmitting: false });
      this.handleError(e);
    }
  };

  handleError(error) {
    const errors = error.response && error.response.body.errors;
    if (errors && (errors.firstName || errors.lastName || errors.password)) {
      this.setState({ errors });
    } else {
      window.logException(error);
    }

    if (errors && errors.invitationToken) {
      this.props.notifyError(
        __('Invitation link already used. Redirecting to Sign In...')
      );
      setTimeout(() => {
        navigate(pathToSignin());
      }, 2000);
    }
  }

  render() {
    const { isFetching, hasError } = this.props;

    const { errors, values } = this.state;

    return (
      <FetchContainer
        hasError={hasError}
        isFetching={isFetching}
        render={() => (
          <React.Fragment>
            <PageTitle title={__('Sign up')} />
            <div style={{ textAlign: 'center' }}>
              <h3 className="title is-3">
                {__('Confirm your account creation')}
              </h3>
              <p>
                {__(
                  'Welcome to Elevo! Confirm your name and create your password below.'
                )}
              </p>
            </div>
            <br />
            <Form onSubmit={this.handleSubmit}>
              <input name="invitationToken" value="input" type="hidden" />
              <input name="elevo-login" value="input" type="hidden" />

              <Field>
                <Label>{__('First Name')}</Label>

                <Control>
                  <Input
                    name="firstName"
                    value={values.firstName}
                    onChange={value =>
                      this.handleInputChange('firstName', value)
                    }
                    placeholder={__('First Name')}
                  />

                  <FieldError>{errors.firstName}</FieldError>
                </Control>
              </Field>
              <Field>
                <Label>{__('Last Name')}</Label>

                <Control>
                  <Input
                    name="lastName"
                    value={values.lastName}
                    onChange={value =>
                      this.handleInputChange('lastName', value)
                    }
                    placeholder={__('Last Name')}
                  />

                  <FieldError>{errors.lastName}</FieldError>
                </Control>
              </Field>

              {this.isStrategyPassword() && (
                <Field>
                  <Label>{__('New password')}</Label>

                  <Control>
                    <Input
                      name="password"
                      type="password"
                      value={values.password}
                      onChange={value =>
                        this.handleInputChange('password', value)
                      }
                      placeholder={__('Password')}
                    />

                    {!!errors.password &&
                      errors.password.map(passwordError => {
                        return (
                          <FieldError>
                            <StrictlySanitizedHtml html={passwordError} />
                          </FieldError>
                        );
                      })}
                  </Control>
                </Field>
              )}

              <Button
                type="submit"
                isExpanded
                color="primary"
                onClick={this.handleSubmit}
                isLoading={this.state.isSubmitting}
              >
                {__('Accept invitation')}
              </Button>
            </Form>
          </React.Fragment>
        )}
      />
    );
  }
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    acceptInvitation: (invitationParams: InvitationParams) =>
      dispatch(acceptInvitation(invitationParams)),
    signIn: (signInParams: LoginPasswordPair) => dispatch(signIn(signInParams)),
    notifyError: (message: string) => dispatch(errorNotice(message)),
  };
}

export default compose<React.ComponentType<Props>>(
  newDataLoader({
    fetch: ({ login }: Props) => {
      return get('authentication_strategies', { login });
    },
    hydrate: {
      authenticationStrategy: {},
    },
  }),
  // @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
  connect(null, mapDispatchToProps)
)(AcceptInvitationForm);
