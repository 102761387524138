import React from 'react';

import {
  Icon,
  Number,
  Percentage,
  StrictlySanitizedHtml,
  Text,
  Tooltip,
} from 'components';

type Props = {
  backgroundColorClass: string;
  mainNumber?: number;
  mainPercentage?: number;
  title: string;
  tooltipTitle?: string;
  tooltipIsLight?: boolean;
  description: string;
};

const StatsGridItem = ({
  backgroundColorClass,
  mainNumber,
  mainPercentage,
  title,
  tooltipTitle,
  tooltipIsLight,
  description,
}: Props) => {
  return (
    <div className="flex grow">
      <div className="flex w-1/4 flex-col items-center justify-between my-1 mr-5">
        <Text preset="24bs2">
          {(!!mainNumber || mainNumber === 0) && <Number value={mainNumber} />}
          {(!!mainPercentage || mainPercentage === 0) && (
            <Percentage value={mainPercentage} />
          )}
        </Text>
        <div
          className={`flex h-1 mx-3 ${backgroundColorClass} rounded w-3/4`}
        />
      </div>
      <div className="items grow-center nowrap-whitespace">
        <div className="flex">
          <Text preset="16bs5.5" color="main">
            {title}
          </Text>
          {!!tooltipTitle && (
            <Tooltip
              content={
                <StrictlySanitizedHtml
                  componentType="span"
                  html={tooltipTitle}
                />
              }
              triggerClassName="max-w-[340px]"
              isLight={!!tooltipIsLight}
            >
              <Icon style={{ marginLeft: 8 }} name="info" />
            </Tooltip>
          )}
        </div>
        <Text preset="14bs6" color="soften">
          <StrictlySanitizedHtml componentType="div" html={description} />
        </Text>
      </div>
    </div>
  );
};

export default StatsGridItem;
