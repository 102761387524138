import { cloneDeep } from 'lodash';
import React, { useEffect, useState } from 'react';
import Skeleton from 'react-loading-skeleton';
import { compose } from 'redux';

import { SkillsMatrixWithSuggestions } from 'models/skills';

import { useAppDispatch } from 'helpers/hooks';
import { __, n__ } from 'helpers/i18n';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get, put } from 'redux/actions/api';

import {
  Button,
  ConfirmationModal,
  FetchContainer,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
} from 'components';

import AILogo from 'scenes/components/AILogo';

import AccordionStructure from './AccordionStructure';
import SuggestionsAccordion, {
  type AreaId,
  type CourseId,
  type SelectedCourses,
} from './SuggestionsAccordion';

const LoadingState = () => (
  <AccordionStructure
    defaultState="open"
    design="flat"
    items={Array(2)
      .fill(null)
      .map((_, index) => ({
        key: index,
        title: <Skeleton width={200} />,
        content: (
          <div className="flex flex-col gap-4 py-4">
            <Skeleton width={200} />
            <Skeleton width={200} />
            <Skeleton width={200} />
          </div>
        ),
      }))}
  />
);

type Props = {
  matrixId: string;
  onClose: () => void;
  onConfirm?: () => void;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    matrix: SkillsMatrixWithSuggestions;
  };

const AutoSuggestModal = ({
  matrix,
  isFetching,
  hasError,
  onClose: closeModal,
  onConfirm,
}: AfterDataLoaderProps) => {
  const [selectedCourses, setSelectedCourses] = useState<SelectedCourses>({});
  const [removedCoursesCount, setRemovedCoursesCount] = useState(0);
  const currentModal = removedCoursesCount > 0 ? 'removalConfirm' : 'main';

  const dispatch = useAppDispatch();

  // Select all courses by default
  useEffect(() => {
    if (matrix) {
      const defaultSelectedCourses: SelectedCourses = {};
      matrix.areas.forEach(area => {
        defaultSelectedCourses[area.id] = {};
        area.trainingCourses.forEach(course => {
          defaultSelectedCourses[area.id][course.id] = true;
        });
        area.autoTrainingCourses!.forEach(course => {
          defaultSelectedCourses[area.id][course.id] = true;
        });
      });
      setSelectedCourses(defaultSelectedCourses);
    }
  }, [matrix]);

  const getRemovedCoursesCount = () => {
    let count = 0;
    matrix.areas.forEach(area => {
      area.trainingCourses.forEach(course => {
        if (!selectedCourses[area.id][course.id]) {
          count++;
        }
      });
    });
    return count;
  };

  const createSuggestions = async () => {
    const courseIds: Record<AreaId, CourseId[]> = {};
    Object.keys(selectedCourses).forEach(areaId => {
      courseIds[areaId] = Object.keys(selectedCourses[areaId]).filter(
        courseId => selectedCourses[areaId][courseId]
      );
    });
    await dispatch(
      put(
        `skills/matrices/${matrix.id}/training_suggestions`,
        {
          courseIds,
        },
        {
          successMessage: __('Suggestions have been successfully updated'),
        }
      )
    );
    if (onConfirm) onConfirm();
    closeModal();
  };

  const confirmSelectedCourses = () => {
    const removedCount = getRemovedCoursesCount();
    if (removedCount > 0) {
      setRemovedCoursesCount(removedCount);
    } else {
      createSuggestions();
    }
  };

  const displayMainModal = () => {
    setRemovedCoursesCount(0);
  };

  const toggleCourse = (areaId: string, courseId: string) => {
    setSelectedCourses(prevSelected => {
      const newSelectedIds = cloneDeep(prevSelected);

      newSelectedIds[areaId][courseId] = !newSelectedIds[areaId][courseId];

      return newSelectedIds;
    });
  };

  if (currentModal === 'main') {
    return (
      <ModalCard isActive isLarge onClose={closeModal}>
        <ModalCardHead>
          <ModalCardTitle>
            {__('Preview training suggestions')} <AILogo />
          </ModalCardTitle>
        </ModalCardHead>

        <ModalCardBody>
          <Text preset="14s6" className="block">
            {__(
              'Preview and choose the training courses that will be suggested to employees in the catalog, based on the skills associated with their matrices.'
            )}
          </Text>
          <FetchContainer
            isFetching={isFetching}
            hasError={hasError}
            renderFetching={() => <LoadingState />}
            errorMessage={__(
              'Suggestions could not be retrieved at the moment. Please try again later.'
            )}
            render={() => (
              <SuggestionsAccordion
                matrix={matrix}
                design="flat"
                selectedCourses={selectedCourses}
                onCourseToggle={toggleCourse}
                showAutoSuggestions
              />
            )}
          />
        </ModalCardBody>

        <ModalCardFooter>
          <Button color="secondary" onClick={closeModal}>
            {__('Cancel')}
          </Button>
          <Button
            color="primary"
            onClick={confirmSelectedCourses}
            disabled={!matrix}
          >
            {__('Apply')}
          </Button>
        </ModalCardFooter>
      </ModalCard>
    );
  } else if (currentModal === 'removalConfirm') {
    return (
      <ConfirmationModal
        onConfirm={createSuggestions}
        confirmLabel={n__(
          'Remove %1 training course',
          'Remove %1 training courses',
          removedCoursesCount
        )}
        onCancel={displayMainModal}
        cancelLabel={__('Back')}
        title={n__(
          'Remove %1 training course from the suggestions?',
          'Remove %1 training courses from the suggestions?',
          removedCoursesCount
        )}
        isActive
        isDanger
        refreshContentOnOpening
      >
        <p>
          {n__(
            'Among the unchecked courses, one is part of the currently suggested training courses.',
            'Among the unchecked courses, %1 are part of the currently suggested training courses.',
            removedCoursesCount
          )}
        </p>
        <p>
          {n__(
            'Do you want to remove it from the catalog suggestions?',
            'Do you want to remove them from the catalog suggestions?',
            removedCoursesCount
          )}
        </p>
      </ConfirmationModal>
    );
  } else {
    throw new Error('Invalid auto suggest modal state');
  }
};

export default compose(
  newDataLoader({
    fetch: ({ matrixId }: Props) => {
      return get(`skills/matrices/${matrixId}/training_suggestions`, {
        withAutoSuggestions: true,
      });
    },
    hydrate: {
      matrix: {
        areas: {
          trainingCourses: { organism: {} },
          autoTrainingCourses: { organism: {} },
        },
      },
    },
  })
)(AutoSuggestModal) as React.ComponentType<Props>;
