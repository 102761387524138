import React, { ReactNode } from 'react';
import { useLocation } from 'react-router-dom';

import classNames from 'helpers/classNames';
import { isPageWithoutNavbar } from 'helpers/navigation';

import { AppBar, AppContentContainer } from 'components';

import CustomStyleManager from './CustomStyleManager';

type Props = {
  children: ReactNode;
};

function AppLayout({ children }: Props) {
  const location = useLocation();

  return (
    <div className="sm:flex">
      <CustomStyleManager />

      <AppBar />
      <div
        className={classNames('w-full', {
          'md:pl-[var(--vertical-nav-width)]': !isPageWithoutNavbar(
            location.pathname
          ),
        })}
      >
        <AppContentContainer>{children}</AppContentContainer>
      </div>
    </div>
  );
}

export default AppLayout;
