import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import type { EditPasswordParams } from 'redux/actions/resources/user';
import type { AppDispatch } from 'redux/actions/types';

import { largeMargin } from 'styles/uiConstants';

import { __ } from 'helpers/i18n';
import queryString from 'helpers/queryString';

import { editPassword } from 'redux/actions/resources/user';

import {
  Button,
  Control,
  Field,
  FieldError,
  Form,
  Input,
  Label,
  PageTitle,
  Splash,
  StrictlySanitizedHtml,
} from 'components';

type Props = {
  editPassword: (params: EditPasswordParams) => Promise<void>;
};

type State = {
  errors: {
    passwordConfirmation?: string;
    password?: Array<string>;
  };
  resetingPassword: boolean;
  isSubmitting: boolean;
  values: {
    resetPasswordToken: string;
    password: string;
    passwordConfirmation: string;
    email: string;
  };
};

class ResetPassword extends React.Component<Props, State> {
  state: State = {
    errors: {},
    resetingPassword: false,
    isSubmitting: false,
    values: {
      resetPasswordToken: queryString.parse(window.location.search)
        .reset_password_token as string,
      password: '',
      passwordConfirmation: '',
      email: queryString.parse(window.location.search).email as string,
    },
  };

  handleInputChange = (name: string, value: string | null | undefined) => {
    this.setState(prevState => ({
      values: { ...prevState.values, [name]: value || '' },
      errors: { ...prevState.errors, [name]: null },
    }));
  };

  handleSubmit = () => {
    const { editPassword } = this.props;

    this.setState({ isSubmitting: true });

    return editPassword(this.state.values).then(
      () => {
        this.setState({ isSubmitting: false, errors: {} });
      },
      error => this.handleError(error)
    );
  };

  handleError = error => {
    this.setState({ isSubmitting: false });
    const errors = error.response && error.response.body.errors;
    if (errors && (errors.passwordConfirmation || errors.password)) {
      this.setState({ errors });
    } else {
      window.logException(error);
    }
  };

  render() {
    const { errors, values, isSubmitting } = this.state;

    return (
      <Splash>
        <PageTitle title={__('Reset your password')} />

        <div style={{ textAlign: 'center' }}>
          <h3 className="title is-3">{__('Reset Password')}</h3>
          <p>{__('Please enter your new password')}</p>
        </div>

        <Form onSubmit={this.handleSubmit}>
          <input
            name="resetPasswordToken"
            value={values.resetPasswordToken}
            type="hidden"
          />

          <Field>
            <Label>{__('Password')}</Label>

            <Control>
              <Input
                name="password"
                type="password"
                value={values.password}
                onChange={value => this.handleInputChange('password', value)}
                placeholder={__('Password')}
              />
            </Control>

            {!!errors.password &&
              errors.password.map(passwordError => {
                return (
                  <FieldError>
                    <StrictlySanitizedHtml html={passwordError} />
                  </FieldError>
                );
              })}
          </Field>

          <Field>
            <Label>{__('Confirm password')}</Label>

            <Control>
              <Input
                name="passwordConfirmation"
                type="password"
                value={values.passwordConfirmation}
                onChange={value =>
                  this.handleInputChange('passwordConfirmation', value)
                }
                placeholder={__('Confirm password')}
              />
            </Control>

            {!!errors.passwordConfirmation && (
              <FieldError>{errors.passwordConfirmation}</FieldError>
            )}
          </Field>

          <Button
            isExpanded
            type="submit"
            color="primary"
            onClick={this.handleSubmit}
            isLoading={isSubmitting}
            testClassName="test-reset-password-submit-button"
          >
            {__('Reset password')}
          </Button>
        </Form>

        <div style={{ textAlign: 'center', marginTop: largeMargin }}>
          <Link to="/signin">{__('Go to sign in')}</Link>
        </div>
      </Splash>
    );
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  editPassword: (params: EditPasswordParams) => dispatch(editPassword(params)),
});

// @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
export default connect(null, mapDispatchToProps)(ResetPassword);
