import type { OrganizationTheme } from 'models';

import { useAppSelector } from 'helpers/hooks';
import invariant from 'helpers/invariant';

import { getOrganizationTheme } from 'redux/reducers';

const useOrganizationTheme = (): OrganizationTheme => {
  return useAppSelector(state => {
    const organizationTheme = getOrganizationTheme(state);

    invariant(organizationTheme, 'Organization plan should be defined.');

    return organizationTheme;
  });
};

export default useOrganizationTheme;
