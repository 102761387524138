import React from 'react';

import type { TrainingRequest } from 'models';

import { handleFormErrors } from 'helpers/api';
import can from 'helpers/can';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { put } from 'redux/actions/api';

import {
  Button,
  Centered,
  Divider,
  Helper,
  Icon,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
} from 'components';

import DeleteParticipantWarning from './DeleteParticipantWarning';
import ModalContent from './ModalContent';

type Props = {
  trainingRequest: TrainingRequest;
  currentPeriodSlug: string;
  onClose: (options?: { shouldRefetchData: boolean }) => void;
  onRequestRefusal?: () => void;
  linkedSessionName: string | null;
};

const TrainingRequestManagementModal = ({
  trainingRequest: trainingRequestFromProps,
  currentPeriodSlug,
  onClose,
  onRequestRefusal,
  linkedSessionName,
}: Props) => {
  const dispatch = useAppDispatch();

  const onSubmit = async ({
    id,
    title,
    description,
    priority,
    approvalStatus,
    refusalReason,
    trainingPeriod,
    trainingCourse,
  }: TrainingRequest) => {
    await dispatch(
      put(`training/periods/${currentPeriodSlug}/requests/${id}`, {
        trainingRequest: {
          title,
          description,
          priority,
          approvalStatus,
          refusalReason,
          trainingPeriodId: trainingPeriod.id,
          trainingCourseId: trainingCourse?.id || null,
        },
      })
    );

    if (
      trainingRequestFromProps.approvalStatus !== 'refused' &&
      approvalStatus === 'refused'
    ) {
      !!onRequestRefusal && onRequestRefusal();
    }

    onClose({ shouldRefetchData: true });
  };
  const [errors, setErrors] = React.useState({});
  const [trainingRequest, setTrainingRequest] = React.useState({
    ...trainingRequestFromProps,
  });

  const handleSubmit = () =>
    handleFormErrors(() => onSubmit(trainingRequest), setErrors);

  const onTrainingRequestChange = (params: Partial<TrainingRequest>) =>
    setTrainingRequest({ ...trainingRequest, ...params });

  const canUpdate =
    can({ perform: 'update_details', on: trainingRequest }) ||
    can({ perform: 'update_status', on: trainingRequest });

  const renderInformationComponent = () => {
    if (!canUpdate || trainingRequest.approvalStatus === 'waiting_for_approval')
      return null;

    if (trainingRequest.approvalStatus === 'approved') {
      return (
        <Helper>
          {__(
            'An email will be sent automatically to inform %1 that the request has been approved',
            trainingRequest.trainee.fullName
          )}
        </Helper>
      );
    }

    if (!!linkedSessionName) {
      return (
        <DeleteParticipantWarning
          user={trainingRequest.trainee}
          sessionName={linkedSessionName}
        />
      );
    }
    return (
      <Helper>
        {__(
          'An email will be sent automatically to inform %1 that the training request has been refused.',
          trainingRequest.trainee.fullName
        )}
      </Helper>
    );
  };

  const informationComponent = renderInformationComponent();

  return (
    <ModalCard
      className="training-request-management-modal"
      isActive
      isLarge
      refreshContentOnOpening
      onClose={() => onClose({ shouldRefetchData: false })}
    >
      <ModalCardHead>
        <ModalCardTitle>{__('Request details')}</ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody style={{ paddingBottom: 16 }}>
        <Divider />
        <ModalContent
          request={trainingRequest}
          errors={errors}
          onChange={onTrainingRequestChange}
        />
      </ModalCardBody>
      <ModalCardFooter>
        <Centered>
          {informationComponent && (
            <div>
              <Divider />
              {informationComponent}
            </div>
          )}

          <div style={{ marginTop: 16 }}>
            <Button
              onClick={() => onClose({ shouldRefetchData: false })}
              color="secondary"
            >
              {canUpdate ? __('Cancel') : __('Close')}
            </Button>
            {canUpdate && (
              <Button
                onClick={handleSubmit}
                color="primary"
                testClassName="test-submit-training-request-button"
              >
                <Icon style={{ marginRight: 8 }} name="save" />
                {__('Save')}
              </Button>
            )}
          </div>
        </Centered>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default TrainingRequestManagementModal;
