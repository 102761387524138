import React, { ComponentType, Fragment, useState } from 'react';
import { compose } from 'redux';

import { PaginatedCollection, SkillsMatrix } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';
import { pathToMatrixTrainingCourseSuggestions } from 'helpers/paths';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { WithPaginationProps } from 'lib/pagination/types';
import withPagination from 'lib/pagination/withPagination';
import { get } from 'redux/actions/api';

import {
  Box,
  ContentContainer,
  DatatableWrapper,
  EmptyStateWithIcon,
  FetchContainer,
  HamburgerMenu,
  Link,
  MenuItem,
  MenuList,
  SimpleTable,
  Text,
  Tooltip,
} from 'components';

import PageHeader from '../components/PageHeader';
import AutoSuggestModal from './components/AutoSuggestModal';
import NoMatrices from './components/NoMatrices';
import useEmbeddingsReady from './hooks/useEmbeddingsReady';

type Props = {};

type AfterPaginationProps = Props & WithPaginationProps;

type AfterDataLoaderProps = AfterPaginationProps &
  DataLoaderProvidedProps & {
    matrixCollection: PaginatedCollection<SkillsMatrix>;
  };

const MatrixList = ({
  matrixCollection,
  page,
  countPerPage,
  queryParams,
  setPreviousPageParams,
  setQueryParams,
  setNextPageParams,
  isFetching,
  hasError,
  refetchData,
}: AfterDataLoaderProps) => {
  const { search, filter } = queryParams || {};
  const [autoSuggestMatrixId, setAutoSuggestMatrixId] = useState<string | null>(
    null
  );

  const {
    isFetching: embeddingsReadyFetching,
    isReady: embeddingsReady,
    pollerComponent,
  } = useEmbeddingsReady();

  return (
    <Fragment>
      {pollerComponent}
      <PageHeader />
      <ContentContainer>
        <div className="mb-4">
          <Text preset="14s6">
            {__(
              'Save time by automatically matching training courses to skills with Elevo AI. Elevo will then recommend relevant training courses to your employees based on their matrices, directly in the catalog.'
            )}
          </Text>
        </div>
        <Box>
          <DatatableWrapper
            collectionInfo={matrixCollection ? matrixCollection : null}
            search={search}
            page={page}
            countPerPage={countPerPage}
            getPreviousPage={setPreviousPageParams}
            onQueryParamsChange={setQueryParams}
            getNextPage={setNextPageParams}
            isFetching={isFetching}
            withSearch
            hasError={hasError}
            renderNoResult={() => (
              <EmptyStateWithIcon
                title={__('No skill matrix match your search')}
                iconName={'table_chart'}
                inBox={false}
              />
            )}
            renderNoRecord={() => <NoMatrices />}
            filter={filter}
            filters={[
              { param: 'all', label: __('All') },
              { param: 'with_suggestions', label: __('With suggestions') },
              {
                param: 'without_suggestions',
                label: __('Without suggestions'),
              },
            ]}
            totalCountRenderer={totalCount =>
              n__('%1 skills matrix', '%1 skills matrices', totalCount || 0)
            }
          >
            <FetchContainer
              isFetching={isFetching}
              hasError={hasError}
              loadingStyle="overlay"
              render={() => (
                <SimpleTable
                  columns={[
                    {
                      header: __('Skills matrix'),
                      cell: matrix => (
                        <Link
                          to={pathToMatrixTrainingCourseSuggestions(matrix.id)}
                        >
                          {matrix.title}
                        </Link>
                      ),
                    },
                    {
                      header: __('Suggested training courses'),
                      cell: matrix => matrix.suggestionCount,
                    },
                    {
                      header: __('Associated employees'),
                      cell: matrix => matrix.associatedUsersCount,
                    },
                    {
                      header: null,
                      headerKey: 'actions',
                      isNarrow: true,
                      cell: matrix => (
                        <HamburgerMenu>
                          <MenuList>
                            <Tooltip
                              enabled={
                                !embeddingsReady && !embeddingsReadyFetching
                              }
                              content={__(
                                'Automatic suggestions are being prepared, this may take a few minutes.'
                              )}
                            >
                              <MenuItem
                                onClick={() => {
                                  setAutoSuggestMatrixId(matrix.id);
                                }}
                                disabled={!embeddingsReady}
                              >
                                {__('Suggest courses')}
                              </MenuItem>
                            </Tooltip>
                          </MenuList>
                        </HamburgerMenu>
                      ),
                    },
                  ]}
                  rows={matrixCollection?.items || []}
                  keyFn={matrix => matrix.id}
                />
              )}
            />
          </DatatableWrapper>
        </Box>
      </ContentContainer>
      {!!autoSuggestMatrixId && (
        <AutoSuggestModal
          matrixId={autoSuggestMatrixId}
          onClose={() => setAutoSuggestMatrixId(null)}
          onConfirm={refetchData}
        />
      )}
    </Fragment>
  );
};

export default compose<ComponentType<Props>>(
  withPagination,
  newDataLoader({
    fetch: ({
      queryParams: { search, filter },
      page,
      countPerPage,
    }: AfterPaginationProps) =>
      get('/skills/matrices', {
        search,
        page,
        filter,
        countPerPage,
        withSuggestionCount: true,
      }),
    hydrate: { matrixCollection: { items: {} } },
    cacheKey: ({ queryParams, page, countPerPage }) =>
      compositeKey({
        type: 'matrices',
        page,
        countPerPage,
        queryParams,
      }),
  })
)(MatrixList);
