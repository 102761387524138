import React, { Fragment } from 'react';

import type { UpdatableAttributes } from '.';
import type { TrainingSession } from 'models';

import { FormErrors } from 'helpers/api';
import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { Field, FieldError, Label, Radio } from 'components';
import MoneyInput from 'components/formElements/advancedElements/MoneyInput';

type Props = {
  session: TrainingSession;
  errors: FormErrors;
  disabled: boolean;
  onChange: (attributes: Partial<UpdatableAttributes>) => void;
};

const EditableFields = ({ session, errors, disabled, onChange }: Props) => {
  const { featureFlags } = useCurrentOrganization();
  const withTrainingCurrencies = featureFlags.includes('trainingCurrencies');

  return (
    <Fragment>
      <Field>
        <Label>
          {__('Is the training cost billed per session or per participant?')}
        </Label>
        <div className="flex -ml-3">
          <Radio
            label={__('Per session')}
            isChecked={session.costComputationType === 'per_session'}
            disabled={disabled}
            onChange={() =>
              onChange({
                costComputationType: 'per_session',
              })
            }
            testClassName="test-cost-computation-type-per-session-radio"
          />
          <Radio
            label={__('Per participant')}
            isChecked={session.costComputationType === 'per_participant'}
            onChange={() =>
              onChange({
                costComputationType: 'per_participant',
              })
            }
            disabled={disabled}
            testClassName="test-cost-computation-type-per-participant-radio"
          />
        </div>
        <FieldError>{errors.costComputationType}</FieldError>
      </Field>
      {session.costComputationType === 'per_session' ? (
        <Field>
          <Label>{__('Session total cost')}</Label>
          <MoneyInput
            key="totalCostPerSessionCents"
            amount={session.totalCostPerSessionCents}
            placeholder={__('Enter an amount')}
            onAmountChange={totalCostPerSessionCents => {
              if (
                totalCostPerSessionCents === session.totalCostPerSessionCents
              ) {
                return;
              }
              onChange({
                totalCostPerSessionCents,
              });
            }}
            currency={session.totalCostPerSessionCurrency}
            onCurrencyChange={
              withTrainingCurrencies
                ? totalCostPerSessionCurrency =>
                    onChange({ totalCostPerSessionCurrency })
                : undefined
            }
            isDisabled={disabled}
            saveOnlyOnBlur
            className="max-w-[50%]"
          />
          <FieldError>{errors.totalCostPerSession}</FieldError>
        </Field>
      ) : (
        <Field>
          <Label>{__('Cost per participant')}</Label>
          <MoneyInput
            key="costPerParticipantCents"
            amount={session.costPerParticipantCents}
            placeholder={__('Enter an amount')}
            onAmountChange={costPerParticipantCents => {
              if (costPerParticipantCents === session.costPerParticipantCents) {
                return;
              }
              onChange({ costPerParticipantCents });
            }}
            currency={session.costPerParticipantCurrency}
            onCurrencyChange={
              withTrainingCurrencies
                ? costPerParticipantCurrency =>
                    onChange({ costPerParticipantCurrency })
                : undefined
            }
            isDisabled={disabled}
            saveOnlyOnBlur
            className="max-w-[50%]"
          />
          <FieldError>{errors.costPerParticipantCents}</FieldError>
        </Field>
      )}
      <Field>
        <Label>{__('Will funding be the same for all participants?')}</Label>
        <div className="flex -ml-3">
          <Radio
            label={__('Yes')}
            isChecked={!!session.sameFundingPerParticipant}
            disabled={disabled}
            onChange={() =>
              onChange({
                sameFundingPerParticipant: true,
              })
            }
            testClassName="test-same-funding-per-participant-radio"
          />
          <Radio
            label={__('No')}
            isChecked={!session.sameFundingPerParticipant}
            onChange={() =>
              onChange({
                sameFundingPerParticipant: false,
              })
            }
            disabled={disabled}
            testClassName="test-different-funding-per-participant-radio"
          />
        </div>
        <FieldError>{errors.costComputationType}</FieldError>
      </Field>
    </Fragment>
  );
};

export default EditableFields;
