import React from 'react';

import { __ } from 'helpers/i18n';
import { zendeskURL } from 'helpers/zendesk';

import { EmptyStateWithIcon, Link } from 'components';

const NoCourseState = () => {
  const documentationURL = zendeskURL({
    fr: '29613842189585-Naviguer-dans-le-catalogue-de-formation-en-tant-qu-admin',
    en: '29613842189585-Browse-the-training-catalog-as-an-admin',
  });

  return (
    <EmptyStateWithIcon
      iconName="school"
      title={__('Create your courses')}
      description={__(
        'Enhance your catalog by adding training courses for your employees. To learn more about adding training courses, %1.',
        <Link
          to={documentationURL}
          additionalClassName="text-primary"
          openInNewTab
        >
          {__('visit our help center')}
        </Link>
      )}
    />
  );
};

export default NoCourseState;
