import React, { ReactElement } from 'react';

import type { UpdateReviewCycle } from '../../..';
import type { ReviewCycle } from 'models';

import { useOrganizationPlan } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import OptionWrapper from '../OptionWrapper';
import OneOnOneDeadlinesOptions from './OneOnOneDeadlinesOptions';
import ThreeSixtyDeadlinesOptions from './ThreeSixtyDeadlinesOptions';

type Props = {
  reviewCycle: ReviewCycle;
  updateReviewCycle: UpdateReviewCycle;
};

function DeadlinesOptions({ reviewCycle, updateReviewCycle }: Props) {
  const organizationPlan = useOrganizationPlan();
  const { interactionType } = reviewCycle;
  const autoAddParticipantsEnabled =
    organizationPlan.autoAddParticipantsEnabled &&
    reviewCycle.autoAddParticipantsEnabled;

  let content: ReactElement;
  let deadlineTitle: string;

  if (interactionType === 'one_on_one') {
    content = (
      <OneOnOneDeadlinesOptions
        reviewCycle={reviewCycle}
        updateReviewCycle={updateReviewCycle}
        autoAddParticipantsEnabled={autoAddParticipantsEnabled}
      />
    );
    deadlineTitle = __('Deadlines');
  } else if (interactionType === '360') {
    content = (
      <ThreeSixtyDeadlinesOptions
        reviewCycle={reviewCycle}
        updateReviewCycle={updateReviewCycle}
      />
    );
    deadlineTitle = __('Deadline');
  } else {
    throw new Error(
      `Review cycle interactionType should be one_on_one or 360, got ${interactionType}`
    );
  }

  return (
    <OptionWrapper
      title={
        autoAddParticipantsEnabled
          ? __('Deadline to complete the review')
          : deadlineTitle
      }
      subtitle={
        autoAddParticipantsEnabled
          ? __(
              'After this date, review will be still accessible by participants'
            )
          : __('Define the timeline for this campaign')
      }
      className="deadlines-option"
    >
      {content}
    </OptionWrapper>
  );
}

export default DeadlinesOptions as React.ComponentType<Props>;
