import React, { Fragment } from 'react';

import { ExchangeRate, TrainingPeriod } from 'models';

import compositeKey from 'helpers/compositeKey';
import currencies from 'helpers/currencies';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get, post } from 'redux/actions/api';

import {
  Button,
  FetchContainer,
  Icon,
  Label,
  StrictlySanitizedHtml,
  Text,
  Tooltip,
} from 'components';

import ExchangeRateItem from './ExchangeRateItem';

type Props = {
  trainingPeriod: TrainingPeriod;
  fundingSourcesCurrencies: string[];
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    exchangeRates: Array<ExchangeRate>;
  };

const ExchangeRates = ({
  trainingPeriod,
  fundingSourcesCurrencies,
  isFetching,
  hasError,
  refetchData,
  exchangeRates,
}: AfterDataLoaderProps) => {
  const dispatch = useAppDispatch();

  const addExchangeRate = (baseCurrency: string) =>
    dispatch(
      post(`exchange_rates`, {
        baseCurrency,
        targetCurrency: trainingPeriod.mainCurrency,
        resourceId: trainingPeriod.id,
        resourceType: 'TrainingPeriod',
      })
    );

  return (
    <div>
      <div className="flex items-center">
        <Label labelClassName="mb-1">{__('Exchange Rates')}</Label>
        <Tooltip
          isLight
          placement="right"
          className="max-w-md"
          content={__(
            'In the event that other currencies are used, you will have the option of adding their exchange rates. Otherwise, a rate of 1:1 will be used.'
          )}
        >
          <Icon className="mb-1 ml-2" name="info" color="info" />
        </Tooltip>
      </div>
      <Text color="light">
        <StrictlySanitizedHtml
          html={__(
            'Set the exchange rates against your main currency: <b>%1</b>',
            trainingPeriod.mainCurrency
          )}
        />
      </Text>

      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        loadingStyle="overlay"
        render={() => {
          if (!exchangeRates) return null;

          const usedCurrencies = exchangeRates.map(
            exchangeRate => exchangeRate.baseCurrency
          );
          const availableCurrencies = currencies
            .filter(currency => !usedCurrencies.includes(currency))
            .filter(currency => currency !== trainingPeriod.mainCurrency);

          const addButtonDisabled = availableCurrencies.length === 0;

          return (
            <Fragment>
              <div>
                {exchangeRates.map(exchangeRate => (
                  <ExchangeRateItem
                    key={exchangeRate.id}
                    periodSlug={trainingPeriod.slug}
                    availableCurrencies={availableCurrencies}
                    exchangeRate={exchangeRate}
                    locked={fundingSourcesCurrencies.includes(
                      exchangeRate.baseCurrency
                    )}
                    refetchData={refetchData}
                  />
                ))}
              </div>

              <Button
                onClick={async () => {
                  await addExchangeRate(availableCurrencies[0]);
                  refetchData();
                }}
                className="mt-2"
                color="secondary"
                disabled={addButtonDisabled}
              >
                {__('Add a currency')}
              </Button>
            </Fragment>
          );
        }}
      />
    </div>
  );
};

export default newDataLoader({
  fetch: ({ trainingPeriod }: Props) =>
    get(`exchange_rates`, {
      resourceId: trainingPeriod.id,
      resourceType: 'TrainingPeriod',
    }),
  hydrate: {
    exchangeRates: {},
  },
  cacheKey: ({ trainingPeriod, fundingSourcesCurrencies }) =>
    compositeKey({ trainingPeriod, fundingSourcesCurrencies }),
})(ExchangeRates);
