import { Fragment, ReactNode } from 'react';
import React from 'react';

import type { AbilitiesBearer, AbilityId } from 'models';

import can from 'helpers/can';
import { useCurrentOrganization } from 'helpers/hooks';
import invariant from 'helpers/invariant';

type Props = {
  perform: AbilityId;
  on: 'organization' | AbilitiesBearer;
  children?: ReactNode;
  not?: boolean;
  alternativeChildren?: ReactNode;
  render?: (isAuthorized: boolean) => ReactNode;
};

const Can = ({
  perform,
  on,
  children,
  alternativeChildren,
  render,
  not,
}: Props) => {
  invariant(
    (children && !render) || (!children && render),
    'cannot have both render and children props'
  );

  const organization = useCurrentOrganization();

  let content: ReactNode | null = null;
  const target = on === 'organization' ? organization : on;
  let isAuthorized = can({ perform: perform, on: target });
  isAuthorized = !!not ? !isAuthorized : isAuthorized;

  if (!!render) {
    content = render(isAuthorized);
  } else if (isAuthorized) {
    content = children;
  } else if (alternativeChildren) {
    content = alternativeChildren;
  }

  return <Fragment>{content}</Fragment>;
};

export default Can;
