import React from 'react';

import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon } from 'components';

const NoResultState = () => {
  return (
    <EmptyStateWithIcon
      className="mb-5"
      inBox={false}
      iconName="send"
      title={__(
        'There is no training session matching your search for this period.'
      )}
    />
  );
};

export default NoResultState;
