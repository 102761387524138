import { connect } from 'react-redux';

import type { ReduxStore } from '../redux/reducers';

import {
  getCurrentOrganization,
  getCurrentUser,
  getOrganizationPlan,
  getOrganizationSettings,
  getOrganizationTheme,
} from '../redux/reducers';

/**
 * @deprecated Use useActiveUser hook instad
 */
export const withActiveUser = connect((state: ReduxStore) => ({
  activeUser: getCurrentUser(state),
}));

/**
 * @deprecated Use useCurrentOrganization hook instad
 */
export const withOrganization = connect((state: ReduxStore) => ({
  organization: {
    ...getCurrentOrganization(state),
    settings: getOrganizationSettings(state),
    plan: getOrganizationPlan(state),
    theme: getOrganizationTheme(state),
  },
}));

/**
 * @deprecated Use useActiveUser and useCurrentOrganization hook instad
 */
export const withActiveUserAndOrganization = connect((state: ReduxStore) => ({
  activeUser: getCurrentUser(state),
  organization: {
    ...getCurrentOrganization(state),
    settings: getOrganizationSettings(state),
    plan: getOrganizationPlan(state),
    theme: getOrganizationTheme(state),
  },
}));
