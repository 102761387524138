import React from 'react';

import type { TrainingParticipant } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { del } from 'redux/actions/api';

import {
  ConfirmationModal,
  Icon,
  StrictlySanitizedHtml,
  Text,
} from 'components';

type Props = {
  participant: TrainingParticipant;
  setIsRemoveModalActive: (value: boolean) => void;
  sessionName: string | undefined | null;
  onAfterRemove: () => Promise<void>;
};

function RemoveFromSessionModal({
  participant,
  setIsRemoveModalActive,
  sessionName,
  onAfterRemove,
}: Props) {
  const dispatch = useAppDispatch();
  const removeFromSession = async () => {
    await dispatch(del(`training/participants/${participant.id}`));
    !!onAfterRemove && (await onAfterRemove());
  };

  const shouldCancelMeeting =
    !!participant.meeting && !participant.meeting.canceledAt;

  const participantFullName = participant.user.fullName;

  const bulletPoints = () => {
    const points: Array<string> = [];

    if (participant.request) {
      points.push(
        __(
          '<b>The initial request will be visible</b> in the "Training requests" tab.'
        )
      );
    }

    points.push(
      __('The funding will be deleted, which will <b>impact the budget</b>.')
    );

    if (shouldCancelMeeting) {
      points.push(
        __('%1 will receive an event cancellation.', participantFullName)
      );
    }

    return points;
  };

  return (
    <ConfirmationModal
      isDanger
      isActive
      onCancel={() => setIsRemoveModalActive(false)}
      onConfirm={removeFromSession}
      cancelLabel={__('Cancel')}
      confirmLabel={__('Remove from session')}
      title={__('Remove %1 from the session', participantFullName)}
      refreshContentOnOpening={true}
      inlineButtonIcon={
        shouldCancelMeeting ? (
          <Icon style={{ marginRight: 5 }} name="send" />
        ) : null
      }
    >
      <p>
        <StrictlySanitizedHtml
          html={`${__(
            'This <b>action will remove %1</b> from the training session',
            participantFullName
          )}${sessionName ? ` ${sessionName}.` : '.'}`}
        />
      </p>
      <ul className="list-disc p-5 pr-0">
        {bulletPoints().map((point, index) => (
          <li key={index}>
            <StrictlySanitizedHtml html={point} />
          </li>
        ))}
      </ul>
      <div className="mt-2 flex items-center">
        <Icon className="mr-2" name="warning" color="danger" />
        <div>
          <Text color="danger">{__('This action cannot be undone')}</Text>
        </div>
      </div>
    </ConfirmationModal>
  );
}

export default RemoveFromSessionModal;
