import React, { Fragment } from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { PaginationProps } from 'lib/dataLoader/pagination/types';
import type { WithDeprecatedPaginationProps } from 'lib/dataLoader/pagination/withDeprecatedPagination';
import type { PaginatedCollection, SkillsMatrix } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __, n__ } from 'helpers/i18n';

import { newDataLoader, withDeprecatedPagination } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  BoxList,
  BoxListItem,
  Column,
  Columns,
  DatatableWrapper,
  Divider,
  EmptyStateWithIcon,
  FetchContainer,
  MaterialIconName,
  Text,
} from 'components';

import MatrixListItem from './MatrixListItem';

type Props = WithDeprecatedPaginationProps & {
  areaId?: string;
  emptyStateProps: {
    iconName: MaterialIconName;
    title: string;
    description?: string;
  };
  displayTitleOnly?: boolean;
  fromCareerExplorer?: boolean;
};

type AfterPaginationProps = Props & PaginationProps;

type AfterDataLoaderProps = Props &
  AfterPaginationProps &
  DataLoaderProvidedProps & {
    matrixCollection: PaginatedCollection<SkillsMatrix>;
  };

function MatrixPaginatedList({
  matrixCollection,
  areaId,
  emptyStateProps,
  fromCareerExplorer,
  ...otherProps
}: AfterDataLoaderProps) {
  const { isFetching, hasError, refetchData } = otherProps;
  const filters = fromCareerExplorer
    ? undefined
    : [
        { param: 'all', label: __('All') },
        { param: 'published', label: __('Published') },
        { param: 'unpublished', label: __('Not published') },
      ];

  return (
    <Box>
      <DatatableWrapper
        collectionInfo={matrixCollection}
        filters={filters}
        {...otherProps}
        onUserFilterChange={undefined}
        totalCountRenderer={count =>
          n__('%1 skills matrix', '%1 skills matrices', count || 0)
        }
        renderNoRecord={() => (
          <EmptyStateWithIcon
            iconName={emptyStateProps.iconName}
            title={emptyStateProps.title}
            description={emptyStateProps.description}
            inBox={false}
          />
        )}
      >
        <BoxList>
          <BoxListItem>
            <Columns>
              <Column size={4}>
                <Text preset="14s6" color="light">
                  {__('Skills matrix title')}
                </Text>
              </Column>

              {!fromCareerExplorer && (
                <Fragment>
                  <Column size={3}>
                    <Text preset="14s6" color="light">
                      {__('Associated employees')}
                    </Text>
                  </Column>
                  <Column size={3}>
                    <Text preset="14s6" color="light">
                      {__('Status')}
                    </Text>
                  </Column>
                </Fragment>
              )}
            </Columns>
          </BoxListItem>
          <Divider style={{ marginTop: 0, marginBottom: 0 }} />
          <FetchContainer
            isFetching={isFetching}
            hasError={hasError}
            loadingStyle="overlay"
            render={() => (
              <div>
                {!!matrixCollection &&
                  matrixCollection.items.map(matrix => (
                    <MatrixListItem
                      key={matrix.id}
                      matrix={matrix}
                      fromCareerExplorer={!!fromCareerExplorer}
                      refetchData={refetchData}
                    />
                  ))}
              </div>
            )}
          />
        </BoxList>
      </DatatableWrapper>
    </Box>
  );
}

export default compose<React.ComponentType<Props>>(
  withDeprecatedPagination,
  newDataLoader({
    fetch: ({
      areaId,
      fromCareerExplorer,
      search,
      page,
      filter,
      countPerPage,
    }: AfterPaginationProps) =>
      get('/skills/matrices', {
        areaId,
        fromCareerExplorer,
        search,
        page,
        filter,
        countPerPage,
      }),
    hydrate: { matrixCollection: { items: {} } },
    cacheKey: ({ page, countPerPage, search, filter }) =>
      compositeKey({ page, countPerPage, search, filter }),
  })
)(MatrixPaginatedList);
