import type { OrganizationPlan } from 'models';

import { useAppSelector } from 'helpers/hooks';
import invariant from 'helpers/invariant';

import { getOrganizationPlan } from 'redux/reducers';

const useOrganizationPlan = (): OrganizationPlan => {
  return useAppSelector(state => {
    const organizationPlan = getOrganizationPlan(state);

    invariant(organizationPlan, 'Organization plan should be defined.');

    return organizationPlan;
  });
};

export default useOrganizationPlan;
