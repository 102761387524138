import React, { useContext, useEffect } from 'react';

import { TrainingRequestManagerDashboardStats } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import { DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import FetchContainer from 'components/FetchContainer';
import { ActiveFilters } from 'components/Filters/types';
import { Box, BoxVerticalSeparator } from 'components/containers';

import StatsGridItem from 'scenes/components/StatsGridItem';

import { DataContext } from '.';

type Props = { periodSlug: string; userFilters: ActiveFilters | '' };
type AfterDataLoaderProps = DataLoaderProvidedProps &
  Props & { stats: TrainingRequestManagerDashboardStats };

const Stats = ({
  stats,
  isFetching,
  hasError,
  refetchData,
}: AfterDataLoaderProps) => {
  const { totalCount, waitingForApprovalCount, approvedCount, refusedCount } =
    stats || {};
  const percentageFor = (num: number) =>
    totalCount ? Math.round((num / totalCount) * 100) : 0;

  const { refetchStats, setRefetchStats } = useContext(DataContext);

  useEffect(() => {
    if (refetchStats) {
      refetchData();
      setRefetchStats(false);
    }
  }, [refetchStats, refetchData, setRefetchStats]);

  return (
    <FetchContainer
      loadingStyle="overlay"
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        return (
          <div className="flex w-full mt-0 mb-4">
            <Box className="flex px-2 py-5 grow">
              <StatsGridItem
                title={__('Waiting for approval')}
                description={__(
                  'i.e. %1% of the requests',
                  percentageFor(waitingForApprovalCount)
                )}
                mainNumber={waitingForApprovalCount}
                backgroundColorClass="bg-basic-400"
              />
              <BoxVerticalSeparator className="mx-3" />
              <StatsGridItem
                title={__('Approved')}
                description={__(
                  'i.e. %1% of the requests',
                  percentageFor(approvedCount)
                )}
                mainNumber={approvedCount}
                backgroundColorClass="bg-green-400"
              />
              <BoxVerticalSeparator className="mx-3" />
              <StatsGridItem
                title={__('Refused')}
                description={__(
                  'i.e. %1% of the requests',
                  percentageFor(refusedCount)
                )}
                mainNumber={refusedCount}
                backgroundColorClass="bg-red-400"
              />
            </Box>
          </div>
        );
      }}
    />
  );
};

export default newDataLoader({
  fetch: ({ periodSlug, userFilters }: Props) =>
    get(`training/periods/${periodSlug}/requests/stats`, {
      userFilters,
    }),
  hydrate: { stats: {} },
  cacheKey: ({ periodSlug, userFilters }: Props) =>
    compositeKey({ periodSlug, userFilters }),
})(Stats);
