import React, { Fragment, useState } from 'react';

import type { TrainingSession } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { navigate, pathToTrainingSessionDetails } from 'helpers/navigation';

import { post } from 'redux/actions/api';

import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  Icon,
} from 'components';

import AddParticipantsToSessionModal from './AddParticipantsToSessionModal';
import BulkUpdateModal from './BulkUpdateModal';

type Props = {
  selectedRequestIds: Set<string>;
  resetSelectedRequestIds: () => void;
  triggerDataRefetch: () => void;
  periodSlug: string;
};

const BulkActions = ({
  selectedRequestIds,
  resetSelectedRequestIds,
  triggerDataRefetch,
  periodSlug,
}: Props) => {
  const dispatch = useAppDispatch();
  const createSession = async (
    requestIds: Array<string>
  ): Promise<TrainingSession> => {
    const response = await dispatch(
      post('training/sessions', { requestIds, periodSlug })
    );
    return response.response.body.data;
  };

  const [addToSessionModalIsActive, setAddToSessionModalIsActive] =
    useState(false);
  const [bulkUpdateModalIsActive, setBulkUpdateModalIsActive] = useState(false);

  const createNewSessionWithSelectedRequests = async () => {
    try {
      const session = await createSession(Array.from(selectedRequestIds));
      navigate(pathToTrainingSessionDetails(session.id));
    } catch (e: unknown) {
      // The errors are already handled by the API action
    }
  };

  return (
    <Fragment>
      <Dropdown align="left">
        <DropdownTrigger>
          <Button color="secondary" className="ml-0">
            {__('Actions')}
            <Icon name="keyboard_arrow_down" />
          </Button>
        </DropdownTrigger>
        <DropdownMenu>
          <DropdownMenuItem onClick={() => setBulkUpdateModalIsActive(true)}>
            {__('Change status')}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={createNewSessionWithSelectedRequests}>
            {__('Create a training session')}
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => setAddToSessionModalIsActive(true)}>
            {__('Add to a training session')}
          </DropdownMenuItem>
        </DropdownMenu>
      </Dropdown>
      {addToSessionModalIsActive && (
        <AddParticipantsToSessionModal
          requestIds={Array.from(selectedRequestIds)}
          onClose={() => setAddToSessionModalIsActive(false)}
          createNewSessionWithSelectedRequests={
            createNewSessionWithSelectedRequests
          }
        />
      )}
      {bulkUpdateModalIsActive && (
        <BulkUpdateModal
          periodSlug={periodSlug}
          requestIds={Array.from(selectedRequestIds)}
          onClose={() => setBulkUpdateModalIsActive(false)}
          onAfterUpdate={() => {
            resetSelectedRequestIds();
            triggerDataRefetch();
          }}
        />
      )}
    </Fragment>
  );
};

export default BulkActions;
