import React, { useEffect } from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { TrainingSession } from 'models';
import type { RouteComponentProps } from 'react-router-dom';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import {
  Match,
  OldPathToTrainingSessionIndex,
  pathToForecastTrainingPlan,
  pathToTrainingSessionDetails,
  pathToTrainingSessionParticipants,
  pathToValidatedTrainingPlan,
} from 'helpers/paths';
import {
  makeDocumentNonScrollable,
  makeDocumentScrollable,
} from 'helpers/scrollable';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  ContentContainer,
  DesignSystem,
  FetchContainer,
  PageHeader,
  Redirect,
  Route,
  SavingStatusLabel,
  Switch,
} from 'components';

import Cost from './Cost';
import Details from './Details';
import HeaderActions from './HeaderActions';
import Participants from './Participants';

type RouterProps = RouteComponentProps & {
  match: Match<{
    id: string;
  }>;
};

type Props = RouterProps;

type AfterConnectProps = Props &
  DataLoaderProvidedProps & {
    session: TrainingSession;
  };

const Session = ({
  match,
  session,
  isFetching,
  hasError,
}: AfterConnectProps) => {
  const { featureFlags } = useCurrentOrganization();
  const trainingPlansEnabled = featureFlags.includes('trainingPlans');

  useEffect(() => {
    makeDocumentNonScrollable();
    return function cleanup() {
      makeDocumentScrollable();
    };
  });

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      render={() => {
        const tabItems = [
          {
            label: __('Information'),
            to: pathToTrainingSessionDetails(session.id),
            testName: 'test-details-tab-button',
          },
          {
            label: __('Cost'),
            to: `${match.url}/cost`,
            testName: 'test-cost-tab-button',
          },
          {
            label: __('Participants'),
            to: pathToTrainingSessionParticipants(session.id),
            testName: 'test-participants-tab-button',
          },
        ];

        return (
          <div className="training-session-management">
            <DesignSystem version={2}>
              <PageHeader
                title={session.name || __('Untitled session')}
                withBackButton
                backButtonProps={{
                  target: trainingPlansEnabled
                    ? session.validated
                      ? pathToValidatedTrainingPlan(session.period.slug)
                      : pathToForecastTrainingPlan(session.period.slug)
                    : OldPathToTrainingSessionIndex(session.period.slug),
                }}
                statusInfo={
                  <SavingStatusLabel
                    failedText={() =>
                      __('The training session could not be updated')
                    }
                  />
                }
                tabItems={tabItems}
                actions={<HeaderActions session={session} />}
              />
            </DesignSystem>

            <div className="training-session-management-content">
              <ContentContainer>
                <Switch>
                  <Route
                    exact
                    path={`${match.path}/details`}
                    render={() => <Details session={session} />}
                  />
                  <Route
                    exact
                    path={`${match.path}/participants`}
                    render={() => <Participants session={session} />}
                  />
                  <Route
                    exact
                    path={`${match.path}/cost`}
                    render={() => <Cost session={session} />}
                  />
                  <Redirect
                    to={pathToTrainingSessionDetails(match.params.id)}
                  />
                </Switch>
              </ContentContainer>
            </div>
          </div>
        );
      }}
    />
  );
};

export default compose(
  newDataLoader({
    fetch: (ownProps: Props) =>
      get(`training/sessions/${ownProps.match.params.id}`),
    hydrate: {
      session: {
        fundingItems: {
          fundingSource: {},
        },
        abilities: {},
        period: {},
        creator: {},
        trainingCourse: { organism: {} },
        tags: {},
      },
    },
    cacheKey: ({ match }: Props) => match.params.id,
  })
)(Session) as React.ComponentType<Props>;
