import React from 'react';

type SuggestionsAccordionTitleAreaProps = {
  title: string;
  count: number;
};

const SuggestionsAccordionAreaTitle = ({
  title,
  count,
}: SuggestionsAccordionTitleAreaProps) => (
  <span>
    {title} <span className="text-text-soften ml-1">({count})</span>
  </span>
);

export default SuggestionsAccordionAreaTitle;
