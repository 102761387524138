import React, { useState } from 'react';

import { TrainingSessionValidationActionSummary } from 'models/TrainingSession';

import { __, n__ } from 'helpers/i18n';
import { formatMoney } from 'helpers/money';

import { Checkbox } from 'components';

import UnsafeRawHtml from 'scenes/components/review/UnsafeRawHtml';

const useSessionValidationSummaryContent = (
  summary: TrainingSessionValidationActionSummary
) => {
  const [checkedOptions, setCheckedOptions] = useState({
    approveRequests: false,
    withEmail: false,
  });

  const sideEffects = () => {
    const formattedComputedBudget = formatMoney(
      summary.computedBudgetCents,
      summary.computedBudgetCurrency
    );
    const formattedProvisionedBudget = formatMoney(
      summary.provisionedBudgetCents,
      summary.provisionedBudgetCurrency
    );

    const effects = [
      <UnsafeRawHtml
        html={
          summary.provisionedBudgetCents > 0
            ? __(
                'The <b>validated budget will be %1</b> out of %2',
                formattedComputedBudget,
                formattedProvisionedBudget
              )
            : __(
                'The <b>validated funding will be %1</b>',
                formattedComputedBudget
              )
        }
      />,
      __(
        "Participants won't be notified that the session is in the validated plan"
      ),
    ];

    if (summary.sessionsToBeCompletedCount > 0) {
      effects.push(
        n__(
          '%1 session will be set to "Completed" status because its end date is in the past. You can update the status from the session.',
          '%1 sessions will be set to "Completed" status because their end date is in the past. You can update the status from the session.',
          summary.sessionsToBeCompletedCount
        )
      );
    }

    return effects;
  };

  const renderOptions = () => {
    if (!summary.impactedRequestsCount) return null;

    return (
      <div>
        <ul className="mb-3">
          <li className="mb-1">
            <Checkbox
              isChecked={checkedOptions.approveRequests}
              onChange={approveRequests =>
                setCheckedOptions({
                  withEmail: approveRequests ? checkedOptions.withEmail : false,
                  approveRequests,
                })
              }
              label={n__(
                'Approve %1 linked training request',
                'Approve the %1 linked training requests',
                summary.impactedRequestsCount
              )}
            />
          </li>
          <li>
            <Checkbox
              isChecked={checkedOptions.withEmail}
              disabled={!checkedOptions.approveRequests}
              onChange={withEmail =>
                setCheckedOptions({ ...checkedOptions, withEmail })
              }
              label={__(
                'Inform the participants about their request approval',
                summary.impactedRequestsCount
              )}
            />
          </li>
        </ul>
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div>
        {n__(
          'Validate and move %1 session to the validated plan:',
          'Validate and move %1 sessions to the validated plan:',
          summary.sessionsCount
        )}
        <ul className=" list-disc mb-3">
          {sideEffects().map((effect, idx) => (
            <li className="mt-1 mx-5" key={idx}>
              {effect}
            </li>
          ))}
        </ul>
        {renderOptions()}
      </div>
    );
  };

  return {
    renderTitleText: () =>
      n__(
        'Move %1 training session to the validated plan?',
        'Move %1 training sessions to the validated plan?',
        summary.sessionsCount
      ),
    renderContent,
    renderValidateButtonText: () =>
      checkedOptions.approveRequests
        ? __('Move to the validated plan and approve')
        : n__(
            'Move %1 session to the validated plan',
            'Move %1 sessions to the validated plan',
            summary?.sessionsCount
          ),
    checkedOptions,
  };
};

export default useSessionValidationSummaryContent;
