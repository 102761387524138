import URL from 'core-js/features/url';

import queryString from 'helpers/queryString';

import { targetApi } from './constants';

export const createUrl = (
  resource: string,
  withApiPrefix: boolean = true,
  queryParams?: {}
) => {
  const hostname = targetApi.hostname;
  const defaultPort = targetApi.httpsEnabled ? '443' : '80';
  const port = targetApi.port || defaultPort;

  if (!hostname) {
    throw new Error(
      'Missing hostname in createUrl. Cannot create URL without hostname'
    );
  }

  const protocol = targetApi.httpsEnabled ? 'https:' : 'http:';
  const url = new URL(`${protocol}//${hostname}`);

  url.port = port;

  url.pathname = resource;
  if (withApiPrefix) {
    url.pathname = [targetApi.apiRootPathname, resource].join('/');
  }

  if (queryParams) {
    url.search = queryString.stringify(queryParams);
  }

  return url.toString();
};
