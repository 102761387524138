import type { UserFilterableFieldType } from 'models';

import { useAppSelector } from 'helpers/hooks';
import invariant from 'helpers/invariant';

import { getSurveyUserFilterableFields } from 'redux/reducers';

const useSurveyUserFilterableFields = (): Array<UserFilterableFieldType> => {
  return useAppSelector(state => {
    const surveyUserFilterableFields = getSurveyUserFilterableFields(state);

    invariant(
      surveyUserFilterableFields,
      'Organization survey user filtertable fields should be defined.'
    );

    return surveyUserFilterableFields;
  });
};

export default useSurveyUserFilterableFields;
