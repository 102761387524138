import React, { CSSProperties, ReactNode } from 'react';

import classNames from 'helpers/classNames';
import { __ } from 'helpers/i18n';

import {
  Button,
  Flex,
  Icon,
  type MaterialIconName,
  SupportLink,
} from 'components';

type Props = {
  children: ReactNode | null | undefined;
  kind?: 'dark' | 'primary' | 'info' | 'success' | 'warning' | 'danger';
  style?: CSSProperties;
  onDismiss?: () => void;
  className?: string;
  testClassName?: string;
  withContact: boolean;
  icon?: MaterialIconName;
  action?: {
    label: string;
    handleClick: () => void;
  };
};

function Notification({
  kind,
  children,
  style,
  onDismiss,
  withContact,
  className,
  testClassName,
  icon,
  action,
}: Props) {
  return (
    <div
      style={style}
      className={classNames('notification', testClassName, className, {
        [`is-${kind ? kind : ''}`]: !!kind,
      })}
    >
      <Flex className="gap-2">
        {icon && (
          <Icon
            style={{ marginTop: -2 }}
            name={icon}
            className="flex items-center"
          />
        )}
        {children && (
          <div
            className={`content-end flex items-center ${
              action ? 'justify-between w-full' : ''
            }`}
          >
            {children}
            {action && (
              <Button color="secondary" onClick={action.handleClick}>
                {action.label}
              </Button>
            )}
          </div>
        )}
      </Flex>
      {onDismiss ? <button onClick={onDismiss} className="delete" /> : ''}

      {withContact && (
        <p>
          {__(
            '%1 for more information.',
            <SupportLink>{__('Contact us')}</SupportLink>
          )}
        </p>
      )}
    </div>
  );
}

Notification.defaultProps = {
  withContact: false,
};

export default Notification;
