import React from 'react';

import type { TrainingPlanValidatedBudgetStats } from 'models';

import { __ } from 'helpers/i18n';
import { formatMoney } from 'helpers/money';

import { CompletionProgressBar, Divider, Text } from 'components';

type Props = {
  stats: TrainingPlanValidatedBudgetStats;
};

const OverallStats = ({ stats }: Props) => {
  const { provisionedAmountCents, validatedAmountCents, currency } = stats;

  const completion =
    validatedAmountCents &&
    provisionedAmountCents &&
    (validatedAmountCents * 100) / provisionedAmountCents;

  return (
    <div>
      <Text preset="13bs7">{__('Budget spent')}</Text>
      <div>
        <Text
          preset="18bs5"
          color="primary"
          testClassName="test-total-budget-spent-amount"
        >
          {formatMoney(validatedAmountCents, currency)}
        </Text>

        <Text
          preset="13bs7"
          color="light"
          testClassName="test-total-budget-amount"
        >
          &nbsp;
          {__('of')}
          &nbsp;
          {formatMoney(provisionedAmountCents, currency)}
        </Text>

        {<CompletionProgressBar completion={completion} />}
      </div>

      <Divider />
    </div>
  );
};

export default OverallStats;
