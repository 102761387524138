import * as React from 'react';

import type { InvitationStatus } from 'models';

import { useCurrentOrganization } from 'helpers/hooks';
import { __, date } from 'helpers/i18n';

import { Indicator, Tooltip } from 'components';

type Props = {
  userFirstName: string;
  invitationStatus: InvitationStatus;
  invitedAt: string | null;
  invitationAcceptedAt: string | null;
  email: string | null;
  useSSO: boolean | null;
};

function invitationStatusWithoutEmailNorSSO({
  invitationStatus,
  invitedAt,
  invitationAcceptedAt,
}: Props) {
  let content;

  if (invitationStatus === 'active') {
    content = __('Account activated on %1', date(invitationAcceptedAt));
  } else if (invitationStatus === 'invited') {
    content = __('Access code generated on %1', date(invitedAt));
  } else {
    content = __('Access code not generated yet');
  }

  return content;
}

function invitationStatusWithEmailOrSSO({
  userFirstName,
  invitationStatus,
  invitedAt,
  invitationAcceptedAt,
}: Props) {
  let content;

  if (invitationStatus === 'active') {
    content = __('Invitation accepted on %1', date(invitationAcceptedAt));
  } else if (invitationStatus === 'invited') {
    content = __('Invitation sent on %1', date(invitedAt));
  } else {
    content = [
      <div style={{ textAlign: 'center' }} key="content">
        {__('Not invited yet')}
        <br />
        {__(
          '%1 will receive an invitation when added to a review campaign',
          userFirstName
        )}
      </div>,
    ];
  }

  return content;
}

function UserListItemInvitationTooltip({ ...props }: Props) {
  const { usernameEnabled } = useCurrentOrganization();
  return (
    <Tooltip
      content={
        usernameEnabled && !props.email && !props.useSSO
          ? invitationStatusWithoutEmailNorSSO(props)
          : invitationStatusWithEmailOrSSO(props)
      }
    >
      <Indicator
        isSuccess={props.invitationStatus === 'active'}
        isWarning={props.invitationStatus === 'invited'}
        isDanger={props.invitationStatus === 'not_invited'}
        style={{ display: 'block' }}
      />
    </Tooltip>
  );
}

export default UserListItemInvitationTooltip;
