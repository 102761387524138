import React from 'react';

import type { BudgetItem } from 'models';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { WithSavingStatusRecorder } from 'components';
import MoneyInput from 'components/formElements/advancedElements/MoneyInput';

type Props = {
  budgetItem: BudgetItem | undefined | null;
  defaultCurrency: string;
  onUpdate: (budgetItem: Partial<BudgetItem>) => Promise<void>;
};

const BudgetItemAmountInput = ({
  budgetItem,
  defaultCurrency,
  onUpdate,
}: Props) => {
  const { featureFlags } = useCurrentOrganization();
  const trainingCurrenciesEnabled = featureFlags.includes('trainingCurrencies');

  const amount = budgetItem?.provisionedAmountCents;
  const currency = budgetItem?.provisionedAmountCurrency || defaultCurrency;

  return (
    <WithSavingStatusRecorder
      onChange={onUpdate}
      render={autoSavingOnChange => (
        <MoneyInput
          testClassName="test-budget-item-money-input"
          placeholder={__('Amount')}
          amount={amount}
          currency={currency}
          saveOnlyOnBlur
          onAmountChange={provisionedAmountCents => {
            autoSavingOnChange({ provisionedAmountCents });
          }}
          onCurrencyChange={
            trainingCurrenciesEnabled
              ? provisionedAmountCurrency =>
                  autoSavingOnChange({ provisionedAmountCurrency })
              : undefined
          }
        />
      )}
    />
  );
};

export default BudgetItemAmountInput;
