import React from 'react';

import type { OneOnOneReviewTemplate } from 'models';

import { useOrganizationPlan } from 'helpers/hooks';

import { BoxSeparator } from 'components';

import EnableSelfEvaluation from './components/EnableSelfEvaluation';
import ObjectiveSettings from './components/ObjectiveSettings';
import SignatureModule from './components/SignatureModule';
import SkillsCareerLevelModule from './components/SkillsCareerLevelModule';
import TemplateName from './components/TemplateName';
import TrainingRequestHistoryModule from './components/TrainingRequestHistoryModule';

type Props = {
  template: OneOnOneReviewTemplate;
  onFieldChange: (value: Partial<OneOnOneReviewTemplate>) => any;
  settingsDisabled: boolean;
};

function OneOnOneTemplateSettings({
  template,
  onFieldChange,
  settingsDisabled,
}: Props) {
  const { trainingModuleEnabled, skillsAndCareersEnabled } =
    useOrganizationPlan();

  return (
    <React.Fragment>
      <TemplateName
        name={template.actualName}
        onChange={name => onFieldChange({ name })}
        templateId={template.id}
      />

      <BoxSeparator style={{ margin: '8px 0' }} />

      <ObjectiveSettings
        objectivesReviewEnabled={template.objectivesReviewEnabled}
        objectivesDefinitionEnabled={template.objectivesDefinitionEnabled}
        disabled={settingsDisabled}
        onChange={onFieldChange}
        templateId={template.id}
      />
      {trainingModuleEnabled && (
        <React.Fragment>
          <BoxSeparator style={{ margin: '8px 0' }} />

          <TrainingRequestHistoryModule
            trainingRequestHistoryEnabled={
              template.trainingRequestHistoryEnabled
            }
            isDisabled={settingsDisabled}
            onChange={trainingRequestHistoryEnabled =>
              onFieldChange({ trainingRequestHistoryEnabled })
            }
            templateId={template.id}
          />
        </React.Fragment>
      )}

      <BoxSeparator style={{ margin: '8px 0' }} />

      {skillsAndCareersEnabled && (
        <React.Fragment>
          <SkillsCareerLevelModule
            skillsCareerLevelEnabled={template.skillsCareerLevelEnabled}
            disabled={settingsDisabled}
            onChange={skillsCareerLevelEnabled =>
              onFieldChange({ skillsCareerLevelEnabled })
            }
            templateId={template.id}
          />

          <BoxSeparator style={{ margin: '8px 0' }} />
        </React.Fragment>
      )}

      <SignatureModule
        signatureModuleEnabled={template.signatureModuleEnabled}
        disabled={settingsDisabled}
        onChange={signatureModuleEnabled =>
          onFieldChange({ signatureModuleEnabled })
        }
        templateId={template.id}
      />

      <BoxSeparator style={{ margin: '8px 0' }} />

      <EnableSelfEvaluation
        disabled={settingsDisabled}
        hasSelfEvaluationEnabled={template.hasSelfEvaluationEnabled}
        showRevieweeEvaluationToReviewers={
          template.showRevieweeEvaluationToReviewers
        }
        onChange={onFieldChange}
        interactionType="one_on_one"
        templateId={template.id}
      />
    </React.Fragment>
  );
}

export default OneOnOneTemplateSettings;
