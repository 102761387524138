import React, { Fragment, createContext, useState } from 'react';

import type { RouteComponentProps } from 'react-router-dom';

import { TrainingPeriod } from 'models/TrainingPeriod';

import { useAppDispatch, useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { navigate } from 'helpers/navigation';
import { pathToTrainingPeriodRequests } from 'helpers/paths';

import { post } from 'redux/actions/api';

import { Button, ContentContainer, Icon } from 'components';

import ExportModal from 'scenes/admin/components/ExportModal';
import TrainingPeriodPicker from 'scenes/components/TrainingPeriodPicker';
import TrainingRequestModal from 'scenes/components/TrainingRequestModal';

import PageHeader from '../components/PageHeader';
import OldRequestList from './OldRequestList';
import RequestList from './RequestList';
import RequestStats from './RequestStats';

type Props = RouteComponentProps<{ periodSlug: string }>;

type RefreshContextType = {
  shouldRefreshStats: boolean;
  setShouldRefreshStats: (value: boolean) => void;
  shouldRefetchRequestList: boolean;
  setShouldRefetchRequestList: (value: boolean) => void;
};
export const RefreshContext = createContext<RefreshContextType>(
  {} as RefreshContextType
);

function PeriodRequests({ match }: Props) {
  const [createModalIsActive, setCreateModalIsActive] = useState(false);
  const [exportModalIsActive, setExportModalIsActive] = useState(false);
  const [shouldRefreshStats, setShouldRefreshStats] = useState(false);
  const [shouldRefetchRequestList, setShouldRefetchRequestList] =
    useState(false);
  const { periodSlug } = match.params;

  const dispatch = useAppDispatch();

  // TODO: multifilters: remove these 2 lines when FF is enabled for everyone
  const { featureFlags } = useCurrentOrganization();
  const withUserMultiFilters = featureFlags.includes('userMultifilters');

  invariant(periodSlug, 'Training period must be defined.');

  const handleExport = async () => {
    await dispatch(post(`training/periods/${periodSlug}/requests/export`));

    setExportModalIsActive(true);
  };

  return (
    <Fragment>
      <PageHeader periodSlug={periodSlug} />

      <ContentContainer>
        <div className="flex flew-row justify-between">
          <div style={{ width: 157 }}>
            <TrainingPeriodPicker
              renderingStyle="popover"
              currentPeriodSlug={periodSlug}
              onChange={(period: TrainingPeriod) =>
                navigate(pathToTrainingPeriodRequests(period.slug))
              }
            />
          </div>
          <div>
            <Button color="secondary" onClick={handleExport} className="mr-2">
              <span>{__('Export all requests')}</span>
            </Button>
            <Button
              color="primary"
              onClick={() => setCreateModalIsActive(true)}
            >
              <Icon name="add" />
              <span>{__('Make a request')}</span>
            </Button>
          </div>
        </div>

        <RefreshContext.Provider
          value={{
            shouldRefreshStats,
            setShouldRefreshStats,
            shouldRefetchRequestList,
            setShouldRefetchRequestList,
          }}
        >
          <RequestStats currentPeriodSlug={periodSlug} />

          {withUserMultiFilters ? (
            <RequestList
              currentPeriodSlug={periodSlug}
              defaultFilter={{ waiting_for_approval: true }}
            />
          ) : (
            <OldRequestList
              currentPeriodSlug={periodSlug}
              paginationType="url"
              defaultPaginationParams={{
                filter: { waiting_for_approval: true },
              }}
            />
          )}

          {createModalIsActive && (
            <TrainingRequestModal
              isActive
              currentTrainingPeriodSlug={periodSlug}
              onClose={() => setCreateModalIsActive(false)}
              onAfterSubmit={() => {
                setShouldRefetchRequestList(true);
                setShouldRefreshStats(true);
              }}
            />
          )}
        </RefreshContext.Provider>

        {exportModalIsActive && (
          <ExportModal isActive onClose={() => setExportModalIsActive(false)} />
        )}
      </ContentContainer>
    </Fragment>
  );
}

export default PeriodRequests;
