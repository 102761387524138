import React, { CSSProperties, useContext, useEffect } from 'react';
import { compose } from 'redux';

import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { TrainingStats } from 'models';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import { newDataLoader } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import {
  Box,
  Column,
  Columns,
  FetchContainer,
  Number,
  Text,
  Title,
} from 'components';

import { RefreshContext } from '.';

type Props = {
  currentPeriodSlug: string;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    trainingStats: TrainingStats;
  };

const boxStyle: CSSProperties = {
  textAlign: 'center',
  padding: '20px',
};

const TrainingRequestStatsComponent = ({
  trainingStats,
  isFetching,
  hasError,
  refetchData,
}: AfterDataLoaderProps) => {
  const { shouldRefreshStats, setShouldRefreshStats } =
    useContext(RefreshContext);

  useEffect(() => {
    if (shouldRefreshStats) {
      refetchData();
      setShouldRefreshStats(false);
    }
  }, [shouldRefreshStats, refetchData, setShouldRefreshStats]);

  return (
    <FetchContainer
      isFetching={isFetching}
      hasError={hasError}
      loadingStyle="overlay"
      render={() => (
        <div>
          {!!trainingStats && (
            <Columns style={{ marginBottom: 0, marginTop: 0 }}>
              <Column size={3}>
                <Box style={boxStyle}>
                  <Title size={5} weight="semibold">
                    {trainingStats.waitingForApprovalCount}
                  </Title>
                  <Text transformation="lowercase">
                    {__('Waiting for approval')}
                  </Text>
                </Box>
              </Column>
              <Column size={3}>
                <Box style={boxStyle}>
                  <Title size={5} weight="semibold">
                    <Number
                      value={trainingStats.percentageApproved}
                      isPercentage
                    />
                  </Title>
                  <Text transformation="lowercase">
                    {__('Training approved')}
                  </Text>
                </Box>
              </Column>
            </Columns>
          )}
        </div>
      )}
    />
  );
};

export default compose(
  newDataLoader({
    fetch: ({ currentPeriodSlug }: Props) =>
      get(`training/periods/${currentPeriodSlug}/request_stats`),
    hydrate: {
      trainingStats: {},
    },
    cacheKey: ({ currentPeriodSlug }: Props) =>
      compositeKey({ currentPeriodSlug, viewId: 'trainingRequestStats' }),
  })
)(TrainingRequestStatsComponent) as React.ComponentType<Props>;
