import React from 'react';

import {
  AllValidatedFundingSourceStats,
  TrainingPlanValidatedBudgetStats,
} from 'models';

import FundingSourceDetails from './FundingSourceDetails';
import OverallStats from './OverallStats';

type Props = {
  stats: TrainingPlanValidatedBudgetStats;
  fundingSourcesStats: AllValidatedFundingSourceStats;
};
const ValidatedStats = ({ stats, fundingSourcesStats }: Props) => {
  return (
    <div>
      {!!stats.provisionedAmountCents && stats.provisionedAmountCents > 0 && (
        <OverallStats stats={stats} />
      )}

      {fundingSourcesStats.map(sourceStats => (
        <FundingSourceDetails key={sourceStats.id} stats={sourceStats} />
      ))}
    </div>
  );
};

export default ValidatedStats;
