import React, { useState } from 'react';

import type { FormErrors } from 'helpers/api';
import type { User } from 'models/User';

import { Occupation } from 'models/Occupation';

import { handleFormErrors } from 'helpers/api';
import { dateToDefaultDateStringFormat } from 'helpers/date';
import { useOrganizationSettings } from 'helpers/hooks';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { post, put } from 'redux/actions/api';

import {
  Button,
  Field,
  FieldError,
  Label,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Select,
} from 'components';
import MoneyInput from 'components/formElements/advancedElements/MoneyInput';

import DateInputs from './DateInputs';

type Props = {
  onClose: () => void;
  occupation?: Occupation;
  user: User;
  existingOccupationTitles: Array<string>;
  refetchOccupations: () => void;
};

type OccupationParams = {
  title: string;
  startDate: Date | null;
  endDate: Date | null;
  fixedRemunerationCents: number | null;
  fixedRemunerationCurrency: string;
  variableRemunerationCents: number | null;
  variableRemunerationCurrency: string;
};

const OccupationManagementModal = ({
  onClose,
  occupation,
  user,
  existingOccupationTitles,
  refetchOccupations,
}: Props) => {
  const { defaultCurrencyIsoCode } = useOrganizationSettings();
  const dispatch = useAppDispatch();
  const [elementData, setElementData] = useState<OccupationParams>({
    title: occupation?.title || '',
    startDate: occupation?.startDate ? new Date(occupation.startDate) : null,
    endDate: occupation?.endDate ? new Date(occupation.endDate) : null,
    fixedRemunerationCents: occupation?.fixedRemunerationCents || null,
    fixedRemunerationCurrency:
      occupation?.fixedRemunerationCurrency || defaultCurrencyIsoCode,
    variableRemunerationCents: occupation?.variableRemunerationCents || null,
    variableRemunerationCurrency:
      occupation?.variableRemunerationCurrency || defaultCurrencyIsoCode,
  });

  const [errors, setErrors] = useState<FormErrors>({});

  const saveOccupation = () =>
    handleFormErrors(async () => {
      const params = {
        ...elementData,
        startDate: dateToDefaultDateStringFormat(elementData.startDate),
        endDate: dateToDefaultDateStringFormat(elementData.endDate),
      };

      await dispatch(
        occupation
          ? put(`users/${user.id}/occupations/${occupation.id}`, params)
          : post(`users/${user.id}/occupations`, params)
      );

      if (!occupation) {
        refetchOccupations();
      }
      onClose();
    }, setErrors);

  const titleOptions = existingOccupationTitles.map(title => ({
    value: title,
    label: title,
  }));

  return (
    <ModalCard onClose={onClose} isActive isLarge>
      <ModalCardHead>
        <ModalCardTitle>
          {occupation
            ? __("Update %1's job", user.fullName)
            : __('Add a job for %1', user.fullName)}
        </ModalCardTitle>
      </ModalCardHead>

      <ModalCardBody overflowVisible>
        <Field>
          <Label>{__('Job title')}</Label>
          <Select
            value={
              elementData.title
                ? { value: elementData.title, label: elementData.title }
                : null
            }
            options={titleOptions}
            placeholder={__('Enter a job title')}
            onChange={titleOption =>
              setElementData({
                ...elementData,
                title: titleOption?.value || '',
              })
            }
            formatCreateLabel={(newLabel: string) =>
              __('Create "%1"', newLabel)
            }
            isCreatable
          />
          <FieldError>{errors?.title}</FieldError>
        </Field>
        <DateInputs
          startDate={elementData.startDate}
          endDate={elementData.endDate}
          setStartDate={startDate =>
            setElementData({ ...elementData, startDate })
          }
          setEndDate={endDate => setElementData({ ...elementData, endDate })}
          errors={errors}
        />
        <div className="flex w-100 gap-4">
          <Field className="flex-1">
            <Label>{__('Fixed annual remuneration')}</Label>
            <MoneyInput
              amount={elementData.fixedRemunerationCents}
              onAmountChange={fixedRemunerationCents =>
                setElementData({ ...elementData, fixedRemunerationCents })
              }
              placeholder={__('Enter an amount')}
              currency={elementData.fixedRemunerationCurrency}
              onCurrencyChange={currency =>
                setElementData({
                  ...elementData,
                  fixedRemunerationCurrency: currency,
                  variableRemunerationCurrency: currency,
                })
              }
            />
            <FieldError>{errors?.fixedRemuneration}</FieldError>
          </Field>
          <Field className="flex-1">
            <Label>{__('Variable annual remuneration')}</Label>
            <MoneyInput
              amount={elementData.variableRemunerationCents}
              onAmountChange={variableRemunerationCents =>
                setElementData({ ...elementData, variableRemunerationCents })
              }
              placeholder={__('Enter an amount')}
              currency={elementData.variableRemunerationCurrency}
              onCurrencyChange={currency =>
                setElementData({
                  ...elementData,
                  variableRemunerationCurrency: currency,
                  fixedRemunerationCurrency: currency,
                })
              }
            />
            <FieldError>{errors?.variableRemuneration}</FieldError>
          </Field>
        </div>
      </ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Cancel')}
        </Button>
        <Button
          color="primary"
          disabled={
            elementData.title.length === 0 || elementData.startDate === null
          }
          onClick={saveOccupation}
        >
          {occupation ? __('Update the job') : __('Add the job')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default OccupationManagementModal;
