import { filter as _filter, toArray } from 'lodash';

import type { DataState } from 'redux/reducers/DataStateType';

import hydrate from './hydrate';

type ResourceType = string; // team, organization, user, ...
// TSFIXME: type this
type Filter = any;

export type StoreDataGetterOptions = {
  resourceType: ResourceType;
  id?: string;
  filter?: Filter;
};

function getData(
  dataState: DataState,
  storeDataGetterOptions: StoreDataGetterOptions
) {
  const { id, resourceType, filter } = storeDataGetterOptions;
  const resources = dataState[resourceType]
    ? dataState[resourceType].records
    : null;

  if (id) {
    return resources ? resources[id.toString()] : null;
  }

  let data = resources ? toArray(resources) : [];

  if (filter) {
    data = _filter(data, filter);
  }

  return data;
}

export default function hydrateFromStore(
  dataState: DataState,
  storeDataGetterOptions: StoreDataGetterOptions,
  relationshipsTree: {}
): any {
  const data = getData(dataState, storeDataGetterOptions);
  const relationshipsSubtree = (
    Object.values(relationshipsTree) as Array<{}>
  )[0];
  return hydrate(dataState, data, relationshipsSubtree);
}
