import React, { Fragment, useState } from 'react';

import type { TrainingSession } from 'models';

import can from 'helpers/can';
import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import {
  OldPathToTrainingSessionIndex,
  navigate,
  pathToForecastTrainingPlan,
  pathToValidatedTrainingPlan,
} from 'helpers/navigation';

import {
  Button,
  Dropdown,
  DropdownMenu,
  DropdownMenuItem,
  DropdownTrigger,
  Icon,
  Tooltip,
} from 'components';

import DeleteSessionModal from '../../components/DeleteSessionModal';
import MoveToForecastModal from '../../components/MoveToForecastModal';
import ValidateSessionModal from '../../components/ValidateSessionModal';
import AddParticipantsModal from '../components/AddParticipantsModal';

type Props = {
  session: TrainingSession;
};

const GeneralActions = ({ session }: Props) => {
  const [isDeleteSessionModalVisible, setIsDeleteSessionModalVisible] =
    useState(false);
  const [isMoveToForecastModalVisible, setIsMoveToForecastModalVisible] =
    useState(false);
  const [isValidateSessionModalActive, setIsValidateSessionModalActive] =
    useState(false);
  const [isAddParticipantsModalActive, setIsAddParticipantsModalActive] =
    useState(false);
  const { featureFlags } = useCurrentOrganization();

  const onAfterDeleteSession = () => {
    if (!featureFlags.includes('trainingPlans')) {
      navigate(OldPathToTrainingSessionIndex(session.period.slug));
      return;
    }

    session.validated
      ? navigate(pathToValidatedTrainingPlan(session.period.slug))
      : navigate(pathToForecastTrainingPlan(session.period.slug));
  };

  const putBackToForecastPlanItem = () => {
    const itemDisabled = session.status === 'completed';

    return (
      <Tooltip
        content={__(
          'A session cannot be moved to the forecast plan if it is completed.'
        )}
        enabled={itemDisabled}
      >
        <DropdownMenuItem
          disabled={itemDisabled}
          onClick={() => setIsMoveToForecastModalVisible(true)}
        >
          {__('Move to the forecast plan')}
        </DropdownMenuItem>
      </Tooltip>
    );
  };

  const validateItem = () => (
    <DropdownMenuItem onClick={() => setIsValidateSessionModalActive(true)}>
      {__('Move to the validated plan')}
    </DropdownMenuItem>
  );

  const updatePlanAction = () =>
    session.validated ? putBackToForecastPlanItem() : validateItem();
  const addParticipantsAction = () => (
    <DropdownMenuItem onClick={() => setIsAddParticipantsModalActive(true)}>
      {__('Add participants')}
    </DropdownMenuItem>
  );
  const deleteSessionAction = () => (
    <DropdownMenuItem
      onClick={() => setIsDeleteSessionModalVisible(true)}
      isDanger
    >
      {__('Delete session')}
    </DropdownMenuItem>
  );

  const actions = [
    {
      enabled:
        featureFlags.includes('trainingPlans') &&
        can({ perform: 'update', on: session }),
      render: updatePlanAction,
    },
    {
      enabled:
        featureFlags.includes('addParticipantsToSession') &&
        can({ perform: 'add_participants', on: session }) &&
        !window.location.pathname.endsWith('participants'),
      render: addParticipantsAction,
    },
    {
      enabled: can({ perform: 'destroy', on: session }),
      render: deleteSessionAction,
    },
  ].filter(({ enabled }) => enabled);

  if (actions.length === 0) {
    return null;
  }

  return (
    <Fragment>
      <Dropdown align="left">
        <DropdownTrigger>
          <Button color="secondary" className="ml-0">
            {__('Actions')}
            <Icon name="keyboard_arrow_down" />
          </Button>
        </DropdownTrigger>
        <DropdownMenu>
          {actions.map((action, idx) => (
            <div key={idx}>{action.render()}</div>
          ))}
        </DropdownMenu>
      </Dropdown>
      {isDeleteSessionModalVisible && (
        <DeleteSessionModal
          session={session}
          onClose={() => setIsDeleteSessionModalVisible(false)}
          onAfterDelete={onAfterDeleteSession}
        />
      )}
      {isMoveToForecastModalVisible && (
        <MoveToForecastModal
          session={session}
          onClose={() => setIsMoveToForecastModalVisible(false)}
        />
      )}
      {isValidateSessionModalActive && (
        <ValidateSessionModal
          session={session}
          onClose={() => setIsValidateSessionModalActive(false)}
        />
      )}
      {isAddParticipantsModalActive && (
        <AddParticipantsModal
          session={session}
          onClose={() => setIsAddParticipantsModalActive(false)}
        />
      )}
    </Fragment>
  );
};

export default GeneralActions;
