import React, { CSSProperties } from 'react';

import type { OrganizationSync } from 'models';

import can from 'helpers/can';
import { useCurrentOrganization } from 'helpers/hooks';
import { __, dateCalendar } from 'helpers/i18n';
import { pathToSyncDetails } from 'helpers/paths';

import { Link, Text } from 'components';

type Props = {
  organizationSync: OrganizationSync | undefined | null;
  style: CSSProperties;
};

export default function OrganizationSyncState({
  organizationSync,
  style,
}: Props) {
  const organization = useCurrentOrganization();

  if (!organizationSync) return null;

  const canAccessUserSync = can({
    perform: 'show_user_sync_details',
    on: organization,
  });

  const LinkToOrgSync = canAccessUserSync ? (
    <span>
      {' ('}
      <Link
        to={pathToSyncDetails(organizationSync.id)}
        style={{ textDecoration: 'underline' }}
      >
        {__('see details')}
      </Link>
      )
    </span>
  ) : null;

  switch (organizationSync.status) {
    case 'in_progress':
      return (
        <div style={style}>
          <Text color="info">
            {__(
              'Synchronization ongoing, started %1',
              dateCalendar(organizationSync.startedAt).toLowerCase()
            )}
            {LinkToOrgSync}
          </Text>
        </div>
      );
    case 'completed':
      return (
        <div style={style}>
          <Text color="info">
            {__(
              'Synchronized %1',
              dateCalendar(organizationSync.endedAt).toLowerCase()
            )}
            {LinkToOrgSync}
          </Text>
        </div>
      );
    case 'failed':
      return (
        <div style={style}>
          <Text color="danger">
            {__(
              'Last synchronization failed %1',
              dateCalendar(organizationSync.createdAt).toLowerCase()
            )}
            {LinkToOrgSync}
          </Text>
        </div>
      );
    default:
      return null;
  }
}
