import React, { Fragment } from 'react';

import type { UserFilterChangeMethod } from 'lib/dataLoader/pagination/types';

import { SurveyCampaign, UserFilterSegment } from 'models';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { navigate } from 'helpers/navigation';
import { pathToSurveyCampaignConversations } from 'helpers/paths';

import { EmptyStateWithIcon } from 'components';

import Header from '../components/Header';
import List from './List';

type Props = {
  campaign: SurveyCampaign;
  selectedPeriodId: string;
  userFilter: UserFilterSegment | null;
  onUserFilterChange: UserFilterChangeMethod;
};

const Conversations = ({
  campaign,
  selectedPeriodId,
  userFilter,
  onUserFilterChange,
}: Props) => {
  const { featureFlags } = useCurrentOrganization();
  const surveyFilteringDisabled = featureFlags.includes(
    'surveyFilteringDisabled'
  );
  const segment = surveyFilteringDisabled ? null : userFilter;
  const selectedPeriod = campaign.pollingPeriods.find(
    period => period.id === selectedPeriodId
  );
  const handleSelectedPeriodChange = periodId => {
    navigate(pathToSurveyCampaignConversations(campaign.id, periodId));
  };

  if (campaign.pollingPeriods.length === 0) {
    return (
      <EmptyStateWithIcon
        iconName="comment"
        title={__(
          "You don't have any follow-up conversations yet for this survey."
        )}
      />
    );
  }

  return (
    <Fragment>
      <Header
        campaign={campaign}
        selectedPeriod={selectedPeriod}
        handleSelectedPeriodChange={handleSelectedPeriodChange}
        userFilter={segment}
        onUserFilterChange={onUserFilterChange}
      />
      <List
        campaign={campaign}
        periodId={selectedPeriod?.id}
        segment={segment}
        additionalQueryParams={{
          sort: { lastMessageAt: 'desc' },
        }}
      />
    </Fragment>
  );
};

export default Conversations;
