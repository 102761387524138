import { omit } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';

import type { Email, InteractionType } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { handleFormErrors } from 'helpers/api';
import classNames from 'helpers/classNames';
import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';

import { put } from 'redux/actions/api';

import { AutoSave, EditorHelperText, Input, Textarea, Title } from 'components';
import { Editor as RichTextEditor } from 'components/TrixEditor/TrixEditor';
import EditorHowTo from 'components/formElements/inlineEditables/EditorHowTo';

import reviewCycleVariables from './reviewCycleVariables';

type Props = {
  email: Email;
  interactionType: InteractionType;
  handleEmailValidationChange: (id: string, valid: boolean) => void;
  onEmailUpdated: (email: Email) => void;
  testClassName: string;
};

type AfterConnectProps = Props & {
  updateEmail: (
    params: {
      body?: string;
      subject?: string;
    },
    email: Email
  ) => Promise<void>;
};

type Error = {
  subject?: any;
  body?: any;
};

const MAX_SUBJECT_LENGTH = 90;
const MAX_BODY_LENGTH = 800;

function EmailEditor({
  email,
  interactionType,
  handleEmailValidationChange,
  onEmailUpdated,
  updateEmail,
  testClassName,
}: AfterConnectProps) {
  const { reviewCycleId, recipientType, locale, id: emailId } = email;
  const [subject, setSubject] = React.useState(email.subject);
  const [htmlBody, setHtmlBody] = React.useState(email.body);
  const [editor, setEditor] = React.useState<RichTextEditor | null>(null);
  const [errors, setErrors] = React.useState<Error>({});

  React.useEffect(() => {
    setSubject(email.subject);
  }, [email.subject]);

  React.useEffect(() => {
    if (!editor) return;
    const selectedRange = editor.getSelectedRange();
    // setting the body with setBody doesn't update the editor itself
    // @ts-ignore TSFIXME
    editor.element.value = email.body;
    editor.setSelectedRange(selectedRange);
  }, [email.body, editor]);

  // max_length errors are not handled in the API. we make sure to show them here
  const handleFormLocalErrors = ({
    subjectLength,
    bodyLength,
  }: {
    subjectLength?: number;
    bodyLength?: number;
  }) => {
    if (subjectLength && subjectLength >= MAX_SUBJECT_LENGTH) {
      setErrors({
        ...errors,
        subject: __(
          'The subject of the email should not exceed %1 characters',
          MAX_SUBJECT_LENGTH
        ),
      });
    }
    if (bodyLength && bodyLength >= MAX_BODY_LENGTH) {
      setErrors({
        ...errors,
        body: __(
          'The content of the email should not exceed %1 characters',
          MAX_BODY_LENGTH
        ),
      });
    }
  };

  const clearError = key => setErrors(omit(errors, key));

  const getEditorTextLength = () =>
    editor ? editor.getDocument().toString().length - 1 : 0;

  const persistEmailChanges = async () => {
    if (htmlBody === email.body && subject === email.subject) return;

    handleFormErrors(
      () => updateEmail({ subject, body: htmlBody }, email),
      setErrors
    );
    handleFormLocalErrors({
      subjectLength: subject.length,
      bodyLength: getEditorTextLength(),
    });
    onEmailUpdated({ ...email, body: htmlBody, subject, modified: true });
  };

  React.useEffect(() => {
    handleEmailValidationChange(emailId, Object.values(errors).length === 0);
  }, [emailId, errors, handleEmailValidationChange]);

  const getMergeTags = () => {
    const tags = reviewCycleVariables(email.recipientType, interactionType).map(
      ({ name, variable }) => ({ name, tag: variable })
    );

    return [{ trigger: '[', tags }];
  };

  return (
    <div className={classNames('email-editor', testClassName)}>
      <Title style={{ position: 'relative' }} size={6}>
        {__('Email object')}

        <EditorHelperText
          maxLength={MAX_SUBJECT_LENGTH}
          length={subject.length}
          error={errors.subject}
          className="email-edition"
        />
      </Title>
      <AutoSave
        fieldUid={compositeKey({
          reviewCycleId,
          recipientType,
          locale,
          type: 'subject',
        })}
        onChange={(value: string) => {
          if (value.length <= MAX_SUBJECT_LENGTH) {
            setSubject(value);
            clearError('subject');
          } else handleFormLocalErrors({ subjectLength: value.length });
        }}
        doPersist={persistEmailChanges}
        render={autoSavingOnChange => (
          <Input
            className={classNames('test-email-subject-input', {
              'has-error': errors.subject,
            })}
            value={subject}
            onChange={autoSavingOnChange}
          />
        )}
      />

      <Title style={{ position: 'relative' }} size={6}>
        {__('Email content')}
        <EditorHelperText
          maxLength={MAX_BODY_LENGTH}
          length={getEditorTextLength()}
          error={errors.body}
          className="email-edition"
        />
      </Title>
      <AutoSave
        fieldUid={compositeKey({
          reviewCycleId,
          recipientType,
          locale,
          type: 'body',
        })}
        onChange={(value: string) => {
          const textLength = getEditorTextLength();
          if (textLength <= MAX_BODY_LENGTH) {
            setHtmlBody(value);
            clearError('body');
          } else {
            if (editor) {
              editor.setSelectedRange([MAX_BODY_LENGTH, textLength]);
              editor.deleteInDirection('forward');
            }
            handleFormLocalErrors({ bodyLength: textLength });
          }
        }}
        doPersist={persistEmailChanges}
        render={autoSavingOnChange => (
          <Textarea
            richText
            richTextOptions={{
              onEditorReady: setEditor,
              allowImageUpload: false,
            }}
            value={htmlBody}
            mergeTags={getMergeTags()}
            onChange={autoSavingOnChange}
            className={classNames('email-editor', 'test-email-content-input', {
              'has-error': errors.body,
            })}
          />
        )}
      />
      <EditorHowTo />
    </div>
  );
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    updateEmail: (params: Partial<Email>, email: Email) => {
      const { reviewCycleId, id } = email;
      return dispatch(
        put(`review_cycles/${reviewCycleId}/emails/${id}`, {
          email: {
            id,
            ...params,
          },
        })
      );
    },
  };
}

export default connect(
  null,
  // @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
  mapDispatchToProps
)(EmailEditor) as React.ComponentType<Props>;
