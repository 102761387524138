import React from 'react';

import { __ } from 'helpers/i18n';

import { Select } from 'components';

import { EditableFieldProps } from '../EditableField';
import SyncedField from '../SyncedField';

type GenderOption = {
  label: string;
  value: 'male' | 'female' | 'other' | null;
};

const genderOptions = () => [
  { value: 'male', label: __('Male') },
  { value: 'female', label: __('Female') },
  { value: 'other', label: __('Other') },
];

const GenderField = ({
  value,
  onChange,
  fieldSynced,
  syncedFieldOptions,
}: EditableFieldProps) => {
  const onGenderChange = (option: GenderOption): void => {
    onChange(option?.value);
  };

  return fieldSynced ? (
    <SyncedField lockedFieldValue={value} {...syncedFieldOptions} />
  ) : (
    <Select
      value={genderOptions().find(option => option.value === value) || null}
      options={genderOptions()}
      onChange={value => onGenderChange(value as GenderOption)}
      isClearable
    />
  );
};

export default GenderField;
