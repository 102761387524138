import React, { ReactNode, useState } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { DataLoaderProvidedProps, PaginationProps } from 'lib/dataLoader';
import type {
  CollectionInfo,
  ParticipantEligibility,
  UserFilterSegment,
} from 'models';
import type { AppDispatch } from 'redux/actions/types';

import compositeKey from 'helpers/compositeKey';
import { __ } from 'helpers/i18n';
import { assertDefined } from 'helpers/invariant';

import { newDataLoader } from 'lib/dataLoader';
import withStatePagination from 'lib/dataLoader/pagination/StatePaginationFactory';
import { del, get, post } from 'redux/actions/api';

import { DatatableWrapper, FetchContainer, Testable } from 'components';

import Header from './Header';
import ParticipantList from './ParticipantList';

type Props = {
  fetchUrl: string;
  updateUrl: string;
  additionalUpdateParams?: { [key: string]: number };
  participantsCount: number;
  participantListStyle?: {};
  showBulkActions: boolean;
  renderAfter?: (refetch: () => Promise<any>) => ReactNode;
  renderImportButton?: (
    refetchData: () => Promise<void>,
    setReport: (report: ReactNode) => void,
    setError: (report: ReactNode) => void
  ) => ReactNode;
};

type AfterPaginateProps = Props & PaginationProps;

type ParticipantEligilibityCollection = {
  items: Array<ParticipantEligibility>;
} & CollectionInfo;

type AfterDataloaderProps = AfterPaginateProps &
  DataLoaderProvidedProps & {
    participantEligibilityCollection: ParticipantEligilibityCollection;
  };

type AfterConnectProps = AfterDataloaderProps & {
  addEveryone: () => Promise<void>;
  removeEveryone: () => Promise<void>;
  removeParticipant: (participantId: string) => Promise<void>;
  addParticipant: (participantId: string) => Promise<void>;
};

const ParticipantsChooser: React.FC<AfterConnectProps> = ({
  participantEligibilityCollection,
  isFetching,
  hasError,
  refetchData,
  addEveryone,
  removeEveryone,
  removeParticipant,
  addParticipant,
  showBulkActions = false,
  participantsCount,
  renderAfter,
  search,
  onSearchChange,
  filter,
  onFilterChange,
  countPerPage,
  page,
  getNextPage,
  getPreviousPage,
  onUserFilterChange,
  renderImportButton,
}) => {
  const [userFilter, setUserFilter] = useState<UserFilterSegment | null>(null);

  const handleUserFilterChange = (
    filter: UserFilterSegment | null | undefined
  ) => {
    setUserFilter(filter || null);
    onUserFilterChange?.(filter);
  };

  if ((isFetching && !participantEligibilityCollection) || hasError) {
    return <FetchContainer isFetching={isFetching} hasError={hasError} />;
  }

  const { items, ...collectionInfo } = participantEligibilityCollection;

  return (
    <Testable name="test-participants-chooser">
      <div className="participants-chooser">
        <DatatableWrapper
          collectionInfo={collectionInfo}
          isFetching={isFetching}
          hasError={hasError}
          search={search}
          countPerPage={countPerPage}
          onSearchChange={onSearchChange}
          filters={[
            { param: 'all', label: __('Everyone') },
            { param: 'selected', label: __('Selected') },
            { param: 'not_selected', label: __('Not selected') },
          ]}
          onFilterChange={onFilterChange}
          filter={filter}
          onUserFilterChange={handleUserFilterChange}
          userFilter={userFilter}
          page={page}
          getNextPage={getNextPage}
          getPreviousPage={getPreviousPage}
          showTotalRecordCount={false}
          renderHeader={({
            search,
            onSearchChange,
            filters,
            activeFilter,
            onFilterChange,
            userFilter,
          }) => (
            <Header
              search={search}
              onSearchChange={assertDefined(
                onSearchChange,
                'onSearchChange should be defined'
              )}
              filters={filters}
              activeFilter={activeFilter}
              onFilterChange={onFilterChange}
              userFilter={userFilter}
              onUserFilterChange={handleUserFilterChange}
              onAddEveryoneClick={showBulkActions ? addEveryone : undefined}
              onRemoveEveryoneClick={
                showBulkActions ? removeEveryone : undefined
              }
              participantCount={participantsCount}
              participantEligibilityCount={collectionInfo.totalRecordCount}
              renderImportButton={(setReport, setError) =>
                renderImportButton?.(refetchData, setReport, setError)
              }
            />
          )}
        >
          <ParticipantList
            eligibilities={items}
            onRemoveParticipant={removeParticipant}
            onAddParticipant={addParticipant}
            isFetching={isFetching}
            hasError={hasError}
          />
        </DatatableWrapper>
        {renderAfter && renderAfter(refetchData)}
      </div>
    </Testable>
  );
};

const mapDispatchToProps = (
  dispatch: AppDispatch,
  {
    userFilter,
    updateUrl,
    refetchData,
    additionalUpdateParams,
  }: AfterDataloaderProps
): Omit<AfterConnectProps, keyof AfterDataloaderProps> => {
  const filterParams = userFilter
    ? {
        filterType: userFilter.filterType,
        filterValue: userFilter.value,
      }
    : { filterType: 'everyone' };

  return {
    addParticipant: async (participantId: string) => {
      await dispatch(
        post(updateUrl, {
          participantId,
          ...additionalUpdateParams,
        })
      );
      await refetchData();
    },
    removeParticipant: async (participantId: string) => {
      await dispatch(
        del(updateUrl, {
          participantId,
          ...additionalUpdateParams,
        })
      );
      await refetchData();
    },
    addEveryone: async () => {
      await dispatch(
        post(updateUrl, { ...filterParams, ...additionalUpdateParams })
      );
      await refetchData();
    },
    removeEveryone: async () => {
      await dispatch(
        del(updateUrl, { ...filterParams, ...additionalUpdateParams })
      );
      await refetchData();
    },
  };
};

export default compose(
  withStatePagination({
    defaultPaginationParams: () => ({ filter: { all: true }, countPerPage: 6 }),
  }),
  newDataLoader({
    fetch: ({
      page,
      countPerPage,
      search,
      filter,
      fetchUrl,
      userFilter,
    }: AfterPaginateProps) =>
      get(fetchUrl, {
        page,
        countPerPage,
        search,
        filter,
        userFilter,
      }),
    hydrate: {
      participantEligibilityCollection: {
        userFilter: {},
        items: {
          reviewee: {},
        },
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      search,
      filter,
      userFilter,
    }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, search, filter, userFilter }),
  }),
  // @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
  connect(null, mapDispatchToProps)
)(ParticipantsChooser) as React.ComponentType<Props>;
