import React from 'react';

type Props = {
  className?: string;
};

const AIIcon = ({ className }: Props) => (
  <svg
    viewBox="0 0 14 14"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    // SVG aliasing on low DPI screens does not align well on pixels like font icons
    // This SVG coordinates are pixel perfect for 14px
    style={{ width: '14px', height: '14px' }}
  >
    <path d="M11.0545 5.9455C11.9893 6.9316 14 7.5 14 7.5s-2.0134.5659-2.9455 1.5545C10.1942 9.967 9.5 12 9.5 12s-.54-1.9974-1.5545-2.9455C7.05 8.218 5 7.5 5 7.5s2.0341-.6719 2.9455-1.556C8.8889 5.0285 9.5 3 9.5 3s.712 2.0566 1.5545 2.9455ZM4.709 2.291C5.4362 3.058 7 3.5 7 3.5s-1.566.4401-2.291 1.209C4.04 5.4189 3.5 7 3.5 7s-.42-1.5535-1.209-2.291C1.5944 4.0585 0 3.5 0 3.5s1.582-.5226 2.291-1.2103C3.0246 1.5777 3.5 0 3.5 0s.5537 1.5996 1.209 2.291ZM5.3636 10.6364C5.883 11.1842 7 11.5 7 11.5s-1.1185.3144-1.6364.8636C4.8856 12.8706 4.5 14 4.5 14s-.3-1.1097-.8636-1.6364C3.1389 11.899 2 11.5 2 11.5s1.13-.3733 1.6364-.8645C4.1604 10.127 4.5 9 4.5 9s.3955 1.1426.8636 1.6364Z" />
  </svg>
);

export default AIIcon;
