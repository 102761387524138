import React from 'react';

import type { RadarChart as RadarChartType } from 'models';

import colors from 'styles/colors';

import { RadarChart } from 'components';

type Props = {
  radarChart: RadarChartType;
  onCriterionClick?: (criterion: string) => void;
};

const MultipleScaleQuestionRadarChart = ({
  radarChart,
  onCriterionClick,
}: Props) => {
  const colorsMap = {
    reviewee: colors.radarChart.reviewee,
    reviewer: colors.radarChart.reviewer,
    additionalReviewer: colors.radarChart.additionalReviewer,
  };

  const radarOptions = Object.entries(radarChart.legend).map(([key, name]) => ({
    dataKey: key,
    name,
    color: colorsMap[key],
  }));

  return (
    <RadarChart
      axisLabels={radarChart.orderedPolarAxisLabels}
      data={radarChart.data}
      axisDataKey="label"
      onCriterionClick={onCriterionClick}
      radarOptions={radarOptions}
    />
  );
};

export default MultipleScaleQuestionRadarChart;
