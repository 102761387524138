import { debounce, sortBy } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { components } from 'react-select';

import type { PaginatedCollection, TrainingSession } from 'models';
import type { DropdownIndicatorProps, OptionProps } from 'react-select';
import type { AppDispatch } from 'redux/actions/types';

import { startToEndDateLabel } from 'helpers/date';
import { __ } from 'helpers/i18n/index';

import { hydrateFromResponse } from 'lib/dataLoader';
import { get } from 'redux/actions/api';

import { Flex, Icon, Select, Text } from 'components';

type Props = {
  onChange: (session: TrainingSession | null) => void;
  selectedTrainingSession: TrainingSession | undefined | null;
  setShowNoSessionWarning: (show: boolean) => void;
};

type AfterConnectProps = Props & {
  fetchTrainingSessions: (
    search: string | undefined
  ) => Promise<PaginatedCollection<TrainingSession>>;
};

const DropdownIndicator = (props: DropdownIndicatorProps) => (
  <components.DropdownIndicator {...props}>
    <Icon name="search" />
  </components.DropdownIndicator>
);

type CustomOptionProps = OptionProps & {
  data: {
    value: string;
    label: string;
    session: TrainingSession;
  };
};

type Option = CustomOptionProps['data'];

const SessionPickerOption = (props: CustomOptionProps) => {
  const session = props.data.session;
  return (
    <components.Option {...props}>
      <Flex direction="column">
        <Text>{session.name || __('Untitled session')}</Text>
        <Text transformation="italic">
          {startToEndDateLabel(session.startDate, session.endDate, {
            fromToLabelsAlwaysVisible: true,
          })}
        </Text>
      </Flex>
    </components.Option>
  );
};

const TrainingSessionPicker = ({
  onChange,
  selectedTrainingSession,
  fetchTrainingSessions,
  setShowNoSessionWarning,
}: AfterConnectProps) => {
  const formatSessionToOption = (session: TrainingSession): Option => ({
    value: session.id,
    label: session.name || __('Untitled session'),
    session,
  });

  const loadSessionOptions = (
    search: string | undefined,
    callback: (trainingSessions: Array<Option>) => void
  ) => {
    fetchTrainingSessions(search).then(trainingSessionCollection => {
      setShowNoSessionWarning(
        !search && trainingSessionCollection.totalPageCount === 0
      );
      callback(
        sortBy(
          trainingSessionCollection.items.map(formatSessionToOption),
          o => o.session.name
        )
      );
    });
  };
  const debouncedSearch = debounce(loadSessionOptions, 500);

  const handleChange = (option: Option | Array<Option> | null | void) => {
    if (!option || Array.isArray(option)) return onChange(null);
    onChange(option.session);
  };

  return (
    <Select
      loadOptions={debouncedSearch}
      value={
        selectedTrainingSession
          ? formatSessionToOption(selectedTrainingSession)
          : null
      }
      onChange={handleChange}
      placeholder={__('Select a training session')}
      loadingMessage={() => __('Loading sessions…')}
      maxMenuHeight={200}
      captureMenuScroll
      inModal
      isClearable
      isAsync
      cacheOptions
      defaultOptions
      components={{
        Option: SessionPickerOption,
        DropdownIndicator,
      }}
    />
  );
};

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  fetchTrainingSessions: async (
    search: string | undefined
  ): Promise<PaginatedCollection<TrainingSession>> =>
    dispatch(async (dispatch, getState) => {
      const { response } = await dispatch(
        get('training/sessions', {
          countPerPage: 10,
          permission: 'add_participants?',
          search,
          searchOnlyOnSessionName: true,
        })
      );
      return hydrateFromResponse(
        getState().data,
        response.body,
        {
          trainingSessionCollection: { items: {} },
        },
        response.body.data.id
      );
    }),
});

export default connect(
  null,
  // @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
  mapDispatchToProps
)(TrainingSessionPicker) as React.ComponentType<Props>;
