import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import can from 'helpers/can';
import { useCurrentOrganization } from 'helpers/hooks';
import { pathToHome } from 'helpers/paths';

import { Redirect, Route, Switch } from 'components';

import CatalogRoutes from './Catalog/Routes';
import CatalogManagementRoutes from './CatalogManagement/Routes';
import ManagerDashboard from './ManagerDashboard';

export default function Routes() {
  const { path } = useRouteMatch();
  const organization = useCurrentOrganization();
  const canShowTrainingCatalog = can({
    perform: 'show_training_catalog',
    on: organization,
  });

  const canShowTrainingCatalogManagement = can({
    perform: 'show_training_catalog_management_menu',
    on: organization,
  });

  const canShowManagerTrainingDashboard = can({
    perform: 'show_manager_training_dashboard',
    on: organization,
  });

  return (
    <Switch>
      {canShowTrainingCatalog && (
        <Route path={`${path}/catalog`} component={CatalogRoutes} />
      )}
      {canShowManagerTrainingDashboard && (
        <Route
          path={`${path}/:periodSlug/manager-dashboard`}
          component={ManagerDashboard}
        />
      )}
      {canShowTrainingCatalogManagement && (
        <Route path={`${path}`} component={CatalogManagementRoutes} />
      )}

      <Redirect to={pathToHome()} />
    </Switch>
  );
}
