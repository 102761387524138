import * as ActiveStorage from '@rails/activestorage';
import numeral from 'numeral';
import React, { CSSProperties, ReactNode } from 'react';
import { connect } from 'react-redux';
import Trix from 'trix';

import type { AppDispatch } from 'redux/actions/types';

import { __ } from 'helpers/i18n';
import { assert } from 'helpers/invariant';

import { createUrl } from 'lib/api';
import { getCredentials } from 'lib/api/credentials';
import { htmlErrorNotice } from 'redux/actions';

import { DropdownMenuItem } from 'components';

import translations from './Config/Translations';

type Range = [number, number];
type Tag = { name: string; tag: string };

// Partial typing
type EditorDocument = {
  findRangesForTextAttribute: (
    attributeName: string,
    options?: { withValue: string }
  ) => Range[];
  getCommonAttributesAtRange: (range: Range) => { [attribute: string]: string };
  removeAttributeAtRange: (attributeName: string, range: Range) => void;
};

// Partial typing
export type Editor = {
  getDocument: () => EditorDocument;
  getSelectedRange: () => Range;
  setSelectedRange: (range: Range) => void;
  activateAttribute: (attributeName: string, value: string) => void;
  getPosition: () => number;
  expandSelectionInDirection: (direction: string) => void;
  insertString: (tag: string) => void;
  getClientRectAtPosition: (position: number) => DOMRect;
  deleteInDirection: (direction: string) => void;
  element: HTMLElement;
};

type Container = HTMLElement & { editor: Editor };

type State = {
  showMergeTags: boolean;
  tags: Tag[];
};

type Props = {
  value: string | undefined;
  allowImageUpload?: boolean;
  mergeTags?: {
    trigger: string;
    tags: Tag[];
  }[];
  onEditorReady?: (editor: Editor) => void;
  onChange?: (html: string, text: string) => void;
  fixedTagSelector?: boolean;
  className?: string;
  autoFocus?: boolean;
  placeholder?: string;
  toolbar?: unknown;
};

type AfterConnectProps = Props & {
  notifyError: (message: string) => void;
};

const ALLOWED_TYPES = ['image/png', 'image/jpeg', 'image/gif'];
const MAX_SIZE_ALLOWED = 5 * 1000 * 1000; // 5MB

// from https://github.com/dstpierre/react-trix/blob/master/src/react-trix.tsx
class TrixEditor extends React.Component<AfterConnectProps, State> {
  static defaultProps = {
    allowImageUpload: true,
  };

  container: Container;
  editor: Editor;
  d: HTMLDivElement | null;
  id: string;

  constructor(props: AfterConnectProps) {
    super(props);
    // @ts-ignore TSFIXME: Fix strictNullChecks error
    this.container = null;
    // @ts-ignore TSFIXME: Fix strictNullChecks error
    this.editor = null;
    this.d = null;
    this.id = this.generateId();
    this.state = {
      showMergeTags: false,
      tags: [],
    };

    this.preConfigureTrix();
  }

  generateId() {
    let dt = new Date().getTime();
    let uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        let r = (dt + Math.random() * 16) % 16 | 0;
        dt = Math.floor(dt / 16);
        return (c === 'x' ? r : (r & 0x3) | 0x8).toString(16);
      }
    );
    return 'T' + uuid;
  }

  componentDidMount() {
    this.container = document.getElementById(`editor-${this.id}`) as Container;

    if (this.container) {
      this.listenInitEvent();
    } else {
      console.error('editor not found');
    }
  }

  componentWillUnmount() {
    this.container.removeEventListener(
      'trix-initialize',
      this.handleInitialize
    );
    this.container.removeEventListener('trix-change', this.handleChange);
    this.container.removeEventListener('trix-blur', this.handleBlur);
    this.container.removeEventListener('keydown', this.handleKeyDown);
    this.container.removeEventListener(
      'trix-selection-change',
      this.handleSelectionChange
    );
    this.container.removeEventListener(
      'trix-file-accept',
      this.handleFileAccept
    );
    this.container.removeEventListener('click', this.handleLinkClick);
    this.container.removeEventListener(
      'trix-attachment-add',
      this.handleAttachment
    );
  }

  listenInitEvent() {
    this.container.addEventListener(
      'trix-initialize',
      this.handleInitialize,
      false
    );
  }

  listenPostInitEvents() {
    this.container.addEventListener('trix-change', this.handleChange, false);
    this.container.addEventListener('trix-blur', this.handleBlur, false);
    this.container.addEventListener('keydown', this.handleKeyDown, false);
    this.container.addEventListener(
      'trix-selection-change',
      this.handleSelectionChange,
      false
    );
    this.container.addEventListener(
      'trix-file-accept',
      this.handleFileAccept,
      false
    );
    this.container.addEventListener('click', this.handleLinkClick, false);
    this.container.addEventListener(
      'trix-attachment-add',
      this.handleAttachment
    );
  }

  preConfigureTrix() {
    Object.assign(Trix.config.lang, translations());
    Object.assign(Trix.config.attachments.preview.caption, {
      size: false,
    });
    // frozen attribute is applied when selecting text.
    // it adds style {background-color: highlight} temporarily which trigger a onChange
    Trix.config.textAttributes.frozen = {};
  }

  prefixLinksWithProtocol() {
    const document = this.editor.getDocument();
    const hrefRanges = document.findRangesForTextAttribute('href');

    hrefRanges.forEach(range => {
      const href = document.getCommonAttributesAtRange(range).href;

      if (href && !(href.startsWith('http') || href.startsWith('mailto:'))) {
        const selectedRange = this.editor.getSelectedRange();
        const currentRange =
          selectedRange[0] === selectedRange[1]
            ? ([selectedRange[0], selectedRange[0] + href.length] as Range)
            : selectedRange;

        const prefixedUrl = 'https://' + href;

        this.editor.setSelectedRange(range);
        document.removeAttributeAtRange('href', range);
        this.editor.activateAttribute('href', prefixedUrl);
        this.editor.setSelectedRange(currentRange);
      }
    });
  }

  supportLinkWithoutProtocol(event) {
    const { toolbarElement } = event.target;
    if (!event.target.toolbarElement) return;

    const urlInput = toolbarElement.querySelector('input[name=href]');

    urlInput.addEventListener('keydown', event => {
      if (event.keyCode === 13) {
        setTimeout(() => {
          this.prefixLinksWithProtocol();
        }, 0);
      }
    });

    urlInput.autocomplete = 'url';
    urlInput.type = 'text';
    const httpPattern =
      '(?:https?:\\/\\/)?[^.\\/\\s]+(?:\\.[^.\\/\\s]{2,})+(?:\\/.*)?';
    const mailtoPattern = 'mailto:[^@\\s]+@[^@\\s]+.[^@\\s]+';
    urlInput.pattern = `^(?:${httpPattern})|(?:${mailtoPattern})$`;
  }

  hideDisabledToolbarActions(event) {
    const { toolbarElement } = event.target;
    if (!event.target.toolbarElement) return;

    // for now we only disable the attachment button
    const actionsToHideMap = {
      '.trix-button-group--file-tools': this.props.allowImageUpload,
    };

    Object.entries(actionsToHideMap)
      .filter(([_className, enabled]) => !enabled)
      .forEach(([className]) => {
        toolbarElement.querySelector(className)?.remove();
      });
  }

  handleInitialize = event => {
    this.supportLinkWithoutProtocol(event);
    this.hideDisabledToolbarActions(event);
    this.editor = this.container.editor;
    const { onEditorReady } = this.props;

    if (!this.editor) {
      console.error('cannot find trix editor');
      return;
    }

    this.listenPostInitEvents();
    if (onEditorReady && typeof onEditorReady == 'function') {
      onEditorReady(this.editor);
    }
  };

  handleSelectionChange = _event => {
    this.hideMergeTags();
  };

  handleKeyDown = event => {
    if (event.keyCode === 27) {
      this.hideMergeTags();
    }
  };

  handleBlur = () => {
    this.hideMergeTags();
  };

  hideMergeTags() {
    if (this.state.showMergeTags) this.setState({ showMergeTags: false });
  }

  handleChange = e => {
    const props = this.props;
    const text = e.target.innerText;
    if (props.onChange) {
      props.onChange(e.target.innerHTML, text);
    }
    const range = this.editor.getSelectedRange();
    // if we have a collapse selection
    if (text && range[0] === range[1]) {
      // if we have a merge tag mergeTagTrigger
      if (props.mergeTags) {
        const documentText = this.editor.getDocument().toString();
        const cursorPosition = this.editor.getPosition();
        const lastCharacterTyped = documentText[cursorPosition - 1];

        const mergeTagTyped = props.mergeTags.find(
          mergeTag => mergeTag.trigger === lastCharacterTyped
        );

        if (mergeTagTyped) {
          this.setState({
            showMergeTags: true,
            tags: mergeTagTyped.tags,
          });
        } else {
          this.setState({
            showMergeTags: false,
          });
        }
      }
    }
  };

  handleAttachment = e => {
    var attachment = e.attachment;
    if (this.props.allowImageUpload && attachment.file) {
      return this.uploadAttachment(attachment);
    } else {
      // Only remove new attachments, the images already attached will still be displayed
      if (!attachment.file && this.editor.element.contentEditable !== 'false') {
        attachment.remove();
      }
    }
  };

  handleFileAccept = e => {
    const type = e.file.type;
    const size = e.file.size;
    const filedAllowed = ALLOWED_TYPES.includes(type);
    const fileSizeExceeded = size > MAX_SIZE_ALLOWED;

    if (!this.props.allowImageUpload) {
      e.preventDefault();
      return;
    }

    if (!filedAllowed) {
      e.preventDefault();

      this.props.notifyError(__('File type not supported: %1', type));
    }

    if (fileSizeExceeded) {
      e.preventDefault();

      this.props.notifyError(
        __(
          'File must be less than %1',
          numeral(MAX_SIZE_ALLOWED).format('0.00b')
        )
      );
    }
  };

  /**
   * @note
   * Trix doesn't allow directly setting a target, so we have to do this kind of workaround
   * https://github.com/basecamp/trix/issues/55
   */
  handleLinkClick = e => {
    let target = e.target;

    while (!['A', 'TRIX-EDITOR'].includes(target.tagName)) {
      target = target.parentElement;
    }

    if (target.tagName === 'A') {
      target.setAttribute('target', '_blank');
    }
  };

  setXhrCredentials(xhr) {
    const credentials = getCredentials();
    const authorizationHeader = `Bearer ${assert(
      credentials.token,
      'Credentials required for direct upload'
    )}`;

    xhr.setRequestHeader('Authorization', authorizationHeader);
  }

  setXhrProgress(xhr, attachment) {
    xhr.upload.onprogress = event => {
      const progress = (event.loaded / event.total) * 100;

      // Fix alert: we subtract 1 from the progress to fix a bug
      // where the image finishes uploading but the request is still pending
      return attachment.setUploadProgress(Math.max(0, progress - 1));
    };
  }

  uploadAttachment(attachment) {
    const upload = new ActiveStorage.DirectUpload(
      attachment.file,
      createUrl('direct_uploads'),
      {
        directUploadWillCreateBlobWithXHR: xhr => this.setXhrCredentials(xhr),
        directUploadWillStoreFileWithXHR: xhr =>
          this.setXhrProgress(xhr, attachment),
      }
    );

    upload.create((error, blob) => {
      if (error) {
        window.logException(error);
      } else {
        const url = createUrl(
          `rails/active_storage/blobs/${blob.signed_id}/${blob.filename}`,
          false
        );

        attachment.setAttributes({
          url,
          href: url,
        });
      }
    });
  }

  handleTagSelected(t: Tag) {
    this.setState({
      showMergeTags: false,
    });
    this.editor.expandSelectionInDirection('backward');
    this.editor.insertString(t.tag);
  }

  renderTagSelector(tags: Tag[]) {
    if (!tags || !this.editor) {
      return null;
    }
    const editorTopLevel = document.getElementById(
      'trix-editor-top-level-' + this.id
    );

    if (!editorTopLevel) return null;

    const editorPosition = editorTopLevel.getBoundingClientRect();
    // current cursor position
    const rect = this.editor.getClientRectAtPosition(
      this.editor.getSelectedRange()[0]
    );

    // when the only character is a trigger is deleted we shouldn't show the tag selector
    if (!rect) return null;

    const boxStyle: CSSProperties = this.props.fixedTagSelector
      ? {
          position: 'fixed',
          top: rect.top + 15,
          left: rect.left + 5,
        }
      : {
          position: 'absolute',
          top: rect.top + 25 - editorPosition.top,
          left: rect.left + 25 - editorPosition.left,
        };

    return (
      <div style={boxStyle} className="react-trix-suggestions">
        <div className="dropdown-content">
          {tags.map(tag => {
            return (
              <DropdownMenuItem
                // we use mouseDown because it precedes the blur event, otherwise the dropdown closes before selecting the element
                onMouseDown={this.handleTagSelected.bind(this, tag)}
                key={tag.name}
              >
                {tag.name}
              </DropdownMenuItem>
            );
          })}
        </div>
      </div>
    );
  }

  render() {
    let state = this.state;
    let props = this.props;
    var attributes = {
      id: `editor-${this.id}`,
      input: `input-${this.id}`,
    };
    if (props.className) {
      attributes['class'] = props.className;
    }
    if (props.autoFocus) {
      attributes['autofocus'] = props.autoFocus.toString();
    }
    if (props.placeholder) {
      attributes['placeholder'] = props.placeholder;
    }
    if (props.toolbar) {
      attributes['toolbar'] = props.toolbar;
    }
    let mergetags: ReactNode | null = null;
    if (state.showMergeTags) {
      mergetags = this.renderTagSelector(state.tags);
    }

    return (
      <div
        id={'trix-editor-top-level-' + this.id}
        ref={d => (this.d = d)}
        style={{ position: 'relative' }}
      >
        {React.createElement('trix-editor', attributes)}
        <input type="hidden" id={`input-${this.id}`} value={this.props.value} />
        {mergetags}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  notifyError: (msg: string) => dispatch(htmlErrorNotice(msg)),
});

// @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
export default connect(null, mapDispatchToProps)(TrixEditor);
