import React, { useContext } from 'react';

import type { BudgetItem, FundingSource } from 'models';

import invariant from 'helpers/invariant';

import { CurrentTrainingPeriodContext } from '../../../../Plan';
import BudgetItemAmountInput from './BudgetItemAmountInput';
import BudgetItemDeletionButton from './BudgetItemDeletionButton';
import FundingSourceSelect from './FundingSourceSelect';

type Props = {
  budgetItem?: BudgetItem;
  fundingSources: Array<FundingSource>;
  availableFundingSources: Array<FundingSource>;
  onBudgetItemDestroy: (budgetItem: BudgetItem) => Promise<void>;
  onBudgetItemUpdate: (attributes: Partial<BudgetItem>) => Promise<void>;
  onFundingSourceCreate: (attributes: Partial<FundingSource>) => Promise<void>;
};

const BudgetListItem = ({
  budgetItem,
  fundingSources,
  availableFundingSources,
  onBudgetItemDestroy,
  onBudgetItemUpdate,
  onFundingSourceCreate,
}: Props) => {
  const period = useContext(CurrentTrainingPeriodContext);
  invariant(period, 'Period should be defined');

  const fundingSource = budgetItem
    ? fundingSources.find(source => source.id === budgetItem.fundingSourceId)
    : null;

  const handleFundingSourceUpdate = (fundingSourceId: string) =>
    onBudgetItemUpdate({ ...budgetItem, fundingSourceId: fundingSourceId });

  const handleFundingSourceCreation = (title: string) =>
    onFundingSourceCreate({ title });

  const handleBudgetItemUpdate = (attributes: Partial<BudgetItem>) =>
    onBudgetItemUpdate({ ...budgetItem, ...attributes });

  return (
    <div className="flex justify-between items-center mb-4">
      <div className="w-1/2">
        <FundingSourceSelect
          fundingSource={fundingSource}
          fundingSources={availableFundingSources}
          onUpdate={handleFundingSourceUpdate}
          onCreate={handleFundingSourceCreation}
        />
      </div>
      <div className="flex w-1/3 justify-end">
        {fundingSource && (
          <BudgetItemAmountInput
            budgetItem={budgetItem}
            defaultCurrency={period.mainCurrency}
            onUpdate={handleBudgetItemUpdate}
          />
        )}
        {budgetItem && (
          <BudgetItemDeletionButton
            budgetItem={budgetItem}
            onDestroy={onBudgetItemDestroy}
          />
        )}
      </div>
    </div>
  );
};

export default BudgetListItem;
