import { sortBy } from 'lodash';
import React from 'react';

import type { User } from 'models';

import { useOrganizationPlan } from 'helpers/hooks';
import { __, date } from 'helpers/i18n';

import { Column, Columns, FeatureFlagged, Title } from 'components';

import resolveLocaleLabel from '../helpers/resolveLocaleLabel';
import ReadOnlyField from './ReadOnlyField';

type Props = { user: User };

const ReadOnlyFields = ({ user }: Props) => {
  const { multiLevelAdminEnabled } = useOrganizationPlan();
  const { entity, team, manager } = user;

  const shouldDisplayCustomFields = (): boolean =>
    !!user.activeCustomFieldValues && user.activeCustomFieldValues.length !== 0;

  const sortedActiveCustomFieldValues = sortBy(
    user.activeCustomFieldValues,
    field => field.label.toLowerCase()
  );

  return (
    <React.Fragment>
      <Columns>
        <Column>
          <ReadOnlyField label={__('E-mail:')} value={user.email} />
          <ReadOnlyField label={__('First Name:')} value={user.firstName} />
          <ReadOnlyField label={__('Last Name:')} value={user.lastName} />
          <ReadOnlyField
            label={__('Job Title:')}
            value={user.jobTitle || null}
          />
          <ReadOnlyField label={__('Level:')} value={user.level || null} />
          <ReadOnlyField label={__('Service:')} value={user.service || null} />
          {!!user.workStartDate && (
            <ReadOnlyField
              label={__('Work start date:')}
              value={date(user.workStartDate)}
            />
          )}
        </Column>

        <Column>
          <ReadOnlyField
            label={__('Manager:')}
            value={manager?.fullName || null}
          />
          <ReadOnlyField label={__('Team name:')} value={team?.name || null} />
          {!!entity && multiLevelAdminEnabled && (
            <ReadOnlyField
              label={__('Administration entity:')}
              value={entity.name}
            />
          )}
          <ReadOnlyField
            label={__('Registration Number:')}
            value={user.registrationNumber || null}
          />
          <ReadOnlyField label={__('Region:')} value={user.region || null} />
          <ReadOnlyField
            label={__('Department:')}
            value={user.department || null}
          />
          <FeatureFlagged flag="genderInProfile">
            <ReadOnlyField label={__('Gender:')} value={user.gender || null} />
          </FeatureFlagged>
          <ReadOnlyField
            label={__('Language:')}
            value={resolveLocaleLabel(user.locale)}
          />
        </Column>
      </Columns>

      {shouldDisplayCustomFields() && (
        <React.Fragment>
          <hr />
          <Title size={6} transformation="uppercase" className="mb-4">
            {__('Custom Fields')}
          </Title>
          <Columns className="flex-wrap">
            {sortedActiveCustomFieldValues.map(customField => (
              <Column size={6} key={customField.slug} className="pb-0">
                <ReadOnlyField
                  label={__('%1:', customField.label)}
                  value={customField.value}
                />
              </Column>
            ))}
          </Columns>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ReadOnlyFields;
