import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type {
  AutoAddMode,
  InteractionType,
  ResponsibleRole,
  ReviewCycle,
} from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { __ } from 'helpers/i18n';
import {
  Match,
  navigate,
  pathToReviewCycle,
  pathToReviewCycleParticipants,
  pathToReviewCycles,
} from 'helpers/navigation';
import { TrackView, trackAction } from 'helpers/tracking';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { del, get, post, put } from 'redux/actions/api';

import {
  Button,
  ConfirmationModal,
  DesignSystem,
  FetchContainer,
  Icon,
  PageHeader,
  PageTitle,
  Redirect,
} from 'components';

import Stepper from 'scenes/components/Stepper';

import getSetupStepInfoList from '../helpers/steps';
import CurrentStep from './steps/CurrentStep';

export type UpdatableReviewCycleFields = {
  name: string | undefined | null;
  setupStepIndex: number;
  interactionType: InteractionType | undefined | null;
  defaultTemplateId: string;
  overrideNominatePeersInstruction: string | undefined | null;
  responsibleId: string | undefined | null;
  responsibleRole: ResponsibleRole | undefined | null;
  visibleForSuperiors: boolean | undefined | null;
  visibleForReviewee: boolean | undefined | null;
  dateReviewRevieweeEnd: string | undefined | null;
  dateReviewReviewerEnd: string | undefined | null;
  autoAddParticipantsEnabled: boolean | undefined | null;
  addParticipantsBasedOnWorkStartDateAfterDays: number | undefined | null;
  revieweeDeadlineAfterDays: number | undefined | null;
  reviewerDeadlineAfterDays: number | undefined | null;
  addDirectReportsAsPeersByDefault: boolean;
  autoAddMode: AutoAddMode;
  tags: Array<string>;
  tagLabels: Array<string>;
  reviewCategorizationEnabled: boolean;
};

export type UpdateReviewCycle = (
  reviewCycle: Partial<UpdatableReviewCycleFields>
) => Promise<void>;

type Props = {
  match: Match<{
    reviewCycleId: string;
  }>;
};

type AfterDataLoaderProps = Props &
  DataLoaderProvidedProps & {
    reviewCycle: ReviewCycle;
  };

type AfterConnectProps = {
  updateReviewCycle: UpdateReviewCycle;
  deleteReviewCycle: () => Promise<void>;
  launchReviewCycle: () => Promise<void>;
} & AfterDataLoaderProps;

type State = {
  deleteModalOpened: boolean;
};

class ReviewCycleSetup extends React.Component<AfterConnectProps, State> {
  state = {
    deleteModalOpened: false,
  };

  openDeletionModal = () => this.setState({ deleteModalOpened: true });
  closeDeletionModal = () => this.setState({ deleteModalOpened: false });

  render() {
    const {
      isFetching,
      hasError,
      reviewCycle,
      updateReviewCycle,
      deleteReviewCycle,
      launchReviewCycle,
    } = this.props;
    const { deleteModalOpened } = this.state;

    return (
      <FetchContainer
        isFetching={isFetching}
        hasError={hasError}
        render={() => {
          if (reviewCycle.status !== 'draft') {
            return <Redirect to={pathToReviewCycle(reviewCycle.id)} />;
          }

          return (
            <DesignSystem version={2}>
              <div className="review-cycle-setup">
                <PageHeader
                  title={reviewCycle.name}
                  actions={
                    <Button
                      color="danger"
                      onClick={this.openDeletionModal}
                      size="normal"
                    >
                      <Icon style={{ marginRight: 8 }} name="delete" />
                      {__('Delete')}
                    </Button>
                  }
                  withBackButton
                  backButtonProps={{
                    target: pathToReviewCycles(reviewCycle.interactionType),
                    children: __('Back to campaigns'),
                  }}
                />
                <PageTitle title={[reviewCycle.name, __('Admin')]} />

                <Stepper
                  stepInfoList={getSetupStepInfoList(reviewCycle)}
                  currentStepIndexFromElement={reviewCycle.setupStepIndex}
                  updateElementStepIndex={newIndex =>
                    updateReviewCycle({ setupStepIndex: newIndex })
                  }
                  renderChildren={(currentStepInfo, goToStep, goToNextStep) => (
                    <React.Fragment>
                      <PageTitle title={[reviewCycle.name, __('Admin')]} />
                      <TrackView
                        event="Review cycle setup viewed"
                        properties={{
                          reviewCycleId: reviewCycle.id,
                        }}
                      />

                      <CurrentStep
                        currentStepInfo={currentStepInfo}
                        reviewCycle={reviewCycle}
                        goToNextStep={goToNextStep}
                        goToStep={goToStep}
                        updateReviewCycle={updateReviewCycle}
                        launchReviewCycle={launchReviewCycle}
                      />

                      <ConfirmationModal
                        isDanger
                        isActive={deleteModalOpened}
                        onCancel={this.closeDeletionModal}
                        onConfirm={deleteReviewCycle}
                        cancelLabel={__("Don't delete")}
                        confirmLabel={__('Delete campaign')}
                        title={__('Delete campaign')}
                        refreshContentOnOpening={false}
                      >
                        <p>{__('Caution: This action is not reversible.')}</p>
                      </ConfirmationModal>
                    </React.Fragment>
                  )}
                />
              </div>
            </DesignSystem>
          );
        }}
      />
    );
  }
}

const mapDispatchToProps = (
  dispatch: AppDispatch,
  { match, reviewCycle }: AfterDataLoaderProps
) => ({
  updateReviewCycle: (reviewCycle: Partial<UpdatableReviewCycleFields>) =>
    dispatch(
      put(`review_cycles/${match.params.reviewCycleId}`, { reviewCycle })
    ),
  deleteReviewCycle: async () => {
    await dispatch(
      del(
        `review_cycles/${match.params.reviewCycleId}`,
        {},
        {
          successMessage: __('Campaign deleted'),
          errorMessage: __('Failed to delete campaign.'),
        }
      )
    );
    navigate(pathToReviewCycles(reviewCycle.interactionType));
  },
  launchReviewCycle: async () => {
    await dispatch(
      post(`review_cycles/${match.params.reviewCycleId}/launch`, undefined, {
        errorMessage: __('Failed to start review campaign.'),
      })
    );

    trackAction('Review cycle launched', {
      type: reviewCycle.interactionType,
      numberOfParticipants: reviewCycle.userReviewsCount,
      name: reviewCycle.name,
    });

    navigate(pathToReviewCycleParticipants(match.params.reviewCycleId));
  },
});

export default compose(
  newDataLoader({
    fetch: ({ match }: Props) =>
      get(`review_cycles/${match.params.reviewCycleId}`),
    hydrate: {
      reviewCycle: {
        defaultTemplate: {},
        templates: {},
        responsible: {},
        autoAddRules: { userSegments: {} },
        tags: {},
        abilities: {},
      },
    },
    cacheKey: ({ match }: Props) => match.params.reviewCycleId,
  }),
  // @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
  connect(null, mapDispatchToProps)
)(ReviewCycleSetup) as React.ComponentType<Props>;
