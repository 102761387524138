import * as React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { CreateTrainingRequestFormObject } from '../types';
import type { DataLoaderProvidedProps } from 'lib/dataLoader';
import type { PaginationProps } from 'lib/dataLoader/pagination/types';
import type { User, UserCollection, UserFilterSegment } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import compositeKey from 'helpers/compositeKey';

import { JSONAPIActionSuccess } from 'lib/api/types';
import { newDataLoader } from 'lib/dataLoader';
import withStatePagination from 'lib/dataLoader/pagination/StatePaginationFactory';
import { get } from 'redux/actions/api';

import { Box, BoxList, DatatableWrapper, FetchContainer } from 'components';

import Header from './OldHeader';
import UserPickerListItem from './UserPickerListItem';

type Props = {
  setTrainingRequest: (
    trainingRequest: CreateTrainingRequestFormObject
  ) => void;
  trainingRequest: CreateTrainingRequestFormObject;
};

type AfterPaginateProps = Props & PaginationProps;

type AfterConnectProps = AfterPaginateProps & {
  getUsersWithoutPagination: (
    search: string | null,
    userFilter: UserFilterSegment | null
  ) => Promise<JSONAPIActionSuccess<User[], void>>;
};

type AfterDataloaderProps = AfterConnectProps &
  DataLoaderProvidedProps & {
    userCollection: UserCollection;
  };

class TraineesChooser extends React.Component<AfterDataloaderProps> {
  static defaultProps = {};

  render() {
    const {
      userCollection,
      isFetching,
      hasError,
      search,
      onSearchChange,
      countPerPage,
      page,
      getNextPage,
      getPreviousPage,
      onUserFilterChange,
      trainingRequest,
      setTrainingRequest,
      getUsersWithoutPagination,
    } = this.props;

    if ((isFetching && !userCollection) || hasError) {
      return <FetchContainer isFetching={isFetching} hasError={hasError} />;
    }

    const toggleTrainee = userId => {
      let newTraineeIdsList = trainingRequest.traineeIds;
      if (newTraineeIdsList.has(userId)) newTraineeIdsList.delete(userId);
      else newTraineeIdsList.add(userId);
      setTrainingRequest({
        ...trainingRequest,
        traineeIds: newTraineeIdsList,
      });
    };

    const onRemoveEveryoneClick = () => {
      setTrainingRequest({
        ...trainingRequest,
        traineeIds: new Set(),
      });
    };

    const onAddEveryoneClick = async (search, userFilter) => {
      const { response } = await getUsersWithoutPagination(search, userFilter);

      let traineeIds = trainingRequest.traineeIds;
      const newTraineeIds = response.body.data.map((user: User) => user.id);

      newTraineeIds.forEach(traineeIds.add, traineeIds);

      setTrainingRequest({
        ...trainingRequest,
        traineeIds: traineeIds,
      });
    };

    const { users, ...collectionInfo } = userCollection;

    return (
      <DatatableWrapper
        collectionInfo={collectionInfo}
        isFetching={isFetching}
        hasError={hasError}
        search={search}
        countPerPage={countPerPage}
        onSearchChange={onSearchChange}
        onUserFilterChange={onUserFilterChange}
        userFilter={collectionInfo.userFilter}
        page={page}
        getNextPage={getNextPage}
        getPreviousPage={getPreviousPage}
        showTotalRecordCount={false}
        onFilterChange={() => {}}
        renderHeader={({ userFilter, onUserFilterChange }) => (
          <Header
            participantCount={trainingRequest.traineeIds.size}
            search={search}
            onSearchChange={onSearchChange}
            totalRecordCount={collectionInfo.totalRecordCount}
            // @ts-ignore TSFIXME: Fix strictNullChecks error
            userFilter={userFilter}
            onUserFilterChange={onUserFilterChange}
            onRemoveEveryoneClick={onRemoveEveryoneClick}
            onAddEveryoneClick={onAddEveryoneClick}
          />
        )}
      >
        <Box isInset>
          <BoxList className="participant-list">
            <FetchContainer
              isFetching={isFetching}
              hasError={hasError}
              loadingStyle="overlay"
              render={() =>
                users.map(user => (
                  <UserPickerListItem
                    key={user.id}
                    user={user}
                    toggleTrainee={toggleTrainee}
                    isSelected={trainingRequest.traineeIds.has(user.id)}
                  />
                ))
              }
            />
          </BoxList>
        </Box>
      </DatatableWrapper>
    );
  }
}

function mapDispatchToProps(dispatch: AppDispatch) {
  return {
    getUsersWithoutPagination: async (
      search: string | null,
      userFilter: UserFilterSegment | null
    ) =>
      dispatch(
        get('users', {
          search,
          userFilter,
          permission: 'create_training_request?',
          slim: true,
        })
      ),
  };
}

export default compose<React.ComponentType<AfterConnectProps>>(
  // @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
  connect(null, mapDispatchToProps),
  withStatePagination({ defaultPaginationParams: () => ({ countPerPage: 6 }) }),
  newDataLoader({
    fetch: ({ page, countPerPage, search, userFilter }: AfterPaginateProps) =>
      get(`users`, {
        page,
        countPerPage,
        search,
        userFilter,
        permission: 'create_training_request?',
      }),
    hydrate: {
      userCollection: {
        userFilter: {},
        users: [],
      },
    },
    cacheKey: ({
      page,
      countPerPage,
      search,
      userFilter,
    }: AfterPaginateProps) =>
      compositeKey({ page, countPerPage, search, userFilter }),
  })
)(TraineesChooser) as React.ComponentType<Props>;
