import * as Cookie from 'helpers/cookie';

export type Credentials = {
  token?: string;
  email?: string;
  impersonate?: string;
};

type onCredentialsChangeType = () => void;

const credentialListeners: Array<() => void> = [];

function onStorageChange(event) {
  if (
    event.key === 'credentials' &&
    (event.newValue == null ||
      (event.oldValue != null && event.newValue !== event.oldValue))
  )
    for (let index = 0; index < credentialListeners.length; index++) {
      credentialListeners[index]();
    }
}

function addCredentialsListener(fn) {
  credentialListeners.push(fn);
}

export function registerOnCredentialsChange(
  onCredentialsChange: onCredentialsChangeType
) {
  addCredentialsListener(onCredentialsChange);
  window.addEventListener('storage', onStorageChange, false);
}

function removeCredentialsListener() {
  window.removeEventListener('storage', onStorageChange, false);
}

export function setCredentials(credentials: Credentials) {
  localStorage.setItem('credentials', JSON.stringify(credentials));
}

export function getCredentials(): Credentials {
  const localStorageCredentials = localStorage.getItem('credentials');
  let credentials: Credentials;

  // Start of backward compatibility
  if (!localStorageCredentials) {
    credentials = Cookie.get();
    localStorage.setItem('credentials', JSON.stringify(credentials));
    Cookie.clear();
  }
  // End of backward compatibility
  else {
    credentials = JSON.parse(localStorageCredentials);
  }

  const { email, token, impersonate } = credentials;
  return { email, token, impersonate };
}

export function clearCredentials() {
  credentialListeners.length = 0;
  removeCredentialsListener();
  localStorage.removeItem('credentials');
  Cookie.clear(); // Backward compatibility
}
