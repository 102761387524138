import { merge } from 'lodash';
import React from 'react';

import type { StatePaginationProps } from './types';
import type { PaginationSearchParams } from 'lib/dataLoader/pagination/types';

import { type UserFilterSegment } from 'models';

import { getDisplayName } from 'helpers/hoc';

import { DEFAULT_PAGINATION } from './paginationParams';

type State<T extends {}> = {
  paginationSearchParams: PaginationSearchParams | undefined | null;
  pageDependencies: T[keyof T][] | undefined | null;
};

type FactoryArgs<T extends {}> = {
  defaultPaginationParams?: (
    props: T
  ) => Partial<PaginationSearchParams> | undefined | null;
  resetPageFor?: (props: T) => T[keyof T][];
};

export default function withStatePagination<T extends {}>({
  defaultPaginationParams: defaultPaginationParamsFromProps,
  resetPageFor,
}: FactoryArgs<T>) {
  function hoc(WrappedComponent: React.ComponentType<any>) {
    class StatePaginator extends React.Component<any, State<T>> {
      state = {
        paginationSearchParams: null,
        pageDependencies: resetPageFor && resetPageFor(this.props),
      } as State<T>;

      componentDidMount() {
        const paginationParamsFromProps =
          defaultPaginationParamsFromProps || ((_props: T) => {});
        const paginationSearchParams = merge(
          {},
          DEFAULT_PAGINATION,
          paginationParamsFromProps(this.props)
        );
        if (
          paginationSearchParams.filter?.reviewee ||
          paginationSearchParams.filter?.reviewer
        )
          delete paginationSearchParams.filter.all;

        this.setState({ paginationSearchParams });
      }

      componentDidUpdate() {
        const { paginationSearchParams } = this.state;

        if (
          resetPageFor &&
          paginationSearchParams &&
          this.state.pageDependencies
        ) {
          const prevDeps = this.state.pageDependencies;
          const newDeps = resetPageFor(this.props);

          if (prevDeps.some((dep, i) => dep !== newDeps[i])) {
            this.setState({
              pageDependencies: newDeps,
              paginationSearchParams: {
                ...paginationSearchParams,
                page: 1,
              },
            });
          }
        }
      }

      updatePaginationParams(
        partialPaginationParams: Partial<PaginationSearchParams>
      ) {
        const { paginationSearchParams } = this.state;

        this.setState({
          paginationSearchParams: {
            ...(paginationSearchParams as PaginationSearchParams),
            ...partialPaginationParams,
          },
        });
      }

      onSearchChange = (search: string) => {
        this.updatePaginationParams({
          search,
          page: 1,
        });
      };

      onSortChange = (sortName: string) => {
        const currentSortValue =
          this.state.paginationSearchParams?.sort?.[sortName];

        const defaultSortParam =
          !!defaultPaginationParamsFromProps &&
          defaultPaginationParamsFromProps(this.props)?.sort;
        const defaultIsDesc =
          defaultSortParam && defaultSortParam[sortName] === 'desc';

        // if default sort is desc, then the only other possible sort value is asc
        if (defaultIsDesc && currentSortValue !== 'asc') {
          return this.updatePaginationParams({ sort: { [sortName]: 'asc' } });
        }

        switch (currentSortValue) {
          case null || undefined:
            return this.updatePaginationParams({ sort: { [sortName]: 'asc' } });
          case 'asc':
            return this.updatePaginationParams({
              sort: { [sortName]: 'desc' },
            });
          case 'desc':
            return this.updatePaginationParams({ sort: {} });
          default:
            return this.updatePaginationParams({ sort: {} });
        }
      };

      mergeFilters = (currentFilters: {} = {}, newFilter: {}) => {
        const nestedFilters = {};
        for (let key in currentFilters) {
          if (typeof currentFilters[key] === 'object')
            nestedFilters[key] = currentFilters[key];
        }
        return { ...nestedFilters, ...newFilter };
      };

      onFilterChange = (newFilter: {}) => {
        this.updatePaginationParams({
          filter: this.mergeFilters(
            this.state.paginationSearchParams?.filter,
            newFilter
          ),
          page: 1,
        });
      };

      onUserFilterChange = (
        userFilter: UserFilterSegment | null | undefined
      ) => {
        this.updatePaginationParams({
          userFilter,
          page: 1,
        });
      };

      currentPage = () => {
        const { paginationSearchParams } = this.state;

        if (!paginationSearchParams) return null;

        return paginationSearchParams.page;
      };

      getPreviousPage = () => {
        const page = this.currentPage();

        if (page === null) return;

        this.updatePaginationParams({
          page: page - 1,
        });
      };

      getNextPage = () => {
        const page = this.currentPage();

        if (page === null) return;

        this.updatePaginationParams({
          page: page + 1,
        });
      };

      render() {
        const { paginationSearchParams } = this.state;

        if (paginationSearchParams === null) return null;

        const paginationProps: StatePaginationProps = {
          getNextPage: this.getNextPage,
          getPreviousPage: this.getPreviousPage,
          onSearchChange: this.onSearchChange,
          onFilterChange: this.onFilterChange,
          onSortChange: this.onSortChange,
          onUserFilterChange: this.onUserFilterChange,
          ...paginationSearchParams,
        } as any;

        return <WrappedComponent {...paginationProps} {...this.props} />;
      }
    }

    // @ts-expect-error: TSFIXME if you have the courage
    StatePaginator.displayName = `withStatePagination(${getDisplayName(
      WrappedComponent
    )})`;

    return StatePaginator;
  }

  return hoc;
}
