import { castHashValuesToBoolean } from 'helpers/hash';
import { PaginatedQueryParams } from 'helpers/hooks/usePaginatedQuery';

const parsePaginatedQueryParams = (
  params: Partial<PaginatedQueryParams>
): Partial<PaginatedQueryParams> => {
  const parsedParams: Partial<PaginatedQueryParams> = { ...params };
  let sanitizedQueryParams = {};

  if (params.page) {
    parsedParams.page = parseInt(params.page as unknown as string, 10);
  }

  if (params.countPerPage) {
    parsedParams.countPerPage = parseInt(
      params.countPerPage as unknown as string,
      10
    );
  }

  if (
    params.queryParams?.search &&
    typeof params.queryParams.search === 'string'
  ) {
    sanitizedQueryParams['search'] = params.queryParams.search;
  }

  if (
    params.queryParams?.userFilters &&
    typeof params.queryParams.userFilters === 'object'
  ) {
    sanitizedQueryParams['userFilters'] = params.queryParams.userFilters as any;
  }

  if (params.queryParams?.sort && typeof params.queryParams.sort === 'object') {
    sanitizedQueryParams['sort'] = params.queryParams.sort as any;
  }

  if (
    params.queryParams?.filter &&
    typeof params.queryParams.filter === 'object'
  ) {
    sanitizedQueryParams['filter'] = castHashValuesToBoolean(
      params.queryParams.filter
    );
  }

  if (Object.keys(sanitizedQueryParams).length > 0) {
    parsedParams.queryParams = {
      ...parsedParams.queryParams,
      ...(sanitizedQueryParams as PaginatedQueryParams['queryParams']),
    };
  }

  return parsedParams;
};

export default parsePaginatedQueryParams;
