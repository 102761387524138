import React, { Fragment, useState } from 'react';

import type { ReviewTemplateCreationType } from 'models';

import { useAppDispatch, useOrganizationPlan } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { navigate, pathToTemplate } from 'helpers/navigation';

import { post } from 'redux/actions/api';

import { Button, Icon } from 'components';

import ThreeSixtyFeaturePromptModal from 'scenes/admin/components/ThreeSixtyFeaturePromptModal';

type Props = {
  interactionType: string;
};

const TemplateCreator = ({ interactionType }: Props) => {
  const dispatch = useAppDispatch();
  const { is360Enabled } = useOrganizationPlan();
  const shouldPromptThreeSixtyUpgrade = !is360Enabled;
  const [displayedModal, setDisplayedModal] = useState<
    null | 'interactionTypePicker' | 'featurePrompt'
  >(null);

  const createTemplate = async type => {
    const response = await dispatch(
      post(
        `review_templates`,
        {
          reviewTemplate: { type },
        },
        {
          errorMessage: __('The template could not be created.'),
        }
      )
    );

    navigate(pathToTemplate(response.response.body.data.id));
  };

  const handleButtonClick = () => {
    if (interactionType === '360' && shouldPromptThreeSixtyUpgrade) {
      setDisplayedModal('featurePrompt');
      return;
    }

    createTemplate(interactionType as ReviewTemplateCreationType);
  };

  const handleFeaturePromptClose = () => {
    setDisplayedModal('interactionTypePicker');
  };

  const buttonText =
    interactionType === '360'
      ? __('Add a 360 Feedback template')
      : __('Add a review template');

  return (
    <Fragment>
      <Button color="primary" onClick={handleButtonClick}>
        <Icon name="add" />
        <span>{buttonText}</span>
      </Button>
      {displayedModal === 'featurePrompt' && (
        <ThreeSixtyFeaturePromptModal
          isActive
          onClose={handleFeaturePromptClose}
        />
      )}
    </Fragment>
  );
};

export default TemplateCreator;
