import React, { Fragment } from 'react';

import { TrainingRequest } from 'models';

import { FormErrors } from 'helpers/api';
import can from 'helpers/can';
import invariant from 'helpers/invariant';

import ApprovalPicker from './ApprovalPicker';
import RefusedRequestAdditionalDetails from './RefusedRequestAdditionalDetails';
import RequestDetails from './RequestDetails';

type Props = {
  request: TrainingRequest;
  errors?: FormErrors;
  onChange: (params: Partial<TrainingRequest>) => void;
};

const ModalContent = ({ request, errors, onChange }: Props) => {
  invariant(request.abilityIds, 'Training request abilities are required');

  const canUpdateDetails = can({ perform: 'update_details', on: request });
  const canUpdateStatus = can({ perform: 'update_status', on: request });

  return (
    <Fragment>
      <RequestDetails
        trainingRequest={request}
        errors={errors}
        readOnly={!canUpdateDetails}
        onChange={onChange}
      />
      <ApprovalPicker
        trainingRequest={request}
        errors={errors}
        readOnly={!canUpdateStatus}
        onChange={onChange}
      />
      {request.approvalStatus === 'refused' && (
        <RefusedRequestAdditionalDetails
          trainingRequest={request}
          readOnly={!canUpdateStatus}
          onChange={onChange}
        />
      )}
    </Fragment>
  );
};

export default ModalContent;
