import React, { ComponentType } from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';

import type { ActionProps } from './types';
import type { User } from 'models';
import type { AppDispatch } from 'redux/actions/types';

import { __ } from 'helpers/i18n';
import { viewerRole } from 'helpers/models/userReview';
import { trackAction } from 'helpers/tracking';
import { withActiveUser } from 'helpers/withSessionProps';

import { sendReminder } from 'redux/actions/resources';

import { MenuItem } from 'components';

type Props = ActionProps & {
  target: 'reviewee' | 'reviewer';
};

type AfterWithActiveUserProps = Props & {
  activeUser: User;
};

type AfterConnectProps = AfterWithActiveUserProps & {
  sendSignatureReminder: () => Promise<void>;
};

const getActionPerformText = (target: Props['target']): string => {
  switch (target) {
    case 'reviewee':
      return __('Remind the reviewee to sign their review');
    case 'reviewer':
      return __('Remind the reviewer to sign their review');
  }
};

function SendSignatureReminder({
  target,
  sendSignatureReminder,
}: AfterConnectProps) {
  return (
    <MenuItem onClick={sendSignatureReminder}>
      {getActionPerformText(target)}
    </MenuItem>
  );
}

function mapDispatchToProps(
  dispatch: AppDispatch,
  { activeUser, userReview, target }: AfterWithActiveUserProps
) {
  return {
    sendSignatureReminder: async () => {
      trackAction(`Individual signature reminder sent`, {
        reviewerRole: target,
        activeUserRole: viewerRole(activeUser, userReview),
      });
      await dispatch(
        sendReminder(userReview.id, `one_on_one_${target}_reminder_to_sign`)
      );
    },
  };
}

export default compose<ComponentType<Props>>(
  withActiveUser,
  // @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
  connect(null, mapDispatchToProps)
)(SendSignatureReminder);
