import React from 'react';

import {
  ForecastFundingSourceStats,
  ValidatedFundingSourceStats,
} from 'models';

import { __ } from 'helpers/i18n';
import { formatMoney } from 'helpers/money';

import { hasShape } from 'types/predicates/WithShape';

import { Text } from 'components';

type Props = {
  sourceStats: ValidatedFundingSourceStats | ForecastFundingSourceStats;
};

const FundingSourceWithoutBudgetStats = ({ sourceStats }: Props) => {
  const currency = sourceStats.currency;
  const forecastAmountCents = hasShape(sourceStats, { forecastAmountCents: 0 })
    ? sourceStats.forecastAmountCents
    : undefined;

  return (
    <div className="flex flex-row justify-between">
      <Text preset="14bs6" className="mt-1">
        {sourceStats.title}
      </Text>
      <div className="flex flex-col min-w-[50%] ml-1 align-right">
        <div>
          <Text>{__('Validated funding:')}</Text>
          <Text
            preset="14bs6"
            className="ml-2"
            testClassName="test-validated-amount"
          >
            {sourceStats.validatedAmountCents
              ? formatMoney(sourceStats.validatedAmountCents, currency)
              : '-'}
          </Text>
        </div>
        {forecastAmountCents !== undefined && (
          <div>
            <Text>{__('Forecast funding:')}</Text>
            <Text
              preset="14bs6"
              className="ml-2"
              testClassName="test-forecast-amount"
            >
              {forecastAmountCents > 0
                ? formatMoney(forecastAmountCents, currency)
                : '-'}
            </Text>
          </div>
        )}
      </div>
    </div>
  );
};

export default FundingSourceWithoutBudgetStats;
