import React from 'react';

import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { Control, FeatureFlagged, Icon, Input, Tooltip } from 'components';

type Props = {
  value: string | null | undefined;
};

function LockedField({ value }: Props) {
  const organization = useCurrentOrganization();

  return (
    <Control className="has-icons-right">
      <Input value={value || ''} disabled />

      <FeatureFlagged flag="overrideSyncedFields" inverseDefaultBehavior>
        <Tooltip
          content={__(
            'Synchronized using %1',
            organization.syncIntegrationInfo.name
          )}
        >
          <Icon size="small" className="is-right" name="lock" />
        </Tooltip>
      </FeatureFlagged>
    </Control>
  );
}

export default LockedField;
