import useActiveUser from './useActiveUser';
import { useCurrentOrganization } from './useCurrentOrganization';

const useSeasonalTheme = () => {
  const activeUser = useActiveUser();
  const organization = useCurrentOrganization();

  return {
    displaySeasonalTheme:
      !!organization.activeSeasonalTheme && activeUser.seasonalThemeEnabled,
    seasonalTheme: organization.activeSeasonalTheme,
  };
};

export default useSeasonalTheme;
