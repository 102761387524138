import React, { Fragment, useState } from 'react';

import type { TrainingSession } from 'models';

import can from 'helpers/can';
import { guessTimeZone } from 'helpers/date';
import { useAppDispatch } from 'helpers/hooks';
import { __ } from 'helpers/i18n';

import { post } from 'redux/actions/api';

import { Button, FeatureFlagged, Icon, Text } from 'components';

import AddParticipantsModal from '../../components/AddParticipantsModal';
import cancelInvitationsModal from './cancelInvitationsModal';
import sendInvitationsModal from './sendInvitationsModal';

type Props = {
  session: TrainingSession;
  refetchParticipants: () => Promise<void>;
};

const TableActions = ({ session, refetchParticipants }: Props) => {
  const dispatch = useAppDispatch();
  const [isAddParticipantsModalActive, setIsAddParticipantsModalActive] =
    useState(false);

  const sendCalendarInvitations = () =>
    dispatch(
      post(
        `training/sessions/${session.id}/send_invitations`,
        {
          timezone: guessTimeZone(),
        },
        {
          successMessage: __(
            'The calendar invitation has been successfully sent'
          ),
        }
      )
    );
  const cancelCalendarInvitations = () =>
    dispatch(
      post(`training/sessions/${session.id}/cancel_invitations`, undefined, {
        successMessage: __(
          'The calendar invitation has been successfully cancelled'
        ),
      })
    );

  return (
    <Fragment>
      <div className="flex flex-col items-end">
        <div className="flex gap-2">
          {can({ perform: 'cancel_invitations', on: session }) && (
            <Button
              color="secondary"
              onClick={() =>
                cancelInvitationsModal(session, cancelCalendarInvitations)
              }
            >
              {__('Cancel the calendar invitation')}
            </Button>
          )}
          {can({ perform: 'send_invitations', on: session }) && (
            <Button
              color="secondary"
              onClick={() =>
                sendInvitationsModal(session, sendCalendarInvitations)
              }
              disabled={!session.endDate || !session.startDate}
              disabledExplanation={__(
                'Add the training dates in the session details to send the invitation'
              )}
            >
              {__('Send the calendar invitation')}
            </Button>
          )}
          <FeatureFlagged flag="addParticipantsToSession">
            {can({ perform: 'add_participants', on: session }) && (
              <Button
                color="secondary"
                onClick={() => setIsAddParticipantsModalActive(true)}
              >
                {__('Add participants')}
              </Button>
            )}
          </FeatureFlagged>
        </div>

        {session.autoInviteNewParticipants && (
          <div className="flex">
            <div className="flex gap-2 items-center">
              <Icon color="success" name="check_circle" />
              <div>
                <Text preset="13s7" color="light">
                  {__(
                    'The new participants will be automatically invited to the session'
                  )}
                </Text>
              </div>
            </div>
          </div>
        )}

        {isAddParticipantsModalActive && (
          <AddParticipantsModal
            session={session}
            onClose={() => setIsAddParticipantsModalActive(false)}
            onAfterAddition={refetchParticipants}
          />
        )}
      </div>
    </Fragment>
  );
};

export default TableActions;
