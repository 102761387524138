import React, { CSSProperties, ReactNode } from 'react';

import invariant from 'helpers/invariant';

import { CheckboxSwitch, ValueManager } from 'components';

type Version = 1 | 2;

type VersionPickerProps = {
  version: Version;
  onChange: (version: Version) => any;
};

function VersionPicker({ version, onChange }: VersionPickerProps) {
  return (
    <div className="tag" style={{ position: 'absolute', zIndex: 1000 }}>
      <CheckboxSwitch
        value={version === 2}
        onChange={enabled => onChange(enabled ? 2 : 1)}
      />
      Version {version}
    </div>
  );
}

const DesignSystemVersionContext = React.createContext<Version>(1);

type Props = {
  version?: Version;
  children?: ReactNode;
  render?: (version: Version) => ReactNode;
  debug?: boolean;
  style?: CSSProperties;
};

/**
 * @deprecated All versions are v2 now
 * - It can always be safely replaced by a `<div>` in scenes
 * - If possible, just remove the component (be careful of side effects)
 * - Don't remove the root ones (app, storybook, body portal) until CSS rules are merged
 *   and no longer scoped within .design-system-v2
 */
export default function DesignSystem({
  version,
  render,
  children,
  debug,
  style,
}: Props) {
  if (typeof version !== 'undefined') {
    return (
      <ValueManager
        initialValue={version}
        render={(onChange, version) => {
          invariant(version, 'version must be defined');

          return (
            <div className={`design-system-v${version}`} style={style}>
              {debug && <VersionPicker onChange={onChange} version={version} />}
              <DesignSystemVersionContext.Provider value={version}>
                {children}
              </DesignSystemVersionContext.Provider>
            </div>
          );
        }}
      />
    );
  }

  invariant(render, 'render must be defined if version is not defined');

  return (
    <DesignSystemVersionContext.Consumer>
      {render}
    </DesignSystemVersionContext.Consumer>
  );
}
