import React from 'react';

import classNames from 'helpers/classNames';

import aiLogo from './svg/aiLogo';

type Props = {
  className?: string;
};

const AILogo = ({ className }: Props) => (
  <span
    className={classNames('inline-flex pb-[0.5em] align-middle', className)}
  >
    <img
      alt="ai-logo"
      src={`data:image/svg+xml;base64,${window.btoa(aiLogo)}`}
      className="h-[1.1em]"
    />
  </span>
);

export default AILogo;
