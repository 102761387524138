import React, { useState } from 'react';

import type { ExportAction } from 'models';

import { useAppDispatch } from 'helpers/hooks';
import { useExportAction } from 'helpers/hooks/useExportAction';
import { __ } from 'helpers/i18n';

import { type DataLoaderProvidedProps, newDataLoader } from 'lib/dataLoader';
import { get, post } from 'redux/actions/api';

import { Button, Icon, Tooltip } from 'components';

import ExportModal from 'scenes/admin/components/ExportModal';

type AfterConnectProps = DataLoaderProvidedProps & {
  lastExportAction: ExportAction | null;
};

function ExportButton({
  lastExportAction,
  refetchData,
  isFetching,
}: AfterConnectProps) {
  const dispatch = useAppDispatch();
  const [exportModalIsActive, setExportModalIsActive] = useState(false);

  const exportUserObjectivePeriods = async () => {
    await dispatch(post('user_objective_periods/export'));

    setExportModalIsActive(true);

    refetchData();
  };

  const { ongoingExportAction, exportTooltipText, pollerComponent } =
    useExportAction(lastExportAction);

  return (
    <React.Fragment>
      <Tooltip content={exportTooltipText}>
        {pollerComponent}
        <Button
          style={{ marginRight: 16 }}
          color="secondary"
          onClick={exportUserObjectivePeriods}
          disabled={ongoingExportAction || isFetching}
          isLoading={ongoingExportAction}
        >
          <Icon style={{ marginRight: 8 }} name="download" />
          {__('Export objectives follow-up')}
        </Button>
      </Tooltip>
      <ExportModal
        isActive={exportModalIsActive}
        onClose={() => setExportModalIsActive(false)}
      />
    </React.Fragment>
  );
}

export default newDataLoader({
  fetch: () => get('user_objective_periods/last_export'),
  hydrate: {
    lastExportAction: {},
  },
})(ExportButton);
