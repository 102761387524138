import { camelCase } from 'lodash';

import type { APIActionError } from './types';
import type { AppDispatch } from 'redux/actions/types';

import { __ } from 'helpers/i18n/index';
import { trackAction } from 'helpers/tracking';

import { errorNotice, htmlErrorNotice } from 'redux/actions/application';
import { blockingMessage } from 'redux/actions/index';

export const highLevelErrorHandler = (
  error: APIActionError<any>,
  dispatch: AppDispatch
) => {
  _errorsKeysToCamelCase(error);
  for (let handler of _errorHandlers) {
    if (handler.test(error)) {
      handler.action && handler.action(dispatch, error);
      if (handler.isHandled) {
        error.isHandled = true;
        error.handledBy = handler.name;
        throw error;
      }
    }
  }
  throw error;
};

const _errorsKeysToCamelCase = error => {
  if (error.response && error.response.body && error.response.body.errors) {
    const errors = error.response.body.errors;
    Object.keys(errors).forEach(key => {
      if (camelCase(key) === key) return;
      errors[camelCase(key)] = errors[key];
      delete errors[key];
    });
  }
};

const _errorHandlers = [
  {
    name: 'backend_not_reachable',
    test: error => !!error.crossDomain,
    action: (dispatch, _error) =>
      dispatch(
        htmlErrorNotice(
          __(
            '<b>The backend server is not reachable.</b><br />Please contact us at support@elevo.fr if the issue persists.'
          )
        )
      ),
    isHandled: true,
  },
  {
    name: 'user_not_authorized',
    test: error =>
      !(
        error.response &&
        error.response.req &&
        error.response.req.url &&
        (error.response.req.url.match(/users\/(sign_in|validate_token)/) ||
          error.response.req.url.match(
            /api\/v1\/users\/access_codes\/validate/
          ))
      ) &&
      error.response &&
      error.response.status === 401,
    action: (dispatch, _error) =>
      dispatch(
        htmlErrorNotice(
          __(
            '<b>You are not authorized to perform this action</b><br />Maybe you have been logged out? Try to reload the page.'
          )
        )
      ),
    isHandled: true,
  },
  {
    name: 'frontend_version_mismatch',
    test: error =>
      error.response &&
      error.response.status === 400 &&
      error.response.body &&
      error.response.body.error === 'frontend_version_mismatch',
    action: (dispatch, _error) => {
      trackAction('Frontend version mismatch');
      dispatch(
        htmlErrorNotice(
          __('<b>Elevo has been updated</b>, please reload the page.')
        )
      );
    },
    isHandled: true,
  },
  {
    name: 'backend_error_to_display',
    test: error =>
      error.response &&
      error.response.body &&
      error.response.body.error &&
      error.response.body.error.display,
    action: (dispatch, error) => {
      const extractedError = error.response.body.error;

      const { message, html, blocking } = extractedError;

      if (blocking) {
        dispatch(blockingMessage(html || message));
        return;
      }

      dispatch(html ? htmlErrorNotice(html) : errorNotice(message));
    },
    isHandled: true,
  },
];

type GenericErrorHandler = (
  apiCall: Promise<any>
) => (dispatch: AppDispatch) => Promise<any>;

export const withGenericErrorHandling: GenericErrorHandler =
  apiCall => (dispatch: AppDispatch) => {
    return apiCall.then(
      response => response,
      (error: APIActionError<any>) => highLevelErrorHandler(error, dispatch)
    );
  };
