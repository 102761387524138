import React, { useState } from 'react';

import { User } from 'models';

import { useAppDispatch, useOrganizationPlan } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { zendeskURL } from 'helpers/zendesk';

import { post } from 'redux/actions/api';

import {
  BlueInfoBox,
  Button,
  Link,
  ModalCard,
  ModalCardBody,
  ModalCardFooter,
  ModalCardHead,
  ModalCardTitle,
  Text,
} from 'components';

import UserPicker from 'scenes/components/UserPicker';

type Props = {
  cycleId: string;
  cycleName: string;
  onClose: () => void;
};

const RolesManagementModal = ({ cycleId, cycleName, onClose }: Props) => {
  const organizationPlan = useOrganizationPlan();
  const multiLevelAdminEnabled = organizationPlan.multiLevelAdminEnabled;

  const dispatch = useAppDispatch();
  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  const assignRoles = async () => {
    await dispatch(
      post(
        `roles/bulk_assign`,
        {
          resource: { type: 'PeopleReviewCycle', id: cycleId },
          userIds: selectedUsers.map(user => user.id),
          roleName: 'targeted_people_review_campaign_admin',
        },
        {
          successMessage: __(
            'Administrator permissions on the campaign have been successfully granted to the selected users.'
          ),
        }
      )
    );
    onClose();
  };

  const documentationURL = zendeskURL({
    fr: '9510092374161-Suivre-et-g%C3%A9rer-une-campagne-de-People-Review',
    en: '9510092374161-Track-and-Manage-a-People-Review-Campaign',
  });

  return (
    <ModalCard onClose={onClose} isActive isBig>
      <ModalCardHead>
        <ModalCardTitle>
          {__('Grant admin permissions on the campaign')}
        </ModalCardTitle>
      </ModalCardHead>
      <ModalCardBody>
        <div className="mb-2">
          <Text className="block mb-4">
            {__(
              'Grant admin permissions on the "%1" campaign by assigning a %2 role to users. For more information, visit our %3.',
              cycleName,
              <b>{__('Campaign administrator')}</b>,
              <Link
                to={documentationURL}
                additionalClassName="text-primary"
                openInNewTab
              >
                {__('help center')}
              </Link>
            )}
          </Text>
          <div className="mb-4">
            <Text preset="14bs6">{__('Select one or more users')}</Text>
            <Text className="block mb-2">
              {__(
                'Users will be notified by email when they are granted permissions on the campaign'
              )}
            </Text>
            <UserPicker
              value={selectedUsers}
              isMulti
              inModal
              isClearable
              placeholder={__('Select one or more users')}
              onChange={users =>
                Array.isArray(users) && setSelectedUsers(users)
              }
              className="w-full"
            />
          </div>
          <BlueInfoBox
            text={
              multiLevelAdminEnabled
                ? __(
                    'The selected users will access the people reviews of <b>all the users</b> in the campaign, including theirs and regardless of their scope.'
                  )
                : __(
                    'The selected users will access the people reviews of <b>all the users</b> in the campaign, including theirs.'
                  )
            }
            className="mt-4"
          />
        </div>
      </ModalCardBody>
      <ModalCardFooter>
        <Button color="secondary" onClick={onClose}>
          {__('Cancel')}
        </Button>
        <Button
          color="primary"
          onClick={assignRoles}
          disabled={selectedUsers.length === 0}
        >
          {__('Grant permissions')}
        </Button>
      </ModalCardFooter>
    </ModalCard>
  );
};

export default RolesManagementModal;
