import queryString from 'helpers/queryString';

import { setCredentials } from 'lib/api/credentials';

export default function Callback() {
  const queryParams = queryString.parse(window.location.search);

  const { email, token, impersonate } = queryParams;

  const redirectFromQueryParams = queryParams.redirect as string | undefined;
  const redirectFromLocalStorage = localStorage.getItem('redirect');

  // TODO: email & token should most likely be required, we could add invariants here
  setCredentials({
    email: email as string | undefined,
    token: token as string | undefined,
    impersonate: impersonate as string | undefined,
  });

  const redirect = redirectFromQueryParams || redirectFromLocalStorage || '/';

  localStorage.removeItem('redirect');

  window.location.replace(redirect);

  return null;
}
