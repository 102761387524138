import React from 'react';
import { connect } from 'react-redux';

import type { TrainingRequestHistoryBlockContent, User } from 'models';
import type { ReduxStore } from 'redux/reducers';

import can from 'helpers/can';
import { useCurrentOrganization } from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import { pathToUserTrainings } from 'helpers/navigation';

import { hydrateFromStore } from 'lib/dataLoader';

import { Box } from 'components';

import BlockDetails from 'scenes/components/review/BlockDetails';
import { default as SimbelTrainingRequestList } from 'scenes/components/simbel/TrainingRequestList';
import PromptTrainingRequestCreationTableFooter from 'scenes/review/components/FormContent/blocks/TrainingRequestHistoryBlockContent/PromptTrainingRequestCreationTableFooter';

import ReviewMessages from '../../ReviewMessages';
import TrainingRequestList from './TrainingRequestList';

type Props = {
  content: TrainingRequestHistoryBlockContent;
  reviewee: User;
  userReviewId?: string;
  withoutSectionSeparator?: boolean;
};

function TrainingRequestHistoryBlockContentComponent({
  content,
  reviewee,
  userReviewId,
  withoutSectionSeparator,
}: Props) {
  const {
    title,
    description,
    id,
    feedbackableItems,
    messages,
    richTextEnabled,
  } = content;
  const { isSimbelIntegrationActive } = useCurrentOrganization();

  const emptyStateTitle = __(
    'No training requests have been created for %1',
    reviewee.fullName
  );

  const canShowTrainingRequests = can({
    perform: 'show_training_requests',
    on: reviewee,
  });
  const canCreateTrainingRequest = can({
    perform: 'create_training_request',
    on: reviewee,
  });

  return (
    <div className="training-request-module test-training-request-history-block">
      <BlockDetails
        id={id}
        section={__('Trainings')}
        title={title}
        description={description}
        withoutSectionSeparator={withoutSectionSeparator}
        withRichText={richTextEnabled}
        withReadMore
      />
      <ReviewMessages style={{ marginBottom: 8 }} messages={messages} />
      {isSimbelIntegrationActive ? (
        <SimbelTrainingRequestList
          emptyStateTitle={emptyStateTitle}
          userId={reviewee.id}
          userReviewId={userReviewId}
          footer={
            canCreateTrainingRequest && (
              <PromptTrainingRequestCreationTableFooter reviewee={reviewee} />
            )
          }
        />
      ) : (
        <Box className="block-content-wrapper training-request-history-block">
          <TrainingRequestList
            emptyStateTitle={emptyStateTitle}
            trainingRequests={feedbackableItems.map(fi => fi.item)}
            link={
              canShowTrainingRequests
                ? {
                    pathTo: pathToUserTrainings(reviewee.id),
                    label: __(
                      "View %1's training requests",
                      reviewee.firstName
                    ),
                  }
                : null
            }
          />
        </Box>
      )}
    </div>
  );
}

function mapStateToProps(state: ReduxStore, { content }: Props) {
  return {
    content: hydrateFromStore(
      state.data,
      { resourceType: 'formContentBlock', id: content.id },
      {
        block: {
          messages: {},
          feedbackableItems: {
            item: {
              abilities: {},
              session: {},
            },
          },
        },
      }
    ),
  };
}

export default connect(mapStateToProps)(
  TrainingRequestHistoryBlockContentComponent
);
