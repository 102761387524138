import React from 'react';

import { __ } from 'helpers/i18n';

import { EmptyStateWithIcon } from 'components';

const NoRecordState = () => {
  return (
    <EmptyStateWithIcon
      inBox={false}
      iconName="send"
      title={__('There is no training session for this period.')}
    />
  );
};

export default NoRecordState;
