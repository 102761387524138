import React, { createContext, useState } from 'react';

import type { TrainingPeriod } from 'models';
import type { RouteComponentProps } from 'react-router-dom';

import can from 'helpers/can';
import {
  useAppDispatch,
  useAppSelector,
  useCurrentOrganization,
} from 'helpers/hooks';
import { __ } from 'helpers/i18n';
import invariant from 'helpers/invariant';
import { navigate } from 'helpers/navigation';
import {
  OldPathToTrainingSessionIndex,
  pathToForecastTrainingPlan,
  pathToTrainingSessionDetails,
  pathToValidatedTrainingPlan,
} from 'helpers/paths';

import { hydrateFromStore } from 'lib/dataLoader';
import { post } from 'redux/actions/api';

import { Button, ContentContainer, Icon } from 'components';

import ExportModal from 'scenes/admin/components/ExportModal';
import TrainingPeriodPicker from 'scenes/components/TrainingPeriodPicker';

import PageHeader from '../components/PageHeader';
import BulkValidateSessionsModal from './BulkValidateSessionsModal';
import SessionTable from './SessionTable';
import Stats from './Stats';

type RouterProps = RouteComponentProps<{ periodSlug: string }>;

type Props = RouterProps & { isValidatedPlan: boolean };

type DataContextType = {
  shouldRefetchStats: boolean;
  setShouldRefetchStats: (value: boolean) => void;
};
export const DataContext = createContext<DataContextType>(
  {} as DataContextType
);

export const CurrentTrainingPeriodContext =
  createContext<TrainingPeriod | null>(null);

const Plan = ({ match, isValidatedPlan }: Props) => {
  const organization = useCurrentOrganization();
  const { periodSlug } = match.params;
  const [exportModalIsActive, setExportModalIsActive] = useState(false);
  const [
    bulkMoveSessionsToValidatedPlanModalIsActive,
    setBulkMoveSessionsToValidatedPlanModalIsActive,
  ] = useState(false);
  const [shouldRefetchStats, setShouldRefetchStats] = useState(false);

  const dispatch = useAppDispatch();

  const period = useAppSelector(
    state =>
      hydrateFromStore(
        state.data,
        {
          resourceType: 'trainingPeriod',
          filter: (item: TrainingPeriod) => item.slug === periodSlug,
        },
        {
          trainingPeriod: {
            exchangeRates: [],
          },
        }
      )[0]
  ) as TrainingPeriod;

  invariant(periodSlug, 'Training period must be defined.');

  const handleExport = async () => {
    await dispatch(post(`training/periods/${periodSlug}/sessions/export`));
    setExportModalIsActive(true);
  };

  const trainingPlansEnabled =
    organization.featureFlags.includes('trainingPlans');

  const createNewSession = async () => {
    const response = await dispatch(
      post(`training/sessions`, {
        periodSlug: periodSlug,
        validated: isValidatedPlan,
        inForecastBudget: !isValidatedPlan,
      })
    );
    const session = response.response.body.data;
    navigate(pathToTrainingSessionDetails(session.id));
  };

  return (
    <CurrentTrainingPeriodContext.Provider value={period}>
      <PageHeader periodSlug={periodSlug} />
      <DataContext.Provider
        value={{ shouldRefetchStats, setShouldRefetchStats }}
      >
        <ContentContainer>
          <div className="flex my-4 justify-between">
            <div className="w-40">
              <TrainingPeriodPicker
                renderingStyle="popover"
                currentPeriodSlug={periodSlug}
                onChange={(period: TrainingPeriod) => {
                  if (!trainingPlansEnabled) {
                    navigate(OldPathToTrainingSessionIndex(period.slug));
                    return;
                  }

                  isValidatedPlan
                    ? navigate(pathToValidatedTrainingPlan(period.slug))
                    : navigate(pathToForecastTrainingPlan(period.slug));
                }}
              />
            </div>
            <div className="flex gap-3">
              <Button color="secondary" onClick={handleExport}>
                {__('Export sessions')}
              </Button>
              <Button
                key={`create-session-from-${
                  isValidatedPlan ? 'validated' : 'forecast'
                }-plan-button`}
                color={isValidatedPlan ? 'primary' : 'secondary'}
                onClick={() => createNewSession()}
              >
                {isValidatedPlan && <Icon name="add" className="mr-1" />}
                {__('Create a training session')}
              </Button>
              {trainingPlansEnabled && !isValidatedPlan && (
                <Button
                  color="primary"
                  disabled={
                    !period ||
                    !can({
                      perform: 'bulk_validate_training_sessions',
                      on: period,
                    })
                  }
                  onClick={() =>
                    setBulkMoveSessionsToValidatedPlanModalIsActive(true)
                  }
                >
                  {__('Validate the forecast sessions')}
                </Button>
              )}
            </div>
          </div>
          <div className="my-4">
            <Stats
              periodSlug={periodSlug}
              isValidatedPlan={trainingPlansEnabled ? isValidatedPlan : true}
            />
          </div>
          <SessionTable
            key={`training-${
              isValidatedPlan ? 'validated' : 'forecast'
            }-plan-${periodSlug}`}
            periodSlug={periodSlug}
            paginationType="url"
            isValidatedPlan={isValidatedPlan || !trainingPlansEnabled}
            defaultPaginationParams={{ sort: { created_at: 'desc' } }}
          />
        </ContentContainer>
        {exportModalIsActive && (
          <ExportModal isActive onClose={() => setExportModalIsActive(false)} />
        )}
        {bulkMoveSessionsToValidatedPlanModalIsActive && (
          <BulkValidateSessionsModal
            onClose={() =>
              setBulkMoveSessionsToValidatedPlanModalIsActive(false)
            }
            periodSlug={periodSlug}
          />
        )}
      </DataContext.Provider>
    </CurrentTrainingPeriodContext.Provider>
  );
};

export default Plan;
