import React from 'react';
import { useRouteMatch } from 'react-router-dom';

import { Redirect, Route, Switch } from 'components';

import MatrixList from './MatrixList';
import MatrixSuggestions from './MatrixSuggestions';

export default function Routes() {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/`} exact component={MatrixList} />
      <Route path={`${path}/:id`} exact component={MatrixSuggestions} />

      <Redirect to={`${path}/`} />
    </Switch>
  );
}
