import React from 'react';
import { connect } from 'react-redux';

import type { UserReview } from 'models/UserReview';
import type { AppDispatch } from 'redux/actions/types';

import { poll } from 'helpers/api';
import { __ } from 'helpers/i18n';
import { addToken } from 'helpers/url';

import { createUrl } from 'lib/api';
import { errorNotice } from 'redux/actions';
import { get, post } from 'redux/actions/api';

import { Button, Can, Icon } from 'components';

import BrowserPrintActionButton from './BrowserPrintActionButton';

type Props = {
  review: UserReview;
};

type AfterConnectProps = Props & {
  downloadPDF: () => void;
};

function PrintActionButton({ review, downloadPDF }: AfterConnectProps) {
  if (review.interactionType === '360') {
    return <BrowserPrintActionButton />;
  }

  return (
    <Can perform="export_pdf" on={review}>
      <Button color="secondary" isRounded hasIconOnly onClick={downloadPDF}>
        <Icon name="print" />
      </Button>
    </Can>
  );
}

function mapDispatchToProps(dispatch: AppDispatch, { review }: Props) {
  return {
    downloadPDF: async () => {
      let response = null;

      try {
        response = await dispatch(
          post(`user_reviews/${review.id}/pdf_exports`)
        );
      } catch (e) {
        dispatch(errorNotice(__('Failed to generate PDF')));
        return null;
      }

      // @ts-ignore: response is not typed
      const exportId = response.response.body.data.id;

      try {
        await poll(
          async () => {
            const response = await dispatch(
              get(`user_reviews/${review.id}/pdf_exports/${exportId}`)
            );

            const { status } = response.response.body.data.attributes;

            if (status === 'failed') {
              throw new Error('Failed to generate PDF');
            }

            return status === 'completed';
          },
          { interval: 2000, timeout: 60000 }
        );
      } catch (e) {
        dispatch(errorNotice(__('Failed to generate PDF')));
        return null;
      }

      const pdfUrl = createUrl(
        `user_reviews/${review.id}/pdf_exports/${exportId}/download`
      );

      window.location.replace(addToken(pdfUrl));
    },
  };
}

export default connect(
  null,
  // @ts-expect-error TSFIXME: connect/mapDispatch don't work because our Action is wrongly typed (missing ThunkAction)
  mapDispatchToProps
)(PrintActionButton) as React.ComponentType<Props>;
