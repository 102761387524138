import { memoize } from 'lodash';

import type { DataState } from 'redux/reducers/DataStateType';

import compositeKey from 'helpers/compositeKey';
import resolveRelationships from 'helpers/data/resolveRelationships';
import { Resource } from 'helpers/data/types/NormalizedJsonApiResponse';

import { getResourceById } from 'redux/reducers/index';

function _hydrate(
  dataState: DataState,
  data: Resource | Array<Resource>,
  relationshipsTree: {},
  _memoizeKey: string
): Resource | Array<Resource> | null {
  if (Array.isArray(data)) {
    return data.map(({ type, id }) => {
      let resource = getResourceById(dataState, type, id) as Resource;
      return resolveRelationships(dataState, resource, relationshipsTree);
    });
  } else {
    const resource = getResourceById(dataState, data.type, data.id) as Resource;
    return resolveRelationships(dataState, resource, relationshipsTree);
  }
}

const memoizedHydrate = memoize(
  _hydrate,
  (dataState, _data, _relationshipsTree, memoizeKey) =>
    compositeKey({
      updatedAt: dataState.updatedAt,
      memoizeKey,
    })
);

export default function hydrate(
  dataState: DataState,
  data: null | Resource | Array<Resource>,
  relationshipsTree: {},
  memoizeKey?: string
) {
  if (!data) return null;

  if (memoizeKey)
    return memoizedHydrate(dataState, data, relationshipsTree, memoizeKey);

  return _hydrate(dataState, data, relationshipsTree, '');
}
