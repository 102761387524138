import React from 'react';

import { __, n__ } from 'helpers/i18n';

import { BlueInfoBox, Text } from 'components';

type Props = {
  userCount: number;
};

const AutoInvitationHelper = ({ userCount }: Props) => (
  <BlueInfoBox>
    <Text preset="13bs7">{__('Automatic invitation to the session')}</Text>
    <br />
    <Text preset="13s7">
      {n__(
        'The selected participant will be automatically invited to the session as this session has been planned:',
        'The %1 selected participants will be automatically invited to the session as this session has been planned:',
        userCount
      )}
    </Text>
  </BlueInfoBox>
);

export default AutoInvitationHelper;
