import React, { Fragment, createContext, useState } from 'react';

import { __ } from 'helpers/i18n';

import { Button, Icon } from 'components';

import TrainingRequestModal from 'scenes/components/TrainingRequestModal';

import useActions from '../helpers/useActions';
import RequestList from './RequestList';
import Stats from './Stats';

type Props = { periodSlug: string };

type ContextType = {
  refetchRequests: boolean;
  setRefetchRequests: (value: boolean) => void;
  refetchStats: boolean;
  setRefetchStats: (value: boolean) => void;
  setRequestCreationModalActive: (value: boolean) => void;
};

export const DataContext = createContext<ContextType>({} as ContextType);

const Requests = ({ periodSlug }: Props) => {
  const [requestCreationModalActive, setRequestCreationModalActive] =
    useState(false);
  const [refetchRequests, setRefetchRequests] = useState(false);
  const [refetchStats, setRefetchStats] = useState(false);

  const { renderActions, userFilters } = useActions(periodSlug);

  return (
    <Fragment>
      {renderActions({
        additionalActions: (
          <Button
            onClick={() => setRequestCreationModalActive(true)}
            color="primary"
          >
            <Icon name="add" className="mr-1" />
            {__('Make a request')}
          </Button>
        ),
      })}

      <DataContext.Provider
        value={{
          refetchRequests,
          setRefetchRequests,
          setRequestCreationModalActive,
          refetchStats,
          setRefetchStats,
        }}
      >
        <Stats periodSlug={periodSlug} userFilters={userFilters} />

        <RequestList
          currentPeriodSlug={periodSlug}
          defaultFilter={{ all: true }}
          additionalQueryParams={{ userFilters }}
        />
      </DataContext.Provider>

      {requestCreationModalActive && (
        <TrainingRequestModal
          isActive
          currentTrainingPeriodSlug={periodSlug}
          onClose={() => setRequestCreationModalActive(false)}
          onAfterSubmit={() => setRefetchRequests(true)}
        />
      )}
    </Fragment>
  );
};

export default Requests;
