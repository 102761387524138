import React from 'react';

import type { UpdatableReviewCycleFields } from 'scenes/admin/reviewCycles/Setup/Edit';

import { ReviewCycle } from 'models';

import { __ } from 'helpers/i18n';
import { zendeskURL } from 'helpers/zendesk';

import { Link, Radio, SubTitle, Title } from 'components';

import DynamicConfiguration from './DynamicConfiguration';
import StaticConfiguration from './StaticConfiguration';

export type UpdateReviewCycle = (
  reviewCycle: Partial<UpdatableReviewCycleFields>
) => Promise<void>;

type Props = {
  reviewCycle: ReviewCycle;
  updateReviewCycle: UpdateReviewCycle;
};

export default function AutoAddParticipantsConfiguration({
  reviewCycle,
  updateReviewCycle,
}: Props) {
  const documentationURL = zendeskURL({
    fr: '4405423597329-Ajouter-automatiquement-un-participant-%C3%A0-une-campagne-en-fonction-de-sa-date-d-arriv%C3%A9e',
    en: '4405423597329-Automatically-add-a-participant-to-a-review-cycle-based-on-their-arrival-date',
  });

  return (
    <div className="mb-5">
      <Title size={4}>{__('Define the campaign addition rules')}</Title>
      <SubTitle size={6} className="mt-0 mb-4">
        {__(
          'For more details, you can read this %1 on our help center.',
          <Link
            additionalClassName="text-[var(--primary)]"
            to={documentationURL}
            openInNewTab
          >
            {__('article')}
          </Link>
        )}
      </SubTitle>

      <Radio
        label={__(
          'All the employees of your company will follow the same rule of automatic addition'
        )}
        className="mb-3 auto-add-mode-static"
        isChecked={reviewCycle.autoAddMode === 'static'}
        onChange={() => updateReviewCycle({ autoAddMode: 'static' })}
      ></Radio>
      {reviewCycle.autoAddMode === 'static' && (
        <div className="mx-9 my-2">
          <StaticConfiguration
            reviewCycle={reviewCycle}
            updateReviewCycle={updateReviewCycle}
          />
        </div>
      )}
      <Radio
        label={__(
          'Define specific rules for employees groups that will be targeted in this campaign'
        )}
        className="mb-3 auto-add-mode-dynamic"
        isChecked={reviewCycle.autoAddMode === 'dynamic'}
        onChange={() => updateReviewCycle({ autoAddMode: 'dynamic' })}
      ></Radio>
      {reviewCycle.autoAddMode === 'dynamic' && (
        <div className="mx-9 mt-2 test-auto-add-mode-dynamic-configuration">
          <DynamicConfiguration reviewCycle={reviewCycle} />
        </div>
      )}
    </div>
  );
}
